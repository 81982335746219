import React, { Component } from "react";

export default class RememberPassword extends Component {
  state = {
    remember: false,
  };
  render() {
    // Handling onChange for remember password
    const onHandleRememberMe = (e) => {
      this.setState({ remember: e.target.checked });
    };

    return (
      <form className="flex flex-row items-center">
        <input
          className="checked:bg-default"
          type="checkbox"
          name="remember"
          onChange={onHandleRememberMe}
          checked={this.state.remember}
        />
        <label className="mx-5 text-lightbg">Remember me</label>
      </form>
    );
  }
}
