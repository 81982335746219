import SideNav from "../../../components/Nav/SideNav";
import { useState } from "react";

import { useEffect } from "react";

import { RiChatQuoteLine } from "react-icons/ri";
import { RxReset } from "react-icons/rx";
import {
  numberFormat,
  defaultColDef,
  rowSelectionType,
} from "../../../components/Utility/Variables";
import { AgGridReact } from "ag-grid-react";

import {
  AUTHOR,
  AUTHOR_RANKING,
  AUTHOR_RANKINGS,
  AUTHOR_RANKING_DESCRIPTION,
  AVERAGE_RATING,
  IMAGE,
  NUMBER_OF_VIEWS,
  PERFORM_UTILITY_FUNCTIONS_ON_AUTHORRanking,
  RESETING_AUTHOR_RANKING_DESCRIPTION,
  RESET_AUTHOR_RANKING,
  RESET_AUTHOR_RANKING_DESCRIPTION,
  SERIES,
  SUMMARY,
  TITLE,
  TOP_COMIC_IN_STORE,
  TWO_MONTH_VIEWS,
  UTILITY,
  VIEWS,
} from "../../../config/app_strings";
import { MoonLoader } from "react-spinners";
import { encryptAndDecryptLocalStorage } from "../../../actions/AuthAction";
import { instance } from "../../../config/api";
import { resetViews } from "../../../actions/SupplementsAction";
import ActionModal from "../../../components/Modal/ActionModal";
import { connect } from "react-redux";

const Root = (props) => {
  
  return (
    <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <AuthorRankingDashContent resetViews={props.resetViews} />
    </div>
  );
};

const AuthorRankingDashContent = ({resetViews}) => {
  const [getAuthorRankings, setAuthorRankings] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const [getTopRatedComics,setTopRatedComics]=useState([])

  useEffect(() => {
    async function fetchAuthorRanking() {
      setStateLoading(true);
      await instance
        .get("authors-ranking", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((response) => {
          const sortedData = response.data.data.sort(
            (a, b) => b.no_of_views - a.no_of_views
          );
          setAuthorRankings(sortedData);
        setTopRatedComics(sortedData[0])
        })
        .catch((error) => {});
      setStateLoading(false);
    }
    fetchAuthorRanking();
  }, []);

  return !stateLoading ? (
    <div className="flex flex-row w-full text-left bg-lightbg">
      <AuthorRankingLeftContent />
      <AuthorDashRightSide getTopRatedComics={getTopRatedComics} resetViews={resetViews} />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full min-h-screen">
     <MoonLoader size="50" color="#008fe7" />
    </div>
  );
};

const AuthorRankingLeftContent = () => {
  // Ag Grid
  const onGridReady = (params) => {
    instance
      .get("authors-ranking", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })

      .then((response) => {
        params.api.applyTransaction({ add: response.data.data });
      })
      .catch((error) => {});
  };


  const columnDefs = [

    {
      headerName: "No",
      cellRenderer: (params) => {
        return <p>{params.node.rowIndex + 1}</p>;
      }
    },
  
    {
      headerName: TITLE,
      field: "title",
    },

    {
      headerName: AUTHOR,
      field: "author",
      cellRenderer: (params) => {
        let isComicbooknotempty = params.data != null;

        if (isComicbooknotempty) {
          let data = params.data.authors[0];

          if (data != null) {
            return <p>{data.name}</p>;
          }
          return <p>No data</p>;
        }
        return <p>No</p>;
      },
    },

    {
      headerName: IMAGE,
      field: "image",
      cellRenderer: (params) => {
        let isComicbooknotempty = params.data;

        if (isComicbooknotempty) {
          let data = params.data.image;
          if (data != null) {
            return (
              <img
                src={params.data.image.path}
                alt={params.data.image.title}
                className="object-contain"
              />
            );
          }

          return <p>No image data </p>;
        }
        return <p>No image to preview</p>;
      },
    },
    

    {
      headerName: TWO_MONTH_VIEWS,
      field: "two_month_views",
    },
    {
      headerName: AVERAGE_RATING,
      field: "data.average_rating",
      cellRenderer: (params) => {
        const averageRating = params.data.average_rating;
        const roundedRating = averageRating ? Number(averageRating).toFixed(2) : 0; 
        return roundedRating;
      },
    },
  ];

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* User Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">{AUTHOR_RANKINGS}</p>
          <p className="text-sm font-thin">{AUTHOR_RANKING_DESCRIPTION}</p>
        </div>
      </div>

      {/* View rankings */}

      <div
        className="w-full text-black placeholder-black bg-transparent border ag-theme-material"
        style={{ height: "80vh", color: "black" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelectionType={rowSelectionType}
          rowMultiSelectWithClick={true}
          pagination={true}
          className="text-left text-black placeholder-black bg-transparent"
        ></AgGridReact>
      </div>
    </div>
  );
};

const AuthorDashRightSide = ({ getTopRatedComics,resetViews }) => {
  const [isModelOpen, setModalOpen] = useState(false);
  const openingModalElement = () => {
    setModalOpen(true);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
  };
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto bg-white shadow-2xl text-lightDefault">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">
          {PERFORM_UTILITY_FUNCTIONS_ON_AUTHORRanking}
        </p>
        <p className="text-4xl font-bold text-default">{UTILITY}</p>
      </div>
      <div className="flex flex-row items-start space-x-5">
        <div className="p-3 bg-passOverColor rounded-xl">
          <RxReset
            size="25"
            className="text-white"
            onClick={() => {
              openingModalElement();
            }}
          />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{RESET_AUTHOR_RANKING}</p>
          <p className="text-sm text-default">
            {RESET_AUTHOR_RANKING_DESCRIPTION}
          </p>
        </div>
      </div>
      <ActionModal
      isResetting={true}
        fromName={AUTHOR_RANKING}
        actionFromName={RESETING_AUTHOR_RANKING_DESCRIPTION}
        isModalOpen={isModelOpen}
        onClose={closingModalElement}
        onConfirm={async () => {
          resetViews();
          closingModalElement();
        }}
      />

      {/* Summary */}
      <div>
        <div className="flex flex-row items-center justify-between mt-5">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        <div className="mt-3">
          <p className="text-lg font-bold text-textColor">
            {TOP_COMIC_IN_STORE}
          </p>
        </div>

        {/* Each Notice */}
        {getTopRatedComics != null ? (
          <div className="flex flex-row items-start mt-8 space-x-5">
            <div className="p-3 bg-passOverColor rounded-xl">
              <RiChatQuoteLine size="30" className="text-white" />
            </div>

            <div className="text-lightDefault">
              <p className="text-2xl font-bold">{getTopRatedComics.title}</p>
              <span className="text-sm text-default">
                {""}
                <span className="text-2xl ">
                  {numberFormat.format(getTopRatedComics.two_month_views)}
                </span>{" "}
                <span className="text-sm text-default ">{NUMBER_OF_VIEWS}</span>
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetViews: () => dispatch(resetViews()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);
