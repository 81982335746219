import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";

import { MoonLoader, ScaleLoader } from "react-spinners";
import { connect } from "react-redux";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import ViewSeries from "./ViewSeries";
import {
  numberFormat,
  defaultColDef,
  rowSelectionType,
} from "../../../components/Utility/Variables";
import { deleteComic } from "../../../actions/ComicAction";
import { instance } from "../../../config/api";
import SingleComic from "./SingleComic";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { ComicDisplayOption, ComicOperation } from "../../../enums/comic";
import { AuthorOperation } from "../../../enums/author";
import { SupplementsOperation } from "../../../enums/supplements";
import {
  ACTION,
  ADD_AUTHOR,
  ADD_COMIC,
  ADD_GENRE,
  ADD_NEW_AUTHOR_DESCRIPTION,
  ADD_NEW_COMIC_DESCRIPTION,
  ADD_NEW_GENRE_DESCRIPTION,
  ADD_NEW_SERIES_DESCRIPTION,
  ADD_NEW_TAG_DESCRIPTION,
  ADD_SERIES,
  ADD_TAG,
  AUTHOR,
  CANCEL,
  COMIC_BOOK,
  COMIC_MANAGEMENT,
  COMIC_MANAGEMENT_DESCRIPTION,
  DELETE,
  DELETE_FROM_COMIC_BOOK,
  FROM_Comic,
  GENRE,
  IMAGE,
  PERFORM_UTILITY_FUNCTIONS_ON_COMIC,
  PUBLICATION_YEAR,
  RATING,
  SERIES,
  SERIES_MANAGEMENT,
  SERIES_MANAGEMENT_DESCRIPTION,
  SUMMARY,
  TAG,
  TITLE,
  TOTAL_COMIC_IN_STORE,
  TOTAL_NUMBER_OF_TWO_MONTH_VIEWS,
  TOTAL_NUMBER_OF_VIEWS,
  TOTAL_SERIES_IN_STORE,
  UTILITY,
  VIEW_COUNT,
} from "../../../config/app_strings";
import ActionModal from "../../../components/Modal/ActionModal";

function Root(props) {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div>
      <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
        <SideNav />
        <ComicDash
          deleteComic={props.deleteComic}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  );
}

// Comic Dashboard Content
const ComicDash = ({ deleteComic, isLoading }) => {
  const [getComic, setComic] = useState([]);
  const [getSeries, setSeries] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const [toggleDetail, setToggleDetail] = useState(false);
  const [selectedComic, setSelectedComic] = useState([]);
  const [gettoalNumberOfViews, setTotalNumberOfViews] = useState(0);
  const [gettotalNumberOfTwoMonthViews, setTotalNumberOfTwoMonthViews] =
    useState(0);

  useEffect(() => {
    async function fetchComicRightSideStats() {
      setStateLoading(true);
      try {
        const getAccessToken = await encryptAndDecryptLocalStorage(
          localStorage.getItem("login_user_token"),
          false
        );
        const header = {
          headers: {
            Authorization: `Bearer ${getAccessToken}`,
          },
        };

        const [series, rightSideStats] = await Promise.all([
          instance.get("series", header),
          instance.get("dashboard-stats", header),
        ]);

        setSeries(series.data.data);
        setTotalNumberOfViews(
          rightSideStats.data.data.attributes.total_no_of_views
        );
        setTotalNumberOfTwoMonthViews(
          rightSideStats.data.data.attributes.total_two_month_views
        );
      } catch (error) {
        console.error("Failed to fetch :", error);
      } finally {
        setStateLoading(false);
      }
    }

    fetchComicRightSideStats();
  }, []);

  return !stateLoading ? (
    <div className="flex flex-row w-full text-left bg-lightbg">
      {!toggleDetail ? (
        <ComicDashLeftSide
          deleteComic={deleteComic}
          isLoading={isLoading}
          getSeries={getSeries}
          setComic={setComic}
          setSelectedComic={setSelectedComic}
          setToggleDetail={setToggleDetail}
        />
      ) : (
        <SingleComic
          selectedComic={selectedComic}
          setToggleDetail={setToggleDetail}
        />
      )}
      <ComicDashRightSide
        gettoalNumberOfViews={gettoalNumberOfViews}
        gettotalNumberOfTwoMonthViews={gettotalNumberOfTwoMonthViews}
        getComic={getComic.length}
        getSeries={getSeries.length}
      />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center min-h-screen m-auto">
      <MoonLoader size="50" color="#008fe7" />
    </div>
  );
};

// Comic Left Side
const ComicDashLeftSide = ({
  deleteComic,

  getSeries,
  setComic,
  setToggleDetail,
  setSelectedComic,
}) => {
  const [toggleSelection, setToggleSelection] = useState(
    ComicDisplayOption.COMIC
  );
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeletableField, setSelectedDeletableField] = useState();
  const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
    useState();

  // Ag Grid
  const onGridReady = (params) => {
    instance
      .get("comicBooks")
      .then((response) => {
        params.api.applyTransaction({ add: response.data.data });
        setComic(response.data.data);
      })
      .catch((error) => {});
  };

  const columnDefs = [
    {
      headerName: IMAGE,
      field: "image",
      filter: false,
      cellRenderer: (params) => (
        <img
          src={params.data.links.image}
          alt={params.data.data.attributes.title}
          className="w-1/3"
        />
      ),
    },
    { headerName: TITLE, field: "data.attributes.title" },
    {
      headerName: PUBLICATION_YEAR,
      field: "data.attributes.publication_year",
    },
    {
      headerName: AUTHOR,
      field: "author_name",
      cellRenderer: (params) => {
        let authorName = "";
        if (
          params.data.data.meta.authors.data &&
          params.data.data.meta.authors.data.length > 0
        )
          params.data.data.meta.authors.data.map((author, i) => {
            i + 1 === params.data.data.meta.authors.data.length
              ? (authorName += author.data.attributes.name)
              : (authorName += author.data.attributes.name + ", ");
          });
        return authorName;
      },
    },
    {
      headerName: GENRE,
      field: "genre",
      cellRenderer: (params) => {
        let genreName = "";
        if (
          params.data.data.meta.genres.data &&
          params.data.data.meta.genres.data.length > 0
        )
          params.data.data.meta.genres.data.map((genre, i) => {
            i + 1 === params.data.data.meta.genres.data.length
              ? (genreName += genre.data.attributes.name)
              : (genreName += genre.data.attributes.name + ", ");
          });

        return genreName;
      },
    },
    {
      headerName: TAG,
      field: "tag",
      cellRenderer: (params) => {
        let tagName = "";
        if (
          params.data.data.meta.tags.data &&
          params.data.data.meta.tags.data.length > 0
        )
          params.data.data.meta.tags.data.map((tags, i) => {
            i + 1 === params.data.data.meta.tags.data.length
              ? (tagName += tags.data.attributes.name)
              : (tagName += tags.data.attributes.name + ", ");
          });

        return tagName;
      },
    },
    { headerName: VIEW_COUNT, field: "data.attributes.no_of_views" },
    {
      headerName: RATING,
      field: "data.attributes.average_rating",
      cellRenderer: (params) => {
        const averageRating = params.data.data.attributes.average_rating;
        const roundedRating = averageRating
          ? Number(averageRating).toFixed(2)
          : 0;
        return roundedRating;
      },
    },
    {
      headerName: ACTION,
      field: "id",
      filter: false,
      cellRenderer: (params) => (
        <div className="flex flex-row items-center space-x-5">
          <button
            onClick={() => {
              setToggleDetail(true);
              setSelectedComic(params.data);
            }}
            className="py-0 bg-transparent rounded-full text-default"
          >
            <BiMessageSquareDetail size="25" />
          </button>
          <button
            className="text-red-400 bg-transparent rounded-full"
            onClick={() =>
              openingModalElement(
                params.data.data.comicbook_id,
                params.data.data.attributes.title
              )
            }
          >
            <AiFillDelete size="25" />
          </button>
        </div>
      ),
    },
  ];

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDelletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDelletableFieldName("");
  };

  // onHandle toggle change selection
  const onHandleChangeSelection = (e) => {
    setToggleSelection(e.target.value);
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto bg-lightbg">
      {/* Comic Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">
            {toggleSelection === ComicDisplayOption.COMIC
              ? COMIC_MANAGEMENT
              : toggleSelection === ComicDisplayOption.SERIES
              ? SERIES_MANAGEMENT
              : null}
          </p>
          <p className="text-sm font-thin">
            {toggleSelection === ComicDisplayOption.COMIC
              ? COMIC_MANAGEMENT_DESCRIPTION
              : toggleSelection === ComicDisplayOption.SERIES
              ? SERIES_MANAGEMENT_DESCRIPTION
              : null}
          </p>
        </div>
        <select
          id="toggleSelection"
          name="toggleSelection"
          className="w-1/4 px-3 py-3 bg-transparent border rounded-lg text-textColor"
          onChange={(e) => onHandleChangeSelection(e)}
        >
          <option value={ComicDisplayOption.COMIC}>{COMIC_BOOK}</option>
          <option value={ComicDisplayOption.SERIES}>{SERIES}</option>
        </select>
      </div>

      {/* View Comic */}
      {toggleSelection === ComicDisplayOption.COMIC ? (
        <div
          className="w-full text-black placeholder-black bg-transparent border ag-theme-material"
          style={{ height: "80vh", color: "black" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowSelectionType={rowSelectionType}
            rowMultiSelectWithClick={true}
            pagination={true}
            className="text-left text-black placeholder-black bg-transparent"
          ></AgGridReact>
        </div>
      ) : (
        <ViewSeries data={getSeries} />
      )}
      <ActionModal
        fromName={FROM_Comic}
        actionFromName={DELETE_FROM_COMIC_BOOK}
        isModalOpen={isModalOpen}
        onClose={closingModalElement}
        onConfirm={async () => {
          deleteComic(selectedDeletableField);
          closingModalElement();
        }}
        itemName={selectedDelletableFieldName}
      />
    </div>
  );
};

// Comic Right Side
const ComicDashRightSide = ({
  getComic,
  getSeries,
  gettotalNumberOfTwoMonthViews,
  gettoalNumberOfViews,
}) => {
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto shadow-2xl bg-lightbg text-lightDefault ">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">
          {PERFORM_UTILITY_FUNCTIONS_ON_COMIC}
        </p>
        <p className="text-4xl font-bold text-default">{UTILITY}</p>
      </div>

      {/* Add Comic */}
      <Link
        to={{
          pathname: "/comic/create",
        }}
        state={{ selectedService: ComicOperation.ADD_COMIC }}
        className="flex flex-row items-start space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_COMIC}</p>
          <p className="text-sm text-default">{ADD_NEW_COMIC_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Add Series */}
      <Link
        to={{
          pathname: "/series/create",
        }}
        state={{ selectedService: ComicOperation.ADD_SERIES }}
        className="flex flex-row items-start mt-8 space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_SERIES}</p>
          <p className="text-sm text-default">{ADD_NEW_SERIES_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Add Author */}
      <Link
        to={{
          pathname: "/author/create",
        }}
        state={{ selectedService: AuthorOperation.ADD_AUTHOR }}
        className="flex flex-row items-start mt-8 space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_AUTHOR}</p>
          <p className="text-sm text-default">{ADD_NEW_AUTHOR_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Add Genre */}
      <Link
        to={{
          pathname: "/supplement/create",
        }}
        state={{ selectedService: SupplementsOperation.ADD_GENRE }}
        className="flex flex-row items-start mt-8 space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_GENRE}</p>
          <p className="text-sm text-default">{ADD_NEW_GENRE_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Add Tag */}
      <Link
        to={{
          pathname: "/supplement/create",
        }}
        state={{ selectedService: SupplementsOperation.ADD_TAG }}
        className="flex flex-row items-start mt-8 space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_TAG}</p>
          <p className="text-sm text-default">{ADD_NEW_TAG_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        {/* Each Notice */}
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getComic)}
            </p>
            <p className="text-sm text-default">{TOTAL_COMIC_IN_STORE}</p>
          </div>
        </div>
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getSeries)}
            </p>
            <p className="text-sm text-default">{TOTAL_SERIES_IN_STORE}</p>
          </div>
        </div>
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(gettoalNumberOfViews)}
            </p>
            <p className="text-sm text-default">{TOTAL_NUMBER_OF_VIEWS}</p>
          </div>
        </div>
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(gettotalNumberOfTwoMonthViews)}
            </p>
            <p className="text-sm text-default">
              {TOTAL_NUMBER_OF_TWO_MONTH_VIEWS}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteComic: (blogId) => dispatch(deleteComic(blogId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
