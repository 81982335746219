import React, { useRef, useState, useEffect } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import SearchForm from "../Form/SearchForm";

// Image
import Logo from "../../assets/images/Pharania Logo White.png";
import LogoText from "../../assets/images/Pharania Logo Text White.png";
import {
  AUTHOR,
  GENRE,
  LOADING_SIGN_OUT,
  SIGN_IN,
  SIGN_OUT,
} from "../../config/app_strings";
import {
  encryptAndDecryptLocalStorage,
  isGuestUserStillLoggedIn,
} from "../../actions/AuthAction";
import { apiWithOutCredentials } from "../../config/api";

function Nav({ from, genres, authors, isNavVisible }) {
  // useNavigate
  let history = useNavigate();

  //
  const ref = useRef(null);

  // State variables
  const [activeNavBar, setActiveNavBar] = useState(
    from === "landing" ? false : true
  );
  const [isSideNavActive, setSideNavActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * The function changes the background of the navigation bar based on the user's scroll position or
   * search status.
   */
  const changeBackground = () => {
    if (window.scrollY >= 475 || from === "search") setActiveNavBar(true);
    else setActiveNavBar(false);
  };

  /**
   * This function changes the current index to the specified index.
   * @param index - The parameter `index` is a variable that represents the new value of the current
   * index. It is passed as an argument to the `changeCurrentIndex` function.
   */
  const changeCurrentIndex = (index) => {
    setCurrentIndex(index);
  };

  /**
   * The function checks if a click event occurred outside a specified element and sets a state
   * variable accordingly.
   * @param event - The `event` parameter is an object that represents the event that triggered the
   * function. In this case, it is likely a mouse click event that occurred outside of the `ref`
   * element. The function checks if the `ref` element exists and if the event target is not contained
   * within it, and
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSideNavActive(false);
    }
  };

  /**
   * This function sets the side navigation to inactive and pushes the selected genre to the history
   * state.
   * @param genre - The `genre` parameter is a variable that represents a specific genre of music. It
   * is passed as an argument to the `onGenrePressed` function. The function sets the `sideNavActive`
   * state to `false` and then uses the `history.push` method to navigate to the "/genre
   */

  const onGenrePressed = (genre) => {
    setSideNavActive(false);
    history("/genre", { state: genre });
  };

  /**
   * This function sets the side navigation to inactive and navigates to a specific author's page.
   * @param author - The `author` parameter is a variable that represents the author object that is
   * being passed as a parameter to the `onAuthorPressed` function. This object is then used to set the
   * state of the `SideNavActive` variable to `false` and to navigate to a new page using the `
   */
  const onAuthorPressed = (author) => {
    setSideNavActive(false);
    history("/author/" + author.data.attributes.name, { state: author });
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // Event listener for detecting the scroll position
  window.addEventListener("scroll", changeBackground);

  /* This code is creating a variable `mappingGenres` that maps over an array of genres and returns a
  list of `<li>` elements with the genre name and an `onClick` event that calls the `onGenrePressed`
  function with the genre object as a parameter. The `key` attribute is set to the `genre_id`
  property of the genre object. If `genres.data` is falsy or has a length of 0, `mappingGenres` is
  set to `null`. */
  const mappingGenres =
    genres.data && genres.data.length > 0
      ? genres.data.map((genre) => (
          <li
            className="cursor-pointer"
            onClick={() => onGenrePressed(genre)}
            key={genre.data.genre_id}
          >
            {genre.data.attributes.name}
          </li>
        ))
      : null;
  const [loading, setLoading] = useState(false);

  const onLogOutButtonClicked = async () => {
    setLoading(true);

    const getAccessToken = await encryptAndDecryptLocalStorage(
      localStorage.getItem("login_user_token"),
      false
    );
    const header = {
      headers: {
        Authorization: `Bearer ${getAccessToken}`,
      },
    };

    try {
      await apiWithOutCredentials
        .get("/sanctum/csrf-cookie")
        .then(() => {
          apiWithOutCredentials
            .post("api/logout", {}, header)
            .then(() => {
              localStorage.clear();
              history("/");
            })
            .catch((error) => error)
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => error)
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  /* This code is creating a variable `mappingAuthors` that maps over an array of authors and returns a
  list of `<li>` elements with the author name and an `onClick` event that calls the
  `onAuthorPressed` function with the author object as a parameter. The `key` attribute is set to
  the `author_id` property of the author object. If `authors.data` is falsy or has a length of 0,
  `mappingAuthors` is set to `null`. */
  const mappingAuthors =
    authors.data && authors.data.length > 0
      ? authors.data.map((author) => (
          <li
            key={author.data.author_id}
            className="cursor-pointer"
            onClick={() => onAuthorPressed(author)}
          >
            {author.data.attributes.name}
          </li>
        ))
      : null;

  return (
    <div ref={ref} className={`${isNavVisible ? "block" : "hidden"}`}>
      <nav
        className={
          activeNavBar
            ? "bg-default fixed flex flex-nowrap items-center justify-between w-full px-6 z-10"
            : "fixed flex flex-nowrap items-center justify-between w-full px-6 py-4 bg-transparent z-20"
        }
      >
        <div className="flex items-center flex-shrink-0 mr-12">
          {isSideNavActive ? null : (
            <HiMenuAlt2
              className="cursor-pointer"
              size="26"
              type="button"
              color="#fff"
              onClick={() => {
                setSideNavActive(true);
              }}
            />
          )}
        </div>
        <div
          className="flex flex-row items-center justify-center w-auto space-x-4 cursor-pointer"
          onClick={() => history("/")}
        >
          <img
            src={Logo}
            className={activeNavBar ? "flex mini-logo" : "hidden"}
            alt="Pharania Logo"
          />
          <img
            src={LogoText}
            className={activeNavBar ? "flex mini-logo-text h-auto" : "hidden"}
            alt="Pharania Logo Text"
          />
        </div>
        <div className="flex flex-row  space-x-3 items-center ">
          <div className="flex flex-row w-2/3 pt-3 space-x-3">
            <SearchForm />
          </div>

          {!isGuestUserStillLoggedIn() === true && (
            <Link
              to="/auth/sign-in"
              className="rounded-md px-4 py-3 text-sm text-white bg-passOverColor  whitespace-nowrap cursor-pointer"
            >
              {SIGN_IN}
            </Link>
          )}
          {isGuestUserStillLoggedIn() && (
            <div onClick={onLogOutButtonClicked}>
              {loading ? (
                <div className="rounded-md px-4 py-3 text-sm text-white bg-passOverColor whitespace-nowrap  cursor-pointer">
                  {LOADING_SIGN_OUT}
                </div>
              ) : (
                <div className="rounded-md px-4 py-3 text-sm text-white bg-passOverColor whitespace-nowrap  cursor-pointer">
                  {SIGN_OUT}
                </div>
              )}
            </div>
          )}
        </div>
      </nav>

      {isSideNavActive ? (
        <div className="fixed z-40 flex flex-col w-64 transition duration-500 transform bg-darkerBackground opacity-80">
          <IoClose
            className="absolute right-5 top-5 cursor-pointer"
            color="white"
            onClick={() => setSideNavActive(false)}
          />
          <div className="flex flex-row items-center h-20 px-5 mt-10 space-x-16 shadow-xl">
            <div
              className="cursor-pointer flex flex-col justify-center items-center"
              onClick={() => changeCurrentIndex(0)}
            >
              <p
                className={
                  "text-base text-white " +
                  (currentIndex === 0 ? "font-bold" : "")
                }
              >
                {GENRE}
              </p>
              {currentIndex === 0 ? <p className="text-white">&bull;</p> : null}
            </div>
            <div
              className="cursor-pointer flex flex-col justify-center items-center"
              onClick={() => changeCurrentIndex(1)}
            >
              <p
                className={
                  "text-base text-white " +
                  (currentIndex === 1 ? "font-bold" : "")
                }
              >
                {AUTHOR}
              </p>
              {currentIndex === 1 ? <p className="text-white">&bull;</p> : null}
            </div>
          </div>
          <ul className="flex flex-col px-5 py-4 space-y-5 text-white min-h-screen max-h-screen overflow-auto">
            {currentIndex === 0
              ? mappingGenres
              : currentIndex === 1
              ? mappingAuthors
              : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isNavVisible: state.utility.isNavBarVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
