import React, { useState } from "react";
import { connect } from "react-redux";
import SlickSlider from "../../../components/SlickSlider";
import "react-medium-image-zoom/dist/styles.css";
import { IoClose } from "react-icons/io5";

// Component
import { setNavBarVisibility } from "../../../actions/UtilityAction";
import {
  rateComicBook,
  calculatePageNumberViews,
} from "../../../actions/ComicAction";
import { NO_PAGE_IN_CHAPTER } from "../../../config/app_strings";
import ReadingSectionBodyBottom from "../../../components/ReadingSectionBodyBottom";
import { isMobile } from "react-device-detect";

function Body({
  selectedChapter,
  comicBookChapters,
  comicbook,
  rateComicBook,
  calculatePageNumberViews,
  onCloseModal,
}) {
  const [activeChapter, setActiveChapter] = useState(
    selectedChapter.data ? selectedChapter.data.chapter_id : 0
  );
  const [isPageLoading, setPageLoading] = useState(false);
  const [selectedChapterPages, setSelectedChapterPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);

  return (
    <div className="lg:w-3/5 flex-grow lg:h-screen">
      {isMobile ? (
        <button
          className="font-bold absolute top-3 right-0 left-0 z-50 w-14 h-14 rounded-full bg-gray-600 bg-opacity-90 m-auto flex flex-row items-center justify-center"
          onClick={(e) => {
            onCloseModal(e);
            setNavBarVisibility(true);
          }}
        >
          <IoClose className="text-white" />
        </button>
      ) : null}
      {selectedChapterPages &&
      selectedChapterPages.data &&
      selectedChapterPages.data.data.length > 0 ? (
        <SlickSlider
          selectedChapterPages={selectedChapterPages}
          selectedPage={selectedPage}
          isPageLoading={isPageLoading}
          setSelectedPage={setSelectedPage}
        />
      ) : (
        <EmptyPlaceholder />
      )}
      <ReadingSectionBodyBottom
        activeChapter={activeChapter}
        setActiveChapter={setActiveChapter}
        isPageLoading={isPageLoading}
        setPageLoading={setPageLoading}
        selectedChapterPages={selectedChapterPages}
        setSelectedChapterPages={setSelectedChapterPages}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        comicBookChapters={comicBookChapters}
        comicbook={comicbook}
        rating={comicbook.attributes.average_rating}
        rateComicBook={rateComicBook}
        calculatePageNumberViews={calculatePageNumberViews}
      />
    </div>
  );
}

const EmptyPlaceholder = () => {
  return (
    <div className="m-auto flex flex-col justify-center items-center text-white no-page-in-chapter">
      {NO_PAGE_IN_CHAPTER}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    rateComicBook: (comicbook_id, rating) =>
      dispatch(rateComicBook(comicbook_id, rating)),
    setNavBarVisibility: (isVisible) =>
      dispatch(setNavBarVisibility(isVisible)),
    calculatePageNumberViews: (pageId) =>
      dispatch(calculatePageNumberViews(pageId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
