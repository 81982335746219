import React, { useEffect, useState } from "react";

import { Link, Navigate } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine, RiRefreshLine } from "react-icons/ri";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import ViewGenres from "./ViewGenres";
import ViewTag from "./ViewTag";
import { numberFormat } from "../../../components/Utility/Variables";
import { instance } from "../../../config/api";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import {
  SupplementsDisplayOption,
  SupplementsOperation,
} from "../../../enums/supplements";
import {
  ADD_GENRE,
  ADD_NEW_GENRE_DESCRIPTION,
  ADD_NEW_TAG_DESCRIPTION,
  ADD_TAG,
  CHOOSE_OPTION,
  GENRE_MANAGEMENT,
  GENRE_MANAGEMENT_DESCRIPTION,
  PERFORM_UTILITY_FUNCTIONS_ON_SUPPLEMENTS,
  SUMMARY,
  TAG_MANAGEMENT,
  TAG_MANAGEMENT_DESCRIPTION,
  TOTAL_GENRE_IN_STORE,
  TOTAL_TAG_IN_STORE,
  UTILITY,
} from "../../../config/app_strings";
import ShimmerIndicator from "../../../components/Shimmer/ShimmerIndicator";

export default function Root() {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div>
      <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
        <SideNav />
        <SupplementDashContent />
      </div>
    </div>
  );
}

const SupplementDashContent = ({ deleteBlog, isLoading }) => {
  const [getGenre, setGenre] = useState([]);
  const [getTags, setTags] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    fetchGenre();
    fetchTag();
  }, []);

  // Fetch Genre
  async function fetchGenre() {
    setStateLoading(true);
    await instance
      .get("genres", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        setGenre(response.data.data);
      })
      .catch((error) => {});
    setStateLoading(false);
  }

  // Fetch Tag
  async function fetchTag() {
    setStateLoading(true);
    await instance
      .get("tags", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        setTags(response.data.data);
      })
      .catch((error) => {});
    setStateLoading(false);
  }

  return (
    <div className="flex flex-row w-full text-left bg-lightbg">
      {isLoading ? (
           <ShimmerIndicator width={250} height={150} count={6} />
      ) : (
        <SupplementLeftContent
          deleteBlog={deleteBlog}
          isLoading={isLoading}
          data={getGenre}
          tagData={getTags}
          fetchGenre={fetchGenre}
          fetchTag={fetchTag}
        />
      )}

      <SupplementDashRightSide
        getGenre={getGenre.length}
        getTags={getTags.length}
      />
    </div>
  );
};

const SupplementLeftContent = ({ data, fetchGenre, fetchTag, tagData }) => {
  // state
  const [toggleSelection, setToggleSelection] = useState(
    SupplementsDisplayOption.GENRE
  );

  // onHandle toggle change selection
  const onHandleChangeSelection = (e) => {
    setToggleSelection(e.target.value);
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* Genre Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">
            {toggleSelection === SupplementsDisplayOption.GENRE
              ? GENRE_MANAGEMENT
              : toggleSelection === SupplementsDisplayOption.TAG
              ? TAG_MANAGEMENT
              : CHOOSE_OPTION}
          </p>
          <p className="text-sm font-thin">
            {toggleSelection === SupplementsDisplayOption.GENRE
              ? GENRE_MANAGEMENT_DESCRIPTION
              : toggleSelection === SupplementsDisplayOption.TAG
              ? TAG_MANAGEMENT_DESCRIPTION
              : ""}
          </p>
        </div>
        <div className="flex flex-row items-center w-1/3 space-x-5">
          <RiRefreshLine
            title="Refresh"
            size="25px"
            className="text-default"
            type="button"
            onClick={() =>
              toggleSelection === SupplementsDisplayOption.GENRE
                ? fetchGenre()
                : fetchTag()
            }
          />
          <select
            id="toggleSelection"
            name="toggleSelection"
            className="w-3/4 px-3 py-3 bg-transparent border rounded-lg text-textColor"
            onChange={(e) => onHandleChangeSelection(e)}
          >
            <option value={SupplementsDisplayOption.GENRE}>Genres</option>
            <option value={SupplementsDisplayOption.TAG}>Tag</option>
          </select>
        </div>
      </div>

      {/* View */}
      {toggleSelection === SupplementsDisplayOption.GENRE ? (
        <ViewGenres data={data} />
      ) : (
        <ViewTag data={tagData} />
      )}
    </div>
  );
};

// User Right Side
const SupplementDashRightSide = ({ getGenre, getTags }) => {
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto bg-white shadow-2xl text-lightDefault">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">
          {PERFORM_UTILITY_FUNCTIONS_ON_SUPPLEMENTS}
        </p>
        <p className="text-4xl font-bold text-default">{UTILITY}</p>
      </div>

      {/* Add Genre */}
      <Link
        to={{
          pathname: "/supplement/create",
        }}
        state={{ selectedService: SupplementsOperation.ADD_GENRE }}
        className="flex flex-row items-start space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_GENRE}</p>
          <p className="text-sm text-default">{ADD_NEW_GENRE_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Add Tag */}
      <Link
        to={{
          pathname: "/supplement/create",
        }}
        state={{ selectedService: SupplementsOperation.ADD_TAG }}
        className="flex flex-row items-start mt-8 space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_TAG}</p>
          <p className="text-sm text-default">{ADD_NEW_TAG_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        {/* Each Notice */}
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getGenre)}
            </p>
            <p className="text-sm text-default">{TOTAL_GENRE_IN_STORE}</p>
          </div>
        </div>
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">{numberFormat.format(getTags)}</p>
            <p className="text-sm text-default">{TOTAL_TAG_IN_STORE}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
