import React from "react";
import { Navigate, Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { connect } from "react-redux";

import { isUserStillLoggedIn, createUser } from "../../../actions/AuthAction";
import SideNav from "../../../components/Nav/SideNav";
import ManipulateAdminUserForm from "../../../components/Form/ManipulateAdminUserForm";
import { ADD_ADMIN_USER, ADD_ADMIN_USER_DESCRIPTION } from "../../../config/app_strings";

function ManageAdminUser(props) {

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center justify-between space-x-5">
          <div className="flex flex-row items-center space-x-5">
            <Link to={"/users"}>
              <FaLongArrowAltLeft className="text-default " size="30" />
            </Link>
            <div className="flex flex-col py-10">
              <p className="text-4xl font-bold text-textColor">
                {ADD_ADMIN_USER}
              </p>
              <p className="text-sm font-thin">
                {ADD_ADMIN_USER_DESCRIPTION}
              </p>
            </div>
          </div>
        </div>

        <ManipulateAdminUserForm
            isLoading={props.isLoading}
            createUser={props.createUser}
            adminSubmissionStatus={props.isAdminSubmissionSuccessful}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
      isLoading: state.app.isUserLoading,
      isAdminSubmissionSuccessful: state.auth.isAdminSubmissionSuccessful,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      createUser: (content) =>
        dispatch(createUser(content)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ManageAdminUser);
  
