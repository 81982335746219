// App
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const RESET_LOADER = "RESET_LOADER";

export const PAGE_START_LOADER = "PAGE_START_LOADER";
export const PAGE_STOP_LOADER = "PAGE_STOP_LOADER";
export const PAGE_RESET_LOADER = "PAGE_RESET_LOADER";

export const SERIES_START_LOADER = "SERIES_START_LOADER";
export const SERIES_STOP_LOADER = "SERIES_STOP_LOADER";
export const SERIES_RESET_LOADER = "SERIES_RESET_LOADER";

export const COMIC_BOOK_START_LOADER = "COMIC_BOOK_START_LOADER";
export const COMIC_BOOK_STOP_LOADER = "COMIC_BOOK_STOP_LOADER";
export const COMIC_BOOK_RESET_LOADER = "COMIC_BOOK_RESET_LOADER";

export const AUTHOR_START_LOADER = "AUTHOR_START_LOADER";
export const AUTHOR_STOP_LOADER = "AUTHOR_STOP_LOADER";
export const AUTHOR_RESET_LOADER = "AUTHOR_RESET_LOADER";

export const UPGRADE_READER_START_LOADER = "UPGRADE_READER_START_LOADER";
export const UPGRADE_READER_STOP_LOADER = "UPGRADE_READER_STOP_LOADER";
export const UPGRADE_READER_RESET_LOADER = "UPGRADE_READER_RESET_LOADER";

export const GENRE_START_LOADER = "GENRE_START_LOADER";
export const GENRE_STOP_LOADER = "GENRE_STOP_LOADER";
export const GENRE_RESET_LOADER = "GENRE_RESET_LOADER";

export const TAG_START_LOADER = "TAG_START_LOADER";
export const TAG_STOP_LOADER = "TAG_STOP_LOADER";
export const TAG_RESET_LOADER = "TAG_RESET_LOADER";

export const USER_START_LOADER = "USER_START_LOADER";
export const USER_STOP_LOADER = "USER_STOP_LOADER";
export const USER_RESET_LOADER = "USER_RESET_LOADER";

export const RESET_PASSWORD_START_LOADER = "RESET_PASSWORD_START_LOADER";
export const RESET_PASSWORD_STOP_LOADER = "RESET_PASSWORD_STOP_LOADER";
export const RESET_PASSWORD_RESET_LOADER = "RESET_PASSWORD_RESET_LOADER";

export const FORGOT_PASSWORD_START_LOADER="FORGOT_PASSWORD_START_LOADER";
export const FORGOT_PASSWORD_STOP_LOADER="FORGOT_PASSWORD_STOP_LOADER";
export const FORGOT_PASSWORD_RESET_LOADER="RESET_PASSWORD_RESET_LOADER";



export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED="RESET_PASSWORD_FAILED";




export const GUEST_READER_START_LOADER="READER_START_LOADER";
export const GUEST_READER_STOP_LOADER="READER_STOP_LOADER";
export const GUEST_READER_RESET_LOADER="READER_RESET_LOADER";



export const ADVERT_START_LOADER = "ADVERT_START_LOADER";
export const ADVERT_STOP_LOADER = "ADVERT_STOP_LOADER";
export const ADVERT_RESET_LOADER = "ADVERT_RESET_LOADER";

export const CHAPTER_START_LOADER = "CHAPTER_START_LOADER";
export const CHAPTER_STOP_LOADER = "CHAPTER_STOP_LOADER";
export const CHAPTER_RESET_LOADER = "CHAPTER_RESET_LOADER";

// Auth
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

// User
export const GUEST_USER_SUCCESS="READER_SUCCESS";
export const GUEST_USER_FAILED="READER_FAILED";





export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED="FORGOT_PASSWORD_FAILED";



export const GUEST_LOGIN_SUCCESS = "GUEST_LOGIN_SUCCESS";
export const GUEST_LOGIN_FAILED = "GUEST_LOGIN_FAILED";

export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILED = "EDIT_ADMIN_FAILED";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED";

// Role
export const ROLE_SUCCESS = "ROLE_SUCCESS";
export const ROLE_FAILED = "ROLE_FAILED";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";

// Genre
export const ADD_GENRE_SUCCESS = "GENRE_SUCCESS";
export const ADD_GENRE_FAILED = "GENRE_FAILED";
export const EDIT_GENRE_SUCCESS = "EDIT_GENRE_SUCCESS";
export const EDIT_GENRE_FAILED = "EDIT_GENRE_FAILED";
export const DELETE_GENRE_SUCCESS = "DELETE_GENRE_SUCCESS";
export const DELETE_GENRE_FAILED = "DELETE_GENRE_FAILED";
export const RELOAD = "RELOAD";

// Tag
export const ADD_TAG_SUCCESS = "TAG_SUCCESS";
export const ADD_TAG_FAILED = "TAG_FAILED";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";
export const TAG_RELOAD = "TAG_RELOAD";

// Author
export const ADD_AUTHOR_SUCCESS = "AUTHOR_SUCCESS";
export const ADD_AUTHOR_FAILED = "AUTHOR_FAILED";
export const EDIT_AUTHOR_SUCCESS = "EDIT_AUTHOR_SUCCESS";
export const EDIT_AUTHOR_FAILED = "EDIT_AUTHOR_FAILED";
export const DELETE_AUTHOR_SUCCESS = "DELETE_AUTHOR_SUCCESS";
export const DELETE_AUTHOR_FAILED = "DELETE_AUTHOR_FAILED";
export const AUTHOR_RELOAD = "AUTHOR_RELOAD";

//reader
export const UPGRADE_READER_SUCCESS = "UPGRADE_READER_SUCCESS";
export const UPGRADE_READER_FAILED = "UPGRADE_READER_FAILED";

//Author ranking
export const AUTHOR_RANK_RESET_START_LOADER = "AUTHOR_START_LOADER";
export const AUTHOR_RANK_RESET_STOP_LOADER = "AUTHOR_STOP_LOADER";
export const AUTHOR_RANK_RESET_LOADER = "AUTHOR_RESET_LOADER";
export const AUTHOR_RANKING_RESET_SUCCESS = "AUTHOR_RANKING_RESET_SUCCESS";
export const AUTHOR_RANKING_RESET_FAILED = "AUTHOR_RANKING_RESET_FAILED";

// Series
export const ADD_SERIES_SUCCESS = "SERIES_SUCCESS";
export const ADD_SERIES_FAILED = "SERIES_FAILED";
export const EDIT_SERIES_SUCCESS = "EDIT_SERIES_SUCCESS";
export const EDIT_SERIES_FAILED = "EDIT_SERIES_FAILED";
export const DELETE_SERIES_SUCCESS = "DELETE_SERIES_SUCCESS";
export const DELETE_SERIES_FAILED = "DELETE_SERIES_FAILED";
export const SERIES_RELOAD = "SERIES_RELOAD";

// Comic
export const ADD_COMIC_BOOK_SUCCESS = "COMIC_BOOK_SUCCESS";
export const ADD_COMIC_BOOK_FAILED = "COMIC_BOOK_FAILED";
export const EDIT_COMIC_BOOK_SUCCESS = "EDIT_COMIC_BOOK_SUCCESS";
export const EDIT_COMIC_BOOK_FAILED = "EDIT_COMIC_BOOK_FAILED";
export const DELETE_COMIC_BOOK_SUCCESS = "DELETE_COMIC_BOOK_SUCCESS";
export const DELETE_COMIC_BOOK_FAILED = "DELETE_COMIC_BOOK_FAILED";
export const COMIC_BOOK_RELOAD = "COMIC_BOOK_RELOAD";

export const OPERATION_SUCCESS = "OPERATION_SUCCESS";
export const OPERATION_FAILED = "OPERATION_FAILED";

// Advert
export const ADD_ADVERT_SUCCESS = "ADVERT_SUCCESS";
export const ADD_ADVERT_FAILED = "ADVERT_FAILED";
export const EDIT_ADVERT_SUCCESS = "EDIT_ADVERT_SUCCESS";
export const EDIT_ADVERT_FAILED = "EDIT_ADVERT_FAILED";
export const DELETE_ADVERT_SUCCESS = "DELETE_ADVERT_SUCCESS";
export const DELETE_ADVERT_FAILED = "DELETE_ADVERT_FAILED";
export const ADVERT_RELOAD = "ADVERT_RELOAD";

// Page
export const ADD_PAGE_SUCCESS = "PAGE_SUCCESS";
export const ADD_PAGE_FAILED = "PAGE_FAILED";
export const EDIT_PAGE_SUCCESS = "EDIT_PAGE_SUCCESS";
export const EDIT_PAGE_FAILED = "EDIT_PAGE_FAILED";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const DELETE_PAGE_FAILED = "DELETE_PAGE_FAILED";
export const PAGE_RELOAD = "PAGE_RELOAD";

// Chapter
export const ADD_CHAPTER_SUCCESS = "ADD_CHAPTER_SUCCESS";
export const ADD_CHAPTER_FAILED = "ADD_CHAPTER_FAILED";
export const DELETE_CHAPTER_SUCCESS = "DELETE_CHAPTER_SUCCESS";
export const DELETE_CHAPTER_FAILED = "DELETE_CHAPTER_FAILED";

// Nav bar visibility
export const SET_NAV_BAR_VISIBILITY = "SET_NAV_BAR_VISIBILITY";
