import { SET_NAV_BAR_VISIBILITY } from "../actions/Type";

const initialState = {isNavBarVisible: true};

const UtilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAV_BAR_VISIBILITY:
            return {...state, isNavBarVisible: action.payload}
        default:
            return state;
    }
}

export default UtilityReducer;