import React, { useState, useEffect } from "react";

// Components
import Nav from "../../../components/Nav/Nav";
import Hero from "./sections/Hero";
import RootCarousel from "../../../components/Carousel/RootCarousel";
import { instance } from "../../../config/api";
import { HelmetProvider } from "react-helmet-async";
import { isAndroid, isMobile } from "react-device-detect";

import ShimmerIndicator from "../../../components/Shimmer/ShimmerIndicator";
import Footer from "../../../components/Footer/footer";
import PageHelmet from "../../../components/Helmet/PageHelmet";
import DownloadAppModal from "../../../components/Modal/DownloadAppModal";
import PreventContextMenu from "../../../components/PreventContextMenu";
import { NEWLY_RELEASED, POPULAR_COMIC, TRENDS } from "../../../config/app_strings";

export default function Root() {
  // Mapping
  const [totalComicBooks, setTotalComicBooks] = useState([]);
  const [totalGenres, setTotalGenres] = useState([]);
  const [totalAuthors, setTotalAuthors] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const id = window.location.pathname.replace("/comic/", "");

  const isRedirectedFromShareLink = id !== "/";

  useEffect(() => {
    Promise.all([fetchComicBooks()]);
    processDownloadMobileApp();
  }, []);

  /**
   * The function checks if the user is on a mobile device and if it is an Android device, and then
   * opens a download modal if both conditions are true.
   */
  async function processDownloadMobileApp() {
    if (isMobile && isAndroid) {
      setIsDownloadModalOpen(true);
    } else {
      setIsDownloadModalOpen(false);
    }
  }

  /**
   * This function fetches data for comic books, genres, and authors from an API and sets the retrieved
   * data to corresponding state variables.
   */
  async function fetchComicBooks() {
    setStateLoading(true);
    try {
      var [comicBooks, genres, authors] = await Promise.all([
        instance.get("comicBooks"),
        instance.get("genres"),
        instance.get("authors"),
      ]);
      setTotalComicBooks(comicBooks.data);
      setTotalGenres(genres.data);
      setTotalAuthors(authors.data);
    } catch (error) {}
    setStateLoading(false);
  }

  return (
    <HelmetProvider>
      <PreventContextMenu>
        <div className="w-screen">
          <PageHelmet />
          <Nav from="landing" genres={totalGenres} authors={totalAuthors} />
          <Hero />

          {isRedirectedFromShareLink ? (
            <RootCarousel />
          ) : stateLoading ? (
            <div className="px-16 py-20">
              <ShimmerIndicator count={8} width={200} height={300} />
            </div>
          ) : (
            <>
              <RootCarousel header={POPULAR_COMIC} data={totalComicBooks}   isNewlyReleased={false}/>
              <RootCarousel header={NEWLY_RELEASED} data={totalComicBooks}  isNewlyReleased={true}/>
            </>
          )}

          <Footer />

          {/* Modals */}
          <DownloadAppModal
            isDownloadModalOpen={isDownloadModalOpen}
            setIsDownloadModalOpen={setIsDownloadModalOpen}
          />
        </div>
      </PreventContextMenu>
    </HelmetProvider>
  );
}
