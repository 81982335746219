// External imports
import { toast } from "react-toastify";

// Component imports
import {
  RELOAD,
  EDIT_GENRE_SUCCESS,
  EDIT_GENRE_FAILED,
  DELETE_GENRE_SUCCESS,
  DELETE_GENRE_FAILED,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILED,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILED,
  ADD_GENRE_SUCCESS,
  ADD_GENRE_FAILED,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILED,
  ADD_AUTHOR_SUCCESS,
  ADD_AUTHOR_FAILED,
  EDIT_AUTHOR_SUCCESS,
  EDIT_AUTHOR_FAILED,
  DELETE_AUTHOR_SUCCESS,
  DELETE_AUTHOR_FAILED,
  AUTHOR_RANKING_RESET_SUCCESS,
  AUTHOR_RANKING_RESET_FAILED,
  UPGRADE_READER_SUCCESS,
  UPGRADE_READER_FAILED,
} from "../actions/Type";
import {
  AUTHOR_ADDED_FAILED,
  AUTHOR_ADDED_SUCCESSFULLY,
  AUTHOR_DELETED_FAILED,
  AUTHOR_DELETED_SUCCESSFULLY,
  AUTHOR_RANKING_RESET_SUCCESSFUL,
  AUTHOR_UPDATED_FAILED,
  AUTHOR_UPDATED_SUCCESSFULLY,
  GENRE_ADDED_FAILED,
  GENRE_ADDED_SUCCESSFULLY,
  GENRE_DELETED_FAILED,
  GENRE_DELETED_SUCCESSFULLY,
  GENRE_UPDATED_FAILED,
  GENRE_UPDATED_SUCCESSFULLY,
  READER_SUCCEFULLY_UPGRADED,
  READER_UPGRADE_FAILED,
  TAG_ADDED_FAILED,
  TAG_ADDED_SUCCESSFULLY,
  TAG_DELETED_FAILED,
  TAG_DELETED_SUCCESSFULLY,
  TAG_UPDATED_FAILED,
  TAG_UPDATED_SUCCESSFULLY,
} from "../config/app_strings";

const initialState = {
  isLoggedIn: false,
  isGenreSubmissionSuccessful: null,
  isTagSubmissionSuccessful: null,
  isAuthorSubmissionSuccessful: null,
};

const SupplementReducer = (state = initialState, action) => {
  switch (action.type) {
    /// Genre operations
    case ADD_GENRE_SUCCESS:
      toast.success(GENRE_ADDED_SUCCESSFULLY);
      return { ...state, isGenreSubmissionSuccessful: true };
    case ADD_GENRE_FAILED:
      toast.error(GENRE_ADDED_FAILED + action.payload);
      return { ...state, isGenreSubmissionSuccessful: false };
    case EDIT_GENRE_SUCCESS:
      toast.success(GENRE_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_GENRE_FAILED:
      toast.error(GENRE_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_GENRE_SUCCESS:
      toast.success(GENRE_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_GENRE_FAILED:
      toast.error(GENRE_DELETED_FAILED + action.payload);
      return { ...state };

    /// Tag operations
    case ADD_TAG_SUCCESS:
      toast.success(TAG_ADDED_SUCCESSFULLY);
      return { ...state, isTagSubmissionSuccessful: true };
    case ADD_TAG_FAILED:
      toast.error(TAG_ADDED_FAILED + action.payload);
      return { ...state, isTagSubmissionSuccessful: false };
    case EDIT_TAG_SUCCESS:
      toast.success(TAG_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_TAG_FAILED:
      toast.error(TAG_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_TAG_SUCCESS:
      toast.success(TAG_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_TAG_FAILED:
      toast.error(TAG_DELETED_FAILED + action.payload);
      return { ...state };

    /// Author operations
    case ADD_AUTHOR_SUCCESS:
      toast.success(AUTHOR_ADDED_SUCCESSFULLY);
      return { ...state, isAuthorSubmissionSuccessful: true };
    case ADD_AUTHOR_FAILED:
      toast.error(AUTHOR_ADDED_FAILED + action.payload);
      return { ...state, isAuthorSubmissionSuccessful: false };
    case EDIT_AUTHOR_SUCCESS:
      toast.success(AUTHOR_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_AUTHOR_FAILED:
      toast.error(AUTHOR_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_AUTHOR_SUCCESS:
      toast.success(AUTHOR_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_AUTHOR_FAILED:
      toast.error(AUTHOR_DELETED_FAILED + action.payload);
      return { ...state };

    // Reset Author Ranking operation
    case AUTHOR_RANKING_RESET_SUCCESS:
      toast.success(AUTHOR_RANKING_RESET_SUCCESSFUL);
      return { ...state };
    case AUTHOR_RANKING_RESET_FAILED:
      toast.error(AUTHOR_RANKING_RESET_FAILED + action.payload);
      return { ...state };

    //upgrade Reader
    case UPGRADE_READER_SUCCESS:
      toast.success(READER_SUCCEFULLY_UPGRADED);
      return { ...state };
    case UPGRADE_READER_FAILED:
      toast.error(READER_UPGRADE_FAILED);
      return { ...state };

    /// Reload
    case RELOAD:
      window.location.reload();
      return { ...state };
    default:
      return state;
  }
};

export default SupplementReducer;
