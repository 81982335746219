import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Component
import LeftAd from "./LeftAd";
import Body from "./Body";
import RightSection from "./RightSection";
import { instance } from "../../../config/api";
import { AdvertType } from "../../../enums/advert";
import {setNavBarVisibility} from "../../../actions/UtilityAction";
import { ClipLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import { isGuestUserStillLoggedIn, isUserStillLoggedIn } from "../../../actions/AuthAction";

/// Comic Book Chapter Modal
function Root({ onCloseModal, selectedChapter, comicBookChapters, comicBook, onParentClose, setNavBarVisibility }) {
  // State
  const [longTimeAdverts, setLongTermAdverts] = useState([]);
  const [shortTimeAdverts, setShortTermsAdverts] = useState([]);
  const [isAdvertLoading, setAdvertLoading] = useState(false);

  useEffect(() => {
    /**
     * This function fetches advertisements and categorizes them as long-term or short-term based on
     * their type and whether they are featured.
     */
    async function fetchAdverts() {
      setAdvertLoading(true);
      try {
        var adverts = await instance.get("advertisements");
        if(adverts && adverts.data && adverts.data.data.length > 0){
          adverts.data.data.map((advert) => {
            if (advert.data.attributes.type === AdvertType.PORTRAIT && advert.data.attributes.is_featured) {
              setLongTermAdverts((advertImage) => [...advertImage, { "url": advert.links.image }]);
            } else if (advert.data.attributes.type === AdvertType.SQUARE && advert.data.attributes.is_featured) {
              setShortTermsAdverts((advertImage) => [...advertImage, { "url": advert.links.image }]);
            }
          })
        }
      } catch (error) {
      }

      setNavBarVisibility(false);
      setAdvertLoading(false);
    }

    fetchAdverts();
  }, []);
  if (!isGuestUserStillLoggedIn()) return <Navigate to={"/auth/sign-in"} />;

  return (
    <div className="flex flex-col justify-between lg:flex-row min-w-screen max-w-screen min-h-screen max-h-screen">
      {isAdvertLoading ? <LoadingIndicator /> : <LeftAd adverts={longTimeAdverts} />}
      <Body onCloseModal={onCloseModal} selectedChapter={selectedChapter} comicBookChapters={comicBookChapters} comicbook={comicBook.data} />
      {isAdvertLoading ? (
        <LoadingIndicator />
      ) : (
        <RightSection onCloseModal={onCloseModal} onParentCloseModel={onParentClose} adverts={shortTimeAdverts} comicbookId={comicBook.data.comicbook_id} recommendations={comicBook.data.recommendations} />
      )}
    </div>
  );
}

const LoadingIndicator = () => {
  return (
    <div className="w-1/4 h-screen mr-5 flex items-center">
      <ClipLoader color="white" size="25px" className="m-auto" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isNavVisible: state.utility.isNavBarVisible,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNavBarVisibility: (isVisible) => dispatch(setNavBarVisibility(isVisible))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
