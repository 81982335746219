import React from "react";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD, WELCOME } from "../../../../../config/app_strings";

// Component imports
import LoginForm from "./LoginForm";
import RememberPassword from "./RememberPassword";

export default function Body() {
  return (
    <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl md:w-1/3 rounded-2xl">
      <p className="text-4xl font-extrabold text-lightbg">{WELCOME}</p>
      <LoginForm />
      <div className="flex flex-row items-center justify-between w-full px-10">
        <RememberPassword />
        <Link to="/forgot" className="text-lightDefault">
          {FORGOT_PASSWORD}
        </Link>
      </div>
    </div>
  );
}
