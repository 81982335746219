import React, { Component } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import {
  registerTag,
  updateTag,
  registerGenre,
  updateGenre,
} from "../../../actions/SupplementsAction";
import { validateEmptyString } from "../../../components/Utility/Validation";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { SupplementsOperation } from "../../../enums/supplements";
import {
  ADD_GENRE,
  ADD_NEW_GENRE_DESCRIPTION,
  ADD_NEW_TAG_DESCRIPTION,
  ADD_TAG,
  EDIT_GENRE,
  EDIT_GENRE_DESCRIPTION,
  EDIT_TAG,
  EDIT_TAG_DESCRIPTION,
  GENRE_NAME,
  MISSING_FIELD,
  SUBMIT,
  TAG_NAME,
} from "../../../config/app_strings";

function ManageSupplement(props) {
  let location = useLocation();

  // Getting selected service
  const selectedService = location.state.selectedService;
  const selectedGenre = location.state.selectedGenre;
  const selectedTag = location.state.selectedTag;

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center space-x-5">
          <Link to={"/supplements"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Supplement Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-textColor">
              {selectedService === SupplementsOperation.ADD_GENRE &&
              typeof selectedService !== "undefined"
                ? ADD_GENRE
                : selectedService === SupplementsOperation.EDIT_GENRE &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedGenre !== "undefined"
                ? EDIT_GENRE
                : selectedService === SupplementsOperation.ADD_TAG &&
                  typeof selectedService !== "undefined"
                ? ADD_TAG
                : selectedService === SupplementsOperation.EDIT_TAG &&
                  typeof selectedService !== "undefined" &&
                  selectedTag !== "undefined"
                ? EDIT_TAG
                : ""}
            </p>
            <p className="text-sm font-thin">
              {selectedService === SupplementsOperation.ADD_GENRE &&
              typeof selectedService !== "undefined"
                ? ADD_NEW_GENRE_DESCRIPTION
                : selectedService === SupplementsOperation.EDIT_GENRE &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedGenre !== "undefined"
                ? EDIT_GENRE_DESCRIPTION
                : selectedService === SupplementsOperation.ADD_TAG &&
                  typeof selectedService !== "undefined"
                ? ADD_NEW_TAG_DESCRIPTION
                : selectedService === SupplementsOperation.EDIT_TAG &&
                  typeof selectedService !== "undefined" &&
                  selectedTag !== "undefined"
                ? EDIT_TAG_DESCRIPTION
                : ""}
            </p>
          </div>
        </div>

        {/* Supplement form */}

        {selectedService === SupplementsOperation.ADD_GENRE ||
        selectedService === SupplementsOperation.EDIT_GENRE ? (
          <AddGenreForm
            selectedService={selectedService}
            selectedGenre={selectedGenre}
            isLoading={props.isGenreLoading}
            registerGenre={props.registerGenre}
            updateGenre={props.updateGenre}
            genreSubmissionStatus={props.isGenreSubmissionSuccessful}
          />
        ) : (
          <TagForm
            selectedService={selectedService}
            selectedTag={selectedTag}
            isLoading={props.isTagLoading}
            registerTag={props.registerTag}
            updateTag={props.updateTag}
            tagSubmissionStatus={props.isTagSubmissionSuccessful}
          />
        )}
      </div>
    </div>
  );
}

class AddGenreForm extends Component {
  state = {
    genreName:
      this.props.selectedGenre !== undefined &&
      this.props.selectedService === SupplementsOperation.EDIT_GENRE
        ? this.props.selectedGenre.attributes.name
        : "",
  };

  render() {
    // Props
    const {
      registerGenre,
      updateGenre,
      selectedService,
      selectedGenre,
      isLoading,
      genreSubmissionStatus,
    } = this.props;

    const resetForm = () => {
      this.state.genreName = "";
    };

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      //   Get the data
      const { genreName } = this.state;

      //   Validation
      if (!validateEmptyString(genreName)) {
        return toast.warning(MISSING_FIELD);
      }

      selectedService === SupplementsOperation.EDIT_GENRE
        ? await updateGenre(genreName, selectedGenre.genre_id)
        : await registerGenre(genreName);

      resetForm();
    };

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {GENRE_NAME}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="genreName"
              id="genreName"
              placeholder={GENRE_NAME}
              value={this.state.genreName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}

class TagForm extends Component {
  state = {
    tagName:
      this.props.selectedTag !== undefined &&
      this.props.selectedService === SupplementsOperation.EDIT_TAG
        ? this.props.selectedTag.attributes.name
        : "",
  };

  render() {
    // Props
    const {
      registerTag,
      updateTag,
      selectedService,
      selectedTag,
      tagSubmissionStatus,
      isLoading,
    } = this.props;
    const resetForm = () => {
      this.state.tagName = "";
    };

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      //   Get the data
      const { tagName } = this.state;

      //   Validation
      if (!validateEmptyString(tagName)) {
        return toast.warning(MISSING_FIELD);
      }

      selectedService === SupplementsOperation.EDIT_TAG
        ? await updateTag(tagName, selectedTag.tag_id)
        : await registerTag(tagName);
      resetForm();
    };

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {TAG_NAME}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="tagName"
              id="tagName"
              placeholder={TAG_NAME}
              value={this.state.tagName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isGenreLoading: state.app.isGenreLoading,
    isTagLoading: state.app.isTagLoading,
    isGenreSubmissionSuccessful: state.supplements.isGenreSubmissionSuccessful,
    isTagSubmissionSuccessful: state.supplements.isTagSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerTag: (tagContent) => dispatch(registerTag(tagContent)),
    updateTag: (tagName, tagId) => dispatch(updateTag(tagName, tagId)),
    registerGenre: (genreContent) => dispatch(registerGenre(genreContent)),
    updateGenre: (genreName, genreId) =>
      dispatch(updateGenre(genreName, genreId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSupplement);
