import React from "react";

import ComicBookImage from "../../assets/images/comic-books.jpg";
import PharaniaLogoTextWhite from "../../assets/images/Pharania Logo Text White.png";
import PharaniaLogoWhite from "../../assets/images/Pharania Logo White.png";
import GooglePlayStore from "../../assets/images/get-on-google-play.png";

import { AiFillLock } from "react-icons/ai";
import { BiMailSend, BiPen, BiPhone } from "react-icons/bi";

export default function Footer() {
  return (
    <div className="w-screen mt-32 relative md:h-56 h-96">
      <img
        src={ComicBookImage}
        alt="Comic books"
        className="footerImage w-screen opacity-20 h-full"
      />
      <div className="absolute left-10 right-10 bottom-0 top-0 flex flex-col justify-end">
        <div className="flex flex-col-reverse md:flex-row items-center md:items-end justify-between mb-2 md:gap-y-0 gap-y-4">
          <div className="flex flex-col md:flex-row gap-x-0 md:gap-y-0 gap-y-4 md:gap-x-4 items-center justify-center md:justify-left">
            <p className="text-white font-bold">Liens: </p>
            <a
              href="https://pharania.com/privacy-policy"
              className="text-sm text-white hover:text-passOverColor flex flex-row space-x-2 items-center cursor-pointer"
            >
              <span>Politique de confidentialité</span>{" "}
              <AiFillLock className="text-passOverColor" />
            </a>
            <a
              href="https://subscribe.pharania.com"
              className="text-sm text-white hover:text-passOverColor flex flex-row space-x-2 items-center"
            >
              <span>Abonnez-vous à l'auteur</span>{" "}
              <BiPen className="text-passOverColor" />
            </a>
          </div>
          <div className="flex flex-col space-y-2 items-center md:items-end">
            <div className="flex flex-col space-y-2 items-center">
              <img
                src={PharaniaLogoWhite}
                className="w-8"
                alt="Pharania Logo"
              />
              <img
                src={PharaniaLogoTextWhite}
                className="w-36"
                alt="Pharania Logo"
              />
            </div>
            <div className="flex flex-col md:flex-row space-x-3 justify-center md:justify-end items-center md:items-start text-sm">
              <p className="text-white font-bold">Contact:</p>
              <p className="flex flex-row space-x-2 text-white items-center">
                <BiPhone className="text-white" />{" "}
                <span>+251966490806, +2250595027818</span>
              </p>
              <a
                href="mailto:therealpharania@gmail.com"
                className="flex flex-row space-x-2 text-white items-center hover:text-passOverColor"
              >
                <BiMailSend className="text-white" />{" "}
                <span>therealpharania@gmail.com</span>
              </a>
            </div>
          </div>
        </div>
        <hr className="border border-passOverColor" />
        <div className="flex flex-col md:flex-row justify-between items-center mt-3">
          <p className="text-white text-sm">
            droits d'auteur @ {new Date().getFullYear()}, Alimenté par{" "}
            <span className="font-bold">
              <a href="https://vptrading.et">VP Trading</a>
            </span>
          </p>
          <a
            href="market://details?id=com.app.pharania&hl=en&gl=US"
            className="flex flex-row justify-center md:justify-end"
          >
            <img src={GooglePlayStore} className="w-36" alt="Pharania Logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
