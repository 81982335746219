import React, { useEffect } from "react";
import Rating from "react-rating";
import { BsStar, BsStarFill } from "react-icons/bs";
import { IoMdShareAlt } from "react-icons/io";
import { RWebShare } from "react-web-share";
import { instance } from "../config/api";
import {
  CHAPTERS,
  LOADING,
  NO_PAGE_FOUND,
  PAGE,
  PHARANIA_COMIC_BOOK,
  SEARCH_FIELD_PLACEHOLDER,
  SHARE,
} from "../config/app_strings";

const ReadingSectionBodyBottom = ({
  activeChapter,
  setActiveChapter,
  isPageLoading,
  setPageLoading,
  selectedChapterPages,
  setSelectedChapterPages,
  setSelectedPage,
  comicBookChapters,
  comicbook,
  rating,
  rateComicBook,
  selectedPage,
  calculatePageNumberViews,
}) => {
  useEffect(() => {
    async function fetchChapterPage() {
      setPageLoading(true);
      try {
        var pages = await instance.get("chapters/" + activeChapter + "/pages");
        setSelectedChapterPages(pages);
        if (pages.data.data.length >= 0) {
          setSelectedPage(pages[0]);
          calculatePageNumberViews(pages.data.data[0].data.page_id);
        }
      } catch (error) {
        setSelectedChapterPages([]);
      }
      setPageLoading(false);
    }

    if (activeChapter != null) fetchChapterPage();
  }, [activeChapter]);

  /// Functions
  const setSelectedChapterId = (e) => setActiveChapter(e.target.value);

  const generateDeepLink = () =>
    window.location.href + "comic/" + comicbook.comicbook_id;

  const mappingChapters =
    comicBookChapters &&
    comicBookChapters.data &&
    comicBookChapters.data.data &&
    comicBookChapters.data.data.length > 0
      ? comicBookChapters.data.data.map((chapter) => (
          <option value={chapter.data.chapter_id} key={chapter.data.chapter_id}>
            {chapter.data.attributes.title}
          </option>
        ))
      : null;

  const mappingPages =
    selectedChapterPages &&
    selectedChapterPages.data &&
    selectedChapterPages.data.data.length > 0 ? (
      selectedChapterPages.data.data.map((page, index) => (
        <option value={index} key={page.data.page_id}>
          {PAGE} {page.data.attributes.page_no}
        </option>
      ))
    ) : (
      <option value="">{NO_PAGE_FOUND}</option>
    );

  const ViewAndRating = (
    <div className="flex flex-col items-center w-1/3 space-y-2">
      {comicbook.attributes.no_of_views != null &&
      comicbook.attributes.no_of_views !== 0 ? (
        <p className="text-xs text-white">
          {comicbook.attributes.no_of_views} Vues
        </p>
      ) : null}
      <Rating
        emptySymbol={<BsStar />}
        fullSymbol={<BsStarFill />}
        fractions={2}
        initialRating={rating}
        className="text-white"
        onClick={(value) => rateComicBook(comicbook.comicbook_id, value)}
      />
    </div>
  );

  const FilterForm = (
    <div className="flex flex-row w-2/3 space-x-5">
      <select
        type="text"
        placeholder={SEARCH_FIELD_PLACEHOLDER}
        className="relative w-1/2 px-3 py-1 pl-3 text-sm text-white placeholder-gray-400 bg-black border border-gray-400 rounded outline-none focus:outline-none focus:ring"
        value={activeChapter}
        onChange={(e) => {
          setSelectedChapterId(e);
          setSelectedPage("");
        }}
      >
        <option value={0}>{CHAPTERS}</option>
        {mappingChapters}
      </select>
      <select
        type="text"
        placeholder={SEARCH_FIELD_PLACEHOLDER}
        className="relative w-1/2 px-3 py-1 pl-3 text-sm text-white placeholder-gray-400 bg-black border border-gray-400 rounded outline-none focus:outline-none focus:ring"
        onChange={(e) => {
          setSelectedPage(e.target.value);
          calculatePageNumberViews(e.target.key);
        }}
        value={selectedPage}
      >
        {isPageLoading ? <option value={0}>{LOADING} </option> : mappingPages}
      </select>
    </div>
  );

  const ShareButton = (
    <RWebShare
      data={{
        text: PHARANIA_COMIC_BOOK,
        url: generateDeepLink(),
        title: "GfG",
      }}
      onClick={() => {}}
    >
      <button
        className="flex flex-row items-center justify-center w-1/3 space-x-3"
        onClick={() => generateDeepLink()}
      >
        <IoMdShareAlt color="white" size="25" />
        <p className="text-sm text-white">{SHARE}</p>
      </button>
    </RWebShare>
  );

  return (
    <div className="relative bottom-0 flex flex-row justify-between md:mx-10 item-center">
      {ViewAndRating}
      {FilterForm}
      {ShareButton}
    </div>
  );
};

export default ReadingSectionBodyBottom;
