// External imports
import { combineReducers } from "redux";

// Component imports
import AuthReducer from "./AuthReducer";
import AppReducer from "./AppReducer";
import SupplementReducer from "./SupplementReducer";
import ComicReducer from "./ComicReducer";
import AdvertReducer from "./AdvertReducer";
import UtilityReducer from "./UtilityReducer";

export default combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  supplements: SupplementReducer,
  comics: ComicReducer,
  advert: AdvertReducer,
  utility: UtilityReducer,
});
