// External imports
import { toast } from "react-toastify";

// Component imports
import {
  OPERATION_SUCCESS,
  OPERATION_FAILED,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAILED,
  EDIT_SERIES_SUCCESS,
  EDIT_SERIES_FAILED,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_FAILED,
  ADD_COMIC_BOOK_SUCCESS,
  ADD_COMIC_BOOK_FAILED,
  EDIT_COMIC_BOOK_SUCCESS,
  EDIT_COMIC_BOOK_FAILED,
  DELETE_COMIC_BOOK_SUCCESS,
  DELETE_COMIC_BOOK_FAILED,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_FAILED,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_FAILED,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_FAILED,
  ADD_CHAPTER_SUCCESS,
  ADD_CHAPTER_FAILED,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAILED,
} from "../actions/Type";
import {
  CHAPTER_ADDED_FAILED,
  CHAPTER_ADDED_SUCCESSFULLY,
  CHAPTER_DELETED_FAILED,
  CHAPTER_DELETED_SUCCESSFULLY,
  COMIC_BOOK_ADDED_FAILED,
  COMIC_BOOK_ADDED_SUCCESSFULLY,
  COMIC_BOOK_DELETED_FAILED,
  COMIC_BOOK_DELETED_SUCCESSFULLY,
  COMIC_BOOK_UPDATED_FAILED,
  COMIC_BOOK_UPDATED_SUCCESSFULLY,
  PAGE_ADDED_FAILED,
  PAGE_ADDED_SUCCESSFULLY,
  PAGE_DELETED_FAILED,
  PAGE_DELETED_SUCCESSFULLY,
  PAGE_UPDATED_FAILED,
  PAGE_UPDATED_SUCCESSFULLY,
  SERIES_ADDED_FAILED,
  SERIES_ADDED_SUCCESSFULLY,
  SERIES_DELETED_FAILED,
  SERIES_DELETED_SUCCESSFULLY,
  SERIES_UPDATED_FAILED,
  SERIES_UPDATED_SUCCESSFULLY,
} from "../config/app_strings";

const initialState = {
  isLoggedIn: false,
  isSeriesSubmissionSuccessful: null,
  isComicBookSubmissionSuccessful: null,
};

const ComicReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPERATION_SUCCESS:
      toast.success("Operation successful");
      return { ...state };
    case OPERATION_FAILED:
      toast.error("Failed ! " + action.payload);
      return { ...state };

    // Series
    case ADD_SERIES_SUCCESS:
      toast.success(SERIES_ADDED_SUCCESSFULLY);
      return { ...state, isSeriesSubmissionSuccessful: true };
    case ADD_SERIES_FAILED:
      toast.error(SERIES_ADDED_FAILED + action.payload);
      return { ...state, isSeriesSubmissionSuccessful: false };
    case EDIT_SERIES_SUCCESS:
      toast.success(SERIES_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_SERIES_FAILED:
      toast.error(SERIES_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_SERIES_SUCCESS:
      toast.success(SERIES_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_SERIES_FAILED:
      toast.error(SERIES_DELETED_FAILED + action.payload);
      return { ...state };

    // Comic book
    case ADD_COMIC_BOOK_SUCCESS:
      toast.success(COMIC_BOOK_ADDED_SUCCESSFULLY);
      return { ...state, isComicBookSubmissionSuccessful: true };
    case ADD_COMIC_BOOK_FAILED:
      toast.error(COMIC_BOOK_ADDED_FAILED + action.payload);
      return { ...state, isComicBookSubmissionSuccessful: false };
    case EDIT_COMIC_BOOK_SUCCESS:
      toast.success(COMIC_BOOK_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_COMIC_BOOK_FAILED:
      toast.error(COMIC_BOOK_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_COMIC_BOOK_SUCCESS:
      toast.success(COMIC_BOOK_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_COMIC_BOOK_FAILED:
      toast.error(COMIC_BOOK_DELETED_FAILED + action.payload);
      return { ...state };

    // Page
    case ADD_PAGE_SUCCESS:
      toast.success(PAGE_ADDED_SUCCESSFULLY);
      return { ...state };
    case ADD_PAGE_FAILED:
      toast.error(PAGE_ADDED_FAILED + action.payload);
      return { ...state };
    case EDIT_PAGE_SUCCESS:
      toast.success(PAGE_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_PAGE_FAILED:
      toast.error(PAGE_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_PAGE_SUCCESS:
      toast.success(PAGE_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_PAGE_FAILED:
      toast.error(PAGE_DELETED_FAILED + action.payload);
      return { ...state };

    // Chapter
    case ADD_CHAPTER_SUCCESS:
      toast.success(CHAPTER_ADDED_SUCCESSFULLY);
      return { ...state };
    case ADD_CHAPTER_FAILED:
      toast.error(CHAPTER_ADDED_FAILED + action.payload);
      return { ...state };

    case DELETE_CHAPTER_SUCCESS:
      toast.success(CHAPTER_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_CHAPTER_FAILED:
      toast.error(CHAPTER_DELETED_FAILED + action.payload);
      return { ...state };

    default:
      return state;
  }
};

export default ComicReducer;
