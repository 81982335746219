import React from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

// Component import
import Body from "./components/Body";

// Image import
import HeroBackground from "../../../../assets/images/HeroImage.jpg";
import { CONTRACTED_COMPANY, POWERED_BY } from "../../../../config/app_strings";

export default function Login() {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{ backgroundImage: `url(${HeroBackground})` }}
    >
      <Link
        to={"/"}
        className="absolute p-4 bg-white bg-opacity-25 rounded-full top-5 left-5 md:top-10 md:left-10"
      >
        <BiArrowBack size="26" color="#fff" />
      </Link>

      <Body />

      <p className="absolute bottom-0 mb-10 text-bottom text-lightbg">
        {POWERED_BY}
        <span className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </span>
      </p>
    </div>
  );
}
