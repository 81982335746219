import { useNavigate } from "react-router-dom";

import NoDataGIF from '../../../assets/images/no-data-white.gif';
import { GO_TO_HOME, NO_DATA } from "../../../config/app_strings";

export default function NoData() {
  let history = useNavigate();

  const onBackButtonPressed = () => {
    history("/");
  };

  return (
    <div className="h-screen w-full m-auto text-center flex justify-center align-middle flex-col">
        <img src={NoDataGIF} alt={''} className='w-1/3 m-auto' />
      <p className="h1 text-4xl text-white">{NO_DATA}</p>
      <button
        className="py-2 px-4 bg-white border rounded-lg hover:bg-default hover:text-white m-auto"
        type="button"
        onClick={() => onBackButtonPressed()}
      >
        {GO_TO_HOME}
      </button>
    </div>
  );
}
