import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function ShimmerIndicator({count, width, height}) {
  return (
    <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
      {Array.apply(0, Array(count)).map((item) => (
        <ShimmerThumbnail
          width={width}
          height={height}
          className="bg-default"
          key={Math.random()}
        />
      ))}
    </div>
  );
};
