import { SET_NAV_BAR_VISIBILITY } from "./Type";

/**
 * This function sets the visibility of a navigation bar.
 * @param isVisible - a boolean value indicating whether the navigation bar should be visible or not.
 * @returns A function is being returned that takes a `dispatch` argument and dispatches an action with
 * the `SET_NAV_BAR_VISIBILITY` type and the `isVisible` payload.
 */
export const setNavBarVisibility = (isVisible) => {
    return (dispatch) => {
        dispatch({type: SET_NAV_BAR_VISIBILITY, payload: isVisible});
    }
}