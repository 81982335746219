import React, { useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import {
  registerComic,
  registerPages,
  registerChapter,
} from "../../../actions/ComicAction";
import ManipulateComicForm from "../../../components/Form/ManipulateComicForm";
import ManipulatePage from "../../../components/Form/ManipulatePage";

// Image
import SelectOption from "../../../assets/images/Select Option.gif";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { ComicDisplayOption, ComicOperation } from "../../../enums/comic";
import {
  ADD_COMIC,
  ADD_NEW_COMIC_DESCRIPTION,
  CHOOSE_OPTION_TO_DISPLAY_SECTION,
  EDIT_COMIC,
  EDIT_COMIC_DESCRIPTION,
  REGISTER_CHAPTER_IN_COMIC,
  REGISTER_COMIC,
  REGISTER_PAGE_IN_CHAPTER,
} from "../../../config/app_strings";
import ManipulateChapter from "../../../components/Form/ManipulateChapter";

function ManageComic(props) {
  // state
  const [option, setOption] = useState("");

  let location = useLocation();

  // Getting selected service
  const selectedService = location.state.selectedService;
  const selectedComic = location.state.selectedComic;

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center justify-between space-x-5">
          <div className="flex flex-row items-center space-x-5">
            <Link to={"/comics"}>
              <FaLongArrowAltLeft className="text-default " size="30" />
            </Link>
            {/* Comic Header */}
            <div className="flex flex-col py-10">
              <p className="text-4xl font-bold text-textColor">
                {selectedService === ComicOperation.ADD_COMIC &&
                typeof selectedService !== "undefined"
                  ? ADD_COMIC
                  : selectedService === ComicOperation.EDIT_COMIC &&
                    typeof selectedService !== "undefined" &&
                    typeof selectedComic !== "undefined"
                  ? EDIT_COMIC
                  : ""}
              </p>
              <p className="text-sm font-thin">
                {selectedService === ComicOperation.ADD_COMIC &&
                typeof selectedService !== "undefined"
                  ? ADD_NEW_COMIC_DESCRIPTION
                  : selectedService === ComicOperation.EDIT_COMIC &&
                    typeof selectedService !== "undefined" &&
                    typeof selectedComic !== "undefined"
                  ? EDIT_COMIC_DESCRIPTION
                  : ""}
              </p>
            </div>
          </div>

          {/* Option */}
          <select
            type="text"
            name="option"
            id="option"
            value={option}
            onChange={(e) => setOption(e.target.value)}
            className="flex w-1/3 px-3 py-3 m-auto placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
          >
            <option value="">Choose option</option>
            <option value={ComicDisplayOption.COMIC}>{REGISTER_COMIC}</option>
            <option value={ComicDisplayOption.PAGE}>
              {REGISTER_PAGE_IN_CHAPTER}
            </option>
            <option value={ComicDisplayOption.CHAPTER}>
              {REGISTER_CHAPTER_IN_COMIC}
            </option>
          </select>
        </div>

        {/* Comic form */}

        {option === ComicDisplayOption.COMIC ? (
          <ManipulateComicForm
            selectedService={selectedService}
            selectedComic={selectedComic}
            isLoading={props.isComicBookLoading}
            registerComic={props.registerComic}
            updateSeries={props.updateSeries}
            comicBookSubmissionStatus={props.isComicBookSubmissionSuccessful}
          />
        ) : option === ComicDisplayOption.PAGE ? (
          <ManipulatePage
            registerPages={props.registerPages}
            isLoading={props.isPageLoading}
          />
        ) : option === ComicDisplayOption.CHAPTER ? (
          <ManipulateChapter
            registerChapter={props.registerChapter}
            isChapterLoading={props.isChapterLoading}
          />
        ) : (
          <div className="flex flex-col items-center justify-center m-auto font-bold text-center text-gray-400">
            <img
              src={SelectOption}
              alt={"Select Option Animation"}
              className="w-1/3"
            />
            {CHOOSE_OPTION_TO_DISPLAY_SECTION}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isComicBookLoading: state.app.isComicBookLoading,
    isPageLoading: state.app.isPageLoading,
    isChapterLoading: state.app.isChapterLoading,
    isComicBookSubmissionSuccessful:
      state.comics.isComicBookSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerComic: (comicContent, chapters) =>
      dispatch(registerComic(comicContent, chapters)),
    registerPages: (chapterId, pages, lastOne) =>
      dispatch(registerPages(chapterId, pages, lastOne)),
    registerChapter: (chapters, comicId, fromComic) =>
      dispatch(registerChapter(chapters, comicId, fromComic)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageComic);
