import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { BsFillFolderFill } from "react-icons/bs";
import { IoDocumentText } from "react-icons/io5";
import Modal from "react-modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { RiRefreshLine } from "react-icons/ri";
import { MdViewCarousel } from "react-icons/md";
import ImageViewer from "react-simple-image-viewer";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

// Component
import { instance } from "../../../config/api";
import EditChapter from "../../../components/Form/EditChapter";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { deleteChapter, deletePage } from "../../../actions/ComicAction";

// Image
import NoData from "../../../assets/images/no-data.gif";
import EditPage from "../../../components/Form/EditPage";
import { ComicOperation } from "../../../enums/comic";
import {
  CANCEL,
  DELETE,
  EDIT,
  GENRE,
  NO_CHAPTER,
  NO_PAGE,
  PAGE,
  SERIES,
  TAG,
} from "../../../config/app_strings";

function SingleComic({
  selectedComic,
  setToggleDetail,
  deletePage,
  deleteChapter,
}) {
  const [fetchedChapters, setFetchedChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();
  const [fetchedPages, setFetchedPages] = useState([]);
  const [fetchedSeries, setFetchedSeries] = useState({});
  const [isProcessing, setProcessing] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [isSeriesLoading, setSeriesLoading] = useState(false);

  useEffect(() => {
    getFetchedChapters(selectedComic.data.comicbook_id);
    getSeriesData(selectedComic.data.meta.series);
  }, [selectedComic]);

  async function getFetchedChapters(comicId) {
    setProcessing(true);
    await instance
      .get("comicBooks/" + comicId + "/chapters", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        setFetchedChapters(response.data.data);
      })
      .catch((error) => {
        setFetchedChapters([]);
      });
    setProcessing(false);
  }

  async function getSeriesData(seriesLink) {
    if (seriesLink === null || seriesLink === "") return;

    setSeriesLoading(true);

    const seriesId = seriesLink.split("/").pop();

    await instance
      .get("series/" + seriesId, {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        setFetchedSeries(response.data.data);
      })
      .catch((error) => {});

    setSeriesLoading(false);
  }

  async function getFetchedPagesInAChapter(chapterId) {
    setPageLoading(true);
    await instance
      .get("chapters/" + chapterId + "/pages", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => setFetchedPages(response.data.data))
      .catch((error) => {
        setFetchedPages([]);
      });
    setPageLoading(false);
  }

  // Mapping
  const mappingAuthors = () => {
    return selectedComic &&
      selectedComic.data.meta.authors &&
      selectedComic.data.meta.authors.data.length > 0
      ? selectedComic.data.meta.authors.data.map((SingleAuthor, i) =>
          i + 1 === selectedComic.data.meta.authors.data.length ? (
            <span key={i}>{SingleAuthor.data.attributes.name}</span>
          ) : (
            <span key={i}>{SingleAuthor.data.attributes.name + ", "}</span>
          )
        )
      : "";
  };

  const mappingGenres = () => {
    return selectedComic &&
      selectedComic.data.meta.genres &&
      selectedComic.data.meta.genres.data.length > 0
      ? selectedComic.data.meta.genres.data.map((SingleGenre, i) =>
          i + 1 === selectedComic.data.meta.genres.data.length ? (
            <span key={i}>{SingleGenre.data.attributes.name}</span>
          ) : (
            <span key={i}>{SingleGenre.data.attributes.name + ", "}</span>
          )
        )
      : "";
  };

  const mappingTags = () => {
    return selectedComic &&
      selectedComic.data.meta.tags &&
      selectedComic.data.meta.tags.data.length > 0
      ? selectedComic.data.meta.tags.data.map((SingleTag, i) =>
          i + 1 === selectedComic.data.meta.tags.data.length ? (
            <span key={i}>{SingleTag.data.attributes.name}</span>
          ) : (
            <span key={i}>{SingleTag.data.attributes.name + ", "}</span>
          )
        )
      : "";
  };

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto bg-lightbg">
      
      <SingleComicHeader
        mappingTags={mappingTags}
        mappingGenres={mappingGenres}
        mappingAuthors={mappingAuthors}
        selectedComic={selectedComic}
        setToggleDetail={setToggleDetail}
        isSeriesLoading={isSeriesLoading}
        fetchedSeries={fetchedSeries}
      />
      <hr className="mx-6" />

      <div className="flex flex-row items-center justify-between mx-5 mt-5">
        <p className="text-xl font-bold text-gray-400">Chapters</p>
        <button>
          <RiRefreshLine
            title="Refresh"
            size="25px"
            className="text-default"
            type="button"
            onClick={() => getFetchedChapters(selectedComic.data.comicbook_id)}
          />
        </button>
      </div>
      <SingleComicChapters
        fetchedChapters={fetchedChapters}
        setSelectedChapter={setSelectedChapter}
        getFetchedPagesInAChapter={getFetchedPagesInAChapter}
        isProcessing={isProcessing}
        deleteChapter={deleteChapter}
      />

      <hr className="mx-6" />

      <SingleChapterPages
        fetchedPages={fetchedPages}
        selectedChapter={selectedChapter}
        isPageLoading={isPageLoading}
        getFetchedPagesInAChapter={getFetchedPagesInAChapter}
        deletePage={deletePage}
      />
    </div>
  );
}

const SingleComicHeader = ({
  mappingTags,
  mappingGenres,
  mappingAuthors,
  selectedComic,
  setToggleDetail,
  isSeriesLoading,
  fetchedSeries,
}) => {
  return (
    <>
      {/* Comic Header */}
      <div className="flex flex-row items-start justify-between px-5 py-10 mb-10 bg-white shadow-lg">
        <div className="flex flex-row w-4/5 space-x-5">
          <div className="w-1/3 bg-transparent h-1/3 bg-blend-lighten">
            <img
              src={selectedComic.links.image}
              alt={selectedComic.data.attributes.title}
              className="object-cover object-top w-full h-full bg-lightbg"
            />
          </div>
          <div className="flex flex-col space-y-3 w-2/3">
            <p className="text-4xl font-bold text-textColor">
              {selectedComic.data.attributes.title +
                ", " +
                selectedComic.data.attributes.publication_year}
            </p>
            <p className="text-sm font-bold text-gray-400">
              Written by: {mappingAuthors()}
            </p>
            <p className="text-sm font-bold text-gray-400">
              {GENRE}: {mappingGenres()}
            </p>
            <p className="text-sm font-bold text-gray-400">
              {TAG}: {mappingTags()}
            </p>
            <p className="text-sm font-bold text-gray-400">
              {SERIES}:{" "}
              {isSeriesLoading
                ? "Loading . . . "
                : typeof fetchedSeries.attributes === "undefined"
                ? ""
                : fetchedSeries.attributes.title}
            </p>
            <p className="text-sm text-textColor">
              {selectedComic.data.attributes.description}
            </p>
          </div>
        </div>
        <button
          id="toggleSelection"
          name="toggleSelection"
          className="px-3 py-3 text-white border rounded-lg bg-passOverColor hover:bg-transparent hover:text-passOverColor"
          onClick={() => setToggleDetail(false)}
        >
          <MdClose />
        </button>
      </div>
    </>
  );
};

const SingleComicChapters = ({
  deleteChapter,
  fetchedChapters,
  isProcessing,
  setSelectedChapter,
  getFetchedPagesInAChapter,
}) => {
  // state
  const [isModelOpen, setModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDeletableFieldName, setSelectedDeletableFieldName] =
    useState("");
  const [selectedFIeld, setSelectedDeletableField] = useState("");
  const [toggleOperation, setToggleOperation] = useState("");
  const [editableChapter, setEditableChapter] = useState("");

  // opening modal
  const openingModalElement = (
    selectedField,
    selectedFieldName,
    editableChapter
  ) => {
    setModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDeletableFieldName(selectedFieldName);
    setEditableChapter(editableChapter);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
    //setSelectedDeletableField(null);
    setSelectedDeletableFieldName("");
    setEditableChapter("");
  };
  // Mapping
  const mappingChapters =
    fetchedChapters && fetchedChapters.length > 0 ? (
      fetchedChapters.map((data) => (
        <div
          className="flex flex-row w-1/3 px-4 py-3 mb-4 space-x-6 space-y-2 rounded-lg shadow-lg bg-lightbg"
          key={data.data.chapter_id}
        >
          <div className="flex flex-col items-center justify-center w-1/3 p-3 bg-passOverColor rounded-xl">
            <BsFillFolderFill size={40} className="text-white" />
          </div>
          <div className="flex flex-col flex-wrap justify-between w-3/4 text-textColor">
            <p className="w-5/6 text-lg font-bold truncate">
              {data.data.attributes.title}
            </p>
            <div className="flex flex-row space-x-3">
              <button
                onClick={() => {
                  setSelectedChapter(data.data.chapter_id);
                  getFetchedPagesInAChapter(data.data.chapter_id);
                }}
                className="text-sm text-gray-300 hover:text-lightDefault"
              >
                View pages
              </button>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  setToggleOperation(ComicOperation.EDIT_CHAPTER);
                  openingModalElement(
                    data.data.chapter_id,
                    data.data.attributes.title,
                    data.data
                  );
                }}
              >
                {EDIT}
              </button>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  openingModalElement(
                    data.data.chapter_id,
                    data.data.attributes.title,
                    ""
                  );
                }}
              >
                Delete😌
              </button>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="flex flex-col m-auto mt-10 text-center text-muted">
        <img
          src={NoData}
          alt="Pharania - No data animation"
          className="w-1/3 m-auto"
        />
        <p>{NO_CHAPTER}</p>
      </p>
    );
  return (
    <div className="flex flex-row flex-wrap w-full px-5 my-10 text-black placeholder-black bg-transparent">
      {mappingChapters}
      <Modal
        className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        ariaLabelledBy="modal-headline"
        ariaHideApp={false}
        isOpen={isModelOpen}
        onRequestClose={() => closingModalElement()}
        contentLabel="Delete Modal"
        style={{
          content: {
            display: "inline-block",
            alignItems: "bottom",
            background: "white",
            borderRadius: "20px",
            textAlign: "left",
            overflow: "hidden",
            position: "fixed",
            left: "30%",
            right: "auto",
            bottom: "auto",
            top: "30vh",
          },
        }}
      >
        {toggleOperation === ComicOperation.EDIT_CHAPTER ? (
          <EditChapter
            selectedChapter={editableChapter}
            closingModalElement={closingModalElement}
          />
        ) : (
          <>
            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="w-6 h-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-headline"
                  >
                    Deleting{" "}
                    <span className="font-bold">
                      {selectedDeletableFieldName}
                    </span>{" "}
                    from Chapters
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete{" "}
                      <span className="font-bold">
                        {selectedDeletableFieldName}
                      </span>{" "}
                      from chapters? All page data with this specific chapter
                      will be permanently removed. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
              {isProcessingDelete ? (
                <ScaleLoader color="#037d50" />
              ) : (
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={async () => {
                     setIsProcessingDelete(true);
                      await deleteChapter(selectedFIeld);
                      setIsProcessingDelete(false);
                      closingModalElement();
                  }}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => closingModalElement()}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

const SingleChapterPages = ({
  fetchedPages,
  selectedChapter,
  isPageLoading,
  getFetchedPagesInAChapter,
  deletePage,
}) => {
  // state
  const [isModelOpen, setModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDeletableFieldName, setSelectedDeletableFieldName] =
    useState("");
  const [selectedDeletableField, setSelectedDeletableField] = useState("");
  const [toggleOperation, setToggleOperation] = useState("");
  const [editablePage, setEditablePage] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const [pageImages, setPageImages] = useState([]);
  let images = [];

  // opening modal
  const openingModalElement = (
    selectedField,
    selectedFieldName,
    editablePage
  ) => {
    setModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDeletableFieldName(selectedFieldName);
    setEditablePage(editablePage);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDeletableFieldName("");
    setEditablePage("");
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  // Mapping
  const mappingPages =
    fetchedPages && fetchedPages.length > 0 ? (
      fetchedPages.map((data) => {
        images.push(data.links.image);
        return (
          <div
            className="flex flex-row w-1/3 px-4 py-3 mb-4 space-x-6 space-y-2 rounded-lg shadow-lg bg-lightbg"
            key={data.data.page_id}
          >
            <div className="flex flex-col items-center justify-center w-1/3 p-3 bg-passOverColor rounded-xl">
              <IoDocumentText size={40} className="text-white" />
            </div>
            <div className="flex flex-col flex-wrap justify-between w-3/4 text-textColor">
              <p className="w-5/6 text-lg font-bold truncate">
                {PAGE}
                {`${data.data.attributes.page_no}`}
              </p>
              <div className="flex flex-row space-x-3">
                <button
                  onClick={() => {
                    setToggleOperation(ComicOperation.EDIT_PAGE);
                    openingModalElement(
                      data.data.page_id,
                      data.data.attributes.page_no,
                      data.data
                    );
                  }}
                  className="text-sm text-gray-300 hover:text-lightDefault"
                >
                  {EDIT}
                </button>
                <button
                  className="text-sm text-gray-300 hover:text-lightDefault"
                  onClick={() => {
                    openingModalElement(
                      data.data.page_id,
                      data.data.attributes.page_no
                    );
                  }}
                >
                  {DELETE}
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <p className="flex flex-col m-auto mt-10 text-center text-muted">
        <img
          src={NoData}
          alt="Pharania - No data animation"
          className="w-1/3 m-auto"
        />
        <p>{NO_PAGE}</p>
      </p>
    );

  return (
    <>
      <div className="flex flex-row items-center justify-between mx-5 mt-5">
        <p className="text-xl font-bold text-gray-400">Pages</p>
        <div className="flex flex-row space-x-5">
          <button>
            <MdViewCarousel
              title="View all pages"
              size="25px"
              className="text-default"
              type="button"
              onClick={() => openImageViewer()}
            />
          </button>
          <button>
            <RiRefreshLine
              title="Refresh"
              size="25px"
              className="text-default"
              type="button"
              onClick={() => getFetchedPagesInAChapter(selectedChapter)}
            />
          </button>
        </div>
      </div>
      <div className="flex flex-row flex-wrap w-full px-5 my-10 text-black placeholder-black bg-transparent">
        {mappingPages}
        {isViewerOpen && (
          <ImageViewer
            src={images.reverse()}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            className="w-2/3 border border-white"
          />
        )}
        <Modal
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          ariaLabelledBy="modal-headline"
          ariaHideApp={false}
          isOpen={isModelOpen}
          onRequestClose={() => closingModalElement()}
          contentLabel="Delete Modal"
          style={{
            content: {
              display: "inline-block",
              alignItems: "bottom",
              background: "white",
              borderRadius: "20px",
              textAlign: "left",
              overflow: "hidden",
              position: "fixed",
              left: "30%",
              right: "auto",
              bottom: "auto",
              top: "30vh",
            },
          }}
        >
          {toggleOperation === ComicOperation.EDIT_PAGE ? (
            <EditPage
              selectedPage={editablePage}
              closingModalElement={closingModalElement}
            />
          ) : (
            <>
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="w-6 h-6 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-headline"
                    >
                      Deleting{" "}
                      <span className="font-bold">
                        Page {selectedDeletableFieldName}
                      </span>{" "}
                      from Pages
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete page{" "}
                        <span className="font-bold">
                          {selectedDeletableFieldName}
                        </span>{" "}
                        from pages? All page data with this specific data will
                        be permanently removed. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                {isProcessingDelete ? (
                  <ScaleLoader color="#037d50" />
                ) : (
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      setIsProcessingDelete(true);
                      await deletePage(selectedDeletableField);
                      setIsProcessingDelete(false);
                      closingModalElement();
                    }}
                  >
                    {DELETE}
                  </button>
                )}
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => closingModalElement()}
                >
                  {CANCEL}
                </button>
              </div>
            </>
          )}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteChapter: (chapterId) => dispatch(deleteChapter(chapterId)),
    deletePage: (pageId) => dispatch(deletePage(pageId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleComic);
