import "./App.css";

// External imports
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Modal from "react-modal";

import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";

// Component imports
import Landing from "./layouts/client/landing/Root";
import Search from "./layouts/client/search/Root";
import Comic from "./layouts/client/comic/Root";
import AdminLogin from "./layouts/admin/auth/Login/Login";
import Dashboard from "./layouts/admin/dashboard/Dashboard";
import EditProfile from "./layouts/admin/profile/EditProfile";
import Advert from "./layouts/admin/advert/Advert";
import ManageAdvert from "./layouts/admin/advert/ManageAdvert";
import Supplement from "./layouts/admin/supplement/Root";
import ManageSupplement from "./layouts/admin/supplement/ManageSupplement";
import Author from "./layouts/admin/author/Root";
import ManageAuthor from "./layouts/admin/author/ManageAuthor";
import Comics from "./layouts/admin/comic/Root";
import ManageSeries from "./layouts/admin/comic/ManageSeries";
import ManageComic from "./layouts/admin/comic/ManageComic";
import User from "./layouts/admin/users/Root";
import ManageAdminUser from "./layouts/admin/users/ManageAdminUser";
import NoData from "./layouts/client/utility/NoDate";
import Genre from "./layouts/client/genre/Root";
import AuthorSpecificComics from "./layouts/client/author/Root";
import AuthorRanking from "./layouts/admin/authorRanking/Root";
import ForgotPassword from "./layouts/client/auth/ForgotPassword";
import ResetPassword from "./layouts/client/auth/ResetPassword";


// State management
import Store from "./store/Store";
import PrivacyPolicy from "./layouts/client/privacy_policy/Root";
import CreateAccount from "./layouts/client/auth/CreateAccount";
import SignIn from "./layouts/client/auth/SignIn";

Modal.setAppElement("#root");

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <div className="App">
          <Routes>
            {/* Admin Routes */}
            <Route path="/admin_root_login" exact element={<AdminLogin />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/edit" exact element={<EditProfile />} />
            <Route path="/advert" exact element={<Advert />} />
            <Route path="/users" exact element={<User />} />
            <Route path="/supplements" exact element={<Supplement />} />
            <Route path="/author" exact element={<Author />} />
            <Route path="/comics" exact element={<Comics />} />
            <Route path="/author-ranking" exact element={<AuthorRanking />} />
           

            {/* Admin Sub Routes */}
            <Route path="/advert/create" exact element={<ManageAdvert />} />
            <Route
              path="/supplement/create"
              exact
              element={<ManageSupplement />}
            />
            <Route path="/author/create" exact element={<ManageAuthor />} />
            <Route path="/series/create" exact element={<ManageSeries />} />
            <Route path="/comic/create" exact element={<ManageComic />} />
            <Route path="/users/create" exact element={<ManageAdminUser />} />

            {/* Guest Routes */}
            <Route path="/" exact element={<Landing />} />
            <Route path="/comic/:id" exact element={<Landing />} />
            <Route path="/search" exact element={<Search />} />
            <Route path="/genre" exact element={<Genre />} />
            <Route
              path="/author/:name"
              exact
              element={<AuthorSpecificComics />}
            />
            <Route path="/comic" exact element={<Comic />} />
            <Route path="/404" exact element={<NoData />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

            {/* Guest auth routes */}
            <Route path="/auth/create" exact element={<CreateAccount />} />
            <Route path="/auth/sign-in" exact element={<SignIn />} />
            <Route path="/forgot-password" exact element={< ForgotPassword/>} />
            <Route path="/reset-password" element={<ResetPassword />} />



            {/* Catch-all route for 404 */}
            <Route path="*" element={<NoData />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
