/**
 * Supported advert operations.
 * @enum {string}
 */
export const AdvertOperation = Object.freeze({
    ADD_ADVERT: 'add_advert',
    EDIT_ADVERT: 'edit_advert',
});

/**
 * Supported advert display option.
 * @enum {string}
 */
export const AdvertDisplayOption = Object.freeze({
    ADVERT: 'advert',
    ADVERT_TYPE: 'advert_type',
});

/**
 * Supported advert type operation.
 * @enum {string}
 */
export const AdvertTypeOperation = Object.freeze({
    ADD_ADVERT_TYPE: 'add_advert_type',
    EDIT_ADVERT_TYPE: 'edit_advert_type',
});

/**
 * Supported advert types.
 * @enum {string}
 */
export const AdvertType = Object.freeze({
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait',
    SQUARE: 'square',
});