import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import Select from "react-select";

//
import { validateEmptyString } from "../Utility/Validation";
import { instance } from "../../config/api";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import { ComicOperation } from "../../enums/comic";
import {
  AUTHOR_NAME,
  GENRE,
  IMAGE,
  MISSING_FIELD,
  SUBMIT,
  TAG,
  TITLE,
} from "../../config/app_strings";

export default class ManipulateSeriesForm extends Component {
  state = {
    seriesName:
      this.props.selectedSeries !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_SERIES
        ? this.props.selectedSeries.attributes.title
        : "",
    authorName:
      this.props.selectedSeries !== undefined &&
      this.props.selectedSeries === ComicOperation.EDIT_SERIES
        ? this.props.selectedSeries.attributes.author
        : [],
    genreId:
      this.props.selectedSeries !== undefined &&
      this.props.selectedSeries === ComicOperation.EDIT_SERIES
        ? this.props.selectedSeries.attributes.genre
        : [],
    tagId:
      this.props.selectedSeries !== undefined &&
      this.props.selectedSeries === ComicOperation.EDIT_SERIES
        ? this.props.selectedSeries.attributes.tag
        : [],
    profileImage: "",

    isFieldLoading: false,
    getAuthors: [],
    getGenre: [],
    getTags: [],
  };

  async componentDidMount() {
    this.setState({ isFieldLoading: true });
    await instance
      .get("authors", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        var fetchedAuthor = [];

        response.data.data && response.data.data.length > 0
          ? response.data.data.map((SingleAuthor) =>
              fetchedAuthor.push({
                value: SingleAuthor.data.author_id,
                label: SingleAuthor.data.attributes.name,
              })
            )
          : (fetchedAuthor = []);
        this.setState({ getAuthors: fetchedAuthor });
      })
      .catch((error) => {});

    await instance
      .get("genres", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        var fetchedGenre = [];

        response.data.data && response.data.data.length > 0
          ? response.data.data.map((SingleGenre) =>
              fetchedGenre.push({
                value: SingleGenre.data.genre_id,
                label: SingleGenre.data.attributes.name,
              })
            )
          : (fetchedGenre = []);
        this.setState({ getGenre: fetchedGenre });
      })
      .catch((error) => {});

    await instance
      .get("tags", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        var fetchedTags = [];

        response.data.data && response.data.data.length > 0
          ? response.data.data.map((SingleAuthor) =>
              fetchedTags.push({
                value: SingleAuthor.data.tag_id,
                label: SingleAuthor.data.attributes.name,
              })
            )
          : (fetchedTags = []);
        this.setState({ getTags: fetchedTags });
      })
      .catch((error) => {});
    this.setState({ isFieldLoading: false });
  }

  render() {
    // Get the data
    let {
      seriesName,
      authorName,
      genreId,
      getAuthors,
      getGenre,
      isFieldLoading,
      profileImage,
      getTags,
      tagId,
    } = this.state;
    const {
      registerSeries,
      updateSeries,
      selectedService,
      selectedSeries,
      seriesSubmissionStatus,
      isLoading,
    } = this.props;
    const resetForm = () => {
      this.state.seriesName = "";
      this.state.profileImage = "";
    };
    {
    }
    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      //   Get the data
      let formDataContent = new FormData();

      // Validation
      if (!validateEmptyString(seriesName)) {
        return toast.warning(MISSING_FIELD);
      }

      // Appending content to form data.
      formDataContent.append("data[attributes][title]", seriesName);

      if (selectedService === ComicOperation.EDIT_SERIES) {
        formDataContent.append("_method", "PATCH");
        updateSeries(formDataContent, selectedSeries.series_id);
      } else {
        const image = document.querySelector("#profileImage");

        profileImage = image.files[0] !== undefined ? image.files[0] : null;
        if (profileImage !== null) {
          formDataContent.append("data[attributes][image]", profileImage);
        }
        if (profileImage == null) {
          {
            return toast.warning(MISSING_FIELD);
          }
        }

        if (
          !validateEmptyString(seriesName) ||
          !validateEmptyString(profileImage) ||
          !validateEmptyString(authorName) ||
          !validateEmptyString(genreId) ||
          !validateEmptyString(tagId)
        ) {
          return toast.warning(MISSING_FIELD);
        }

        for (var i = 0; i < genreId[0].length; i++)
          formDataContent.append("data[attributes][genres][]", genreId[0][i]);
        for (var j = 0; j < authorName[0].length; j++)
          formDataContent.append(
            "data[attributes][authors][]",
            authorName[0][j]
          );
        for (var k = 0; k < tagId[0].length; k++)
          formDataContent.append("data[attributes][tags][]", tagId[0][k]);

        registerSeries(formDataContent);
        resetForm();
      }
    };

    // Methods
    const onHandleMultipleSelectChanges = (value, stateVariable) => {
      var selected = [];
      for (var option of value) {
        selected.push(option.value);
      }
      this.setState({ [stateVariable]: [selected] });
    };

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {TITLE}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="seriesName"
              id="seriesName"
              placeholder={TITLE}
              value={seriesName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          {selectedService === ComicOperation.ADD_SERIES ? (
            <>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {AUTHOR_NAME}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="authorName"
                  id="authorName"
                  placeholder={AUTHOR_NAME}
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getAuthors}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "authorName")
                  }
                  classNamePrefix="select py-3"
                  className="placeholder-gray-300 rounded-lg basic-multi-select text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {GENRE}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="genreId"
                  id="genreId"
                  placeholder={GENRE}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "genreId")
                  }
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getGenre}
                  classNamePrefix="select py-3"
                  className="placeholder-gray-300 rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {TAG}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="tagId"
                  id="tagId"
                  placeholder={TAG}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "tagId")
                  }
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getTags}
                  classNamePrefix="select py-3"
                  className="placeholder-gray-300 rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {IMAGE}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="profileImage"
                  id="profileImage"
                  placeholder={IMAGE}
                  value={profileImage}
                  onChange={onHandleChangeInField}
                  className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
            </>
          ) : (
            ""
          )}

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}
