import React from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import { registerSeries, updateSeries } from "../../../actions/ComicAction";
import ManipulateSeriesForm from "../../../components/Form/ManipulateSeriesForm";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { ComicOperation } from "../../../enums/comic";
import { ADD_NEW_SERIES_DESCRIPTION, ADD_SERIES, EDIT_SERIES, EDIT_SERIES_DESCRIPTION } from "../../../config/app_strings";

function ManageSeries(props) {

  let location = useLocation();

  // Getting selected service
  const selectedService = location.state.selectedService;
  const selectedSeries = location.state.selectedSeries;

  if(!isUserStillLoggedIn()) return <Navigate to={'/admin_root_login'} />

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center space-x-5">
          <Link to={"/comics"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Series Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-textColor">
              {selectedService === ComicOperation.ADD_SERIES &&
              typeof selectedService !== "undefined"
                ? ADD_SERIES
                : selectedService === ComicOperation.EDIT_SERIES &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedSeries !== "undefined"
                ? EDIT_SERIES
                : ""}
            </p>
            <p className="text-sm font-thin">
              {selectedService === ComicOperation.ADD_SERIES &&
              typeof selectedService !== "undefined"
                ? ADD_NEW_SERIES_DESCRIPTION
                : selectedService === ComicOperation.EDIT_SERIES &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedSeries !== "undefined"
                ? EDIT_SERIES_DESCRIPTION
                : ""}
            </p>
          </div>
        </div>

        {/* Supplement form */}

        {selectedService === ComicOperation.ADD_SERIES ||
        selectedService === ComicOperation.EDIT_SERIES ? (
          <ManipulateSeriesForm
            selectedService={selectedService}
            selectedSeries={selectedSeries}
            isLoading={props.isLoading}
            registerSeries={props.registerSeries}
            updateSeries={props.updateSeries}
            seriesSubmissionStatus={props.isSeriesSubmissionSuccessful}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isSeriesLoading,
    isSeriesSubmissionSuccessful: state.comics.isSeriesSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerSeries: (seriesContent) => dispatch(registerSeries(seriesContent)),
    updateSeries: (seriesContent, series_id) =>
      dispatch(updateSeries(seriesContent, series_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSeries);
