import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

export default function LeftAd({ adverts }) {
  if (adverts.length === 0)
    return (
      <div
        className=" bg-default bg-opacity-70 hidden lg:flex rounded-md"
        style={{ width: "250px"}}
      >
        <p className="m-auto text-center text-2xl font-bold text-white">
          Déposez votre <br /> annonce ici
        </p>
      </div>
    );
  return (
    <div className="hidden lg:block lg:w-1/5 h-screen">
      <SimpleImageSlider
        width={250}
        height={"100%"}
        images={adverts}
        showBullets={false}
        showNavs={false}
        autoPlay={true}
        autoPlayDelay={2}
        duration={0.5}
        loop={true}
      />
    </div>
  );
}
