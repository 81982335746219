import React, { Component } from "react";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";

// Component
import { updatePages } from "../../actions/ComicAction";
import { CANCEL, EDIT, EDIT_PAGE, MISSING_FIELD, PAGE_TITLE } from "../../config/app_strings";

class EditPage extends Component {
  state = {
    page_image: "",
  };
  render() {
    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onFormSubmit = (e) => {
      e.preventDefault();

      let formDataContent = new FormData();
      let image = document.querySelector("#page_image");

      this.state.page_image =
        image.files[0] !== undefined ? image.files[0] : null;

      // Validation
      if (this.state.page_image === null) {
        return toast.warning(MISSING_FIELD);
      }

      formDataContent.append("data[attributes][image]", this.state.page_image);
      formDataContent.append("_method", "PATCH");

      this.props.updatePages(formDataContent, this.props.selectedPage.page_id);
    };

    return (
      <div className="pt-5">
        <div className="flex flex-row items-center justify-between px-10 mb-2">
          <p className="text-xl font-bold text-gray-400">{EDIT_PAGE}</p>
          <button
            id="toggleSelection"
            name="toggleSelection"
            className="px-3 py-3 rounded-lg text-passOverColor "
            onClick={() => this.props.closingModalElement()}
          >
            <MdClose />
          </button>
        </div>
        <hr className="px-10" />
        <form onSubmit={onFormSubmit} className="mt-5">
          <div className="flex flex-col px-10 mb-5 space-y-3">
            <label className="font-light text-textColor">
              {PAGE_TITLE}<span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="file"
              name="page_image"
              id="page_image"
              placeholder={PAGE_TITLE}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-10 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-passOverColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {EDIT}
            </button>

            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => this.props.closingModalElement()}
            >
              {CANCEL}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePages: (content, pageId) => dispatch(updatePages(content, pageId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
