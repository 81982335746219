import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";

// Components
import { instance } from "../../config/api";
import { validateEmptyString } from "../Utility/Validation";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import { ADD_PAGE_NOTICE, CHAPTERS, CHOOSE_OPTION, COMIC_BOOK, IMAGE, MISSING_FIELD, NOTE, NO_CHAPTER, NO_COMIC, SUBMIT } from "../../config/app_strings";

class ManipulatePage extends Component {
  //   state
  state = {
    comicBook: "",
    comicChapter: "",
    chapterPages: [],

    getComicBook: [],
    getChapter: [],
    isFieldLoading: false,
    isFetchingChapters: false,
  };

  //
  async componentDidMount() {
    this.setState({ isFieldLoading: true });
    await instance
      .get("comicBooks", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        this.setState({ getComicBook: response.data.data });
      })
      .catch((error) => {});
    this.setState({ isFieldLoading: false });
  }

  render() {
    let { comicBook, comicChapter, getComicBook, getChapter, chapterPages, isFieldLoading, isFetchingChapters } =
      this.state;

    const { registerPages, isLoading } = this.props;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value }, () => {
        if (e.target.name === "comicBook" && e.target.value !== "")
          fetchComicBookChapters(this.state.comicBook);
      });
    };

    const onHandleFileInput = (e) => {
      this.setState({ chapterPages: e.target.files });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      // Validation
      if (!validateEmptyString(comicBook) || !validateEmptyString(comicChapter))
        return toast.warning(MISSING_FIELD);

      let formDataContent = new FormData();

      if (chapterPages.length === 0)
        return toast.warning(MISSING_FIELD);

      Array.from(chapterPages).forEach((page) =>
        formDataContent.append("data[attributes][images][]", page)
      );

      registerPages(comicChapter, formDataContent);
    };

    // Mapping
    const fetchComicBookChapters = async (selectedComicBookId) => {
      this.setState({ isFetchingChapters: true });
      await instance
        .get("comicBooks/" + selectedComicBookId + "/chapters", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((response) => {
          this.setState({ getChapter: response.data.data });
        })
        .catch((error) => {
          this.setState({ getChapter: [] });
        });
      this.setState({ isFetchingChapters: false });
    };

    const mappingComics = isFieldLoading ? <option value="">Loading ...</option> :
      getComicBook && getComicBook.length > 0
        ? getComicBook.map((SingleComic) => (
            <option
              value={SingleComic.data.comicbook_id}
              key={SingleComic.data.comicbook_id}
            >
              {SingleComic.data.attributes.title}
            </option>
          ))
        : NO_COMIC;

    const mappingChapters = isFetchingChapters ? <option value="">Loading ...</option> :
      getChapter && getChapter.length > 0
        ? getChapter.map((SingleComic) => (
            <option
              value={SingleComic.data.chapter_id}
              key={SingleComic.data.chapter_id}
            >
              {SingleComic.data.attributes.title}
            </option>
          ))
        : NO_CHAPTER;

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {COMIC_BOOK}<span className="mx-1 text-red-500">*</span>
            </label>
            <select
              name="comicBook"
              id="comicBook"
              placeholder={COMIC_BOOK}
              value={comicBook}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            >
              <option value="">{CHOOSE_OPTION}</option>
              {mappingComics}
            </select>
          </div>
          {comicBook !== "" ? (
            <>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {CHAPTERS}<span className="mx-1 text-red-500">*</span>
                </label>
                <select
                  name="comicChapter"
                  id="comicChapter"
                  placeholder={CHAPTERS}
                  value={comicChapter}
                  onChange={onHandleChangeInField}
                  className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                >
                  <option value="">{CHOOSE_OPTION}</option>
                  {mappingChapters}
                </select>
              </div>
              <hr />
              <p className="text-sm italic text-gray-400">
                <span className="font-extrabold">{NOTE}:</span> {ADD_PAGE_NOTICE}
              </p>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {IMAGE}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="pageImage"
                  id="pageImage"
                  placeholder={IMAGE}
                  onChange={(e) => onHandleFileInput(e)}
                  className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                  required
                  multiple
                />
              </div>
              {isLoading ? (
                <button
                  className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
                  disabled
                >
                  <ScaleLoader className="m-auto text-white" />
                </button>
              ) : (
                <button
                  className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
                  type="submit"
                >
                  {SUBMIT}
                </button>
              )}
            </>
          ) : null}
        </form>
      </div>
    );
  }
}

export default ManipulatePage;
