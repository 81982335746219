import React, { Component } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import { connect } from "react-redux";

// Component
import SideNav from "../../../components/Nav/SideNav";
import {
  registerAuthor,
  updateAuthor,
} from "../../../actions/SupplementsAction";
import { validateEmptyString } from "../../../components/Utility/Validation";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { AuthorOperation } from "../../../enums/author";
import {
  ADD_AUTHOR,
  ADD_NEW_AUTHOR_DESCRIPTION,
  AUTHOR_DESCRIPTION,
  AUTHOR_NAME,
  EDIT_AUTHOR,
  EDIT_AUTHOR_DESCRIPTION,
  MISSING_FIELD,
  PROFILE_IMAGE,
  SUBMIT,
  PASSWORD_DONOT_MATCH,
  EMAIL,
  PASSWORD,
  CONFIRM_PASSWORD,
} from "../../../config/app_strings";

function ManageAuthor(props) {
  let location = useLocation();

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  // Getting selected service
  const selectedService = location.state.selectedService;
  const selectedAuthor = location.state.selectedAuthor;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center space-x-5">
          <Link to={"/author"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Author Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-textColor">
              {selectedService === AuthorOperation.ADD_AUTHOR &&
              typeof selectedService !== "undefined"
                ? ADD_AUTHOR
                : selectedService === AuthorOperation.EDIT_AUTHOR &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedAuthor !== "undefined"
                ? EDIT_AUTHOR
                : ""}
            </p>
            <p className="text-sm font-thin">
              {selectedService === AuthorOperation.ADD_AUTHOR &&
              typeof selectedService !== "undefined"
                ? ADD_NEW_AUTHOR_DESCRIPTION
                : selectedService === AuthorOperation.EDIT_AUTHOR &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedAuthor !== "undefined"
                ? EDIT_AUTHOR_DESCRIPTION
                : ""}
            </p>
          </div>
        </div>

        {/* Author form */}

        {selectedService === AuthorOperation.ADD_AUTHOR ||
        selectedService === AuthorOperation.EDIT_AUTHOR ? (
          <AddAuthorForm
            selectedService={selectedService}
            selectedAuthor={selectedAuthor}
            isLoading={props.isLoading}
            registerAuthor={props.registerAuthor}
            updateAuthor={props.updateAuthor}
            authorSubmissionStatus={props.isAuthorSubmissionSuccessful}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

class AddAuthorForm extends Component {
  state = {
    authorName:
      this.props.selectedAuthor !== undefined &&
      this.props.selectedService === AuthorOperation.EDIT_AUTHOR
        ? this.props.selectedAuthor.attributes.name
        : "",
    authorDescription:
      this.props.selectedAuthor !== undefined &&
      this.props.selectedService === AuthorOperation.EDIT_AUTHOR
        ? this.props.selectedAuthor.attributes.detail
        : "",
    email:
      this.props.selectedAuthor !== undefined &&
      this.props.selectedService === AuthorOperation.EDIT_AUTHOR
        ? this.props.selectedAuthor.attributes.email
        : "",
    profileImage: "",
    password: "",
    confirmPassword: "",
  };

  render() {
    // Props
    const {
      registerAuthor,
      updateAuthor,
      selectedService,
      selectedAuthor,
      isLoading,
    } = this.props;

    const resetForm = () => {
      this.setState({
        authorName: "",
        authorDescription: "",
        profileImage: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    };

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      //   Get the data
      let formDataContent = new FormData();
      let {
        authorName,
        authorDescription,
        profileImage,
        password,
        confirmPassword,
        email,
      } = this.state;
      const image = document.querySelector("#profileImage");

      profileImage = image.files[0] !== undefined ? image.files[0] : null;

      // Validation
      if (
        !validateEmptyString(authorName) ||
        !validateEmptyString(authorDescription) ||
        !validateEmptyString(password) ||
        !validateEmptyString(confirmPassword) ||
        !validateEmptyString(email)
      ) {
        return toast.warning(MISSING_FIELD);
      }

      if (password.length < 8 || confirmPassword.length < 8) {
        return toast.warning("Password must be at least 8 characters");
      }

      if (password !== confirmPassword) {
        return toast.warning(PASSWORD_DONOT_MATCH);
      }

      // Appending content to the form data
      formDataContent.append("data[attributes][name]", authorName);
      formDataContent.append("data[attributes][detail]", authorDescription);
      formDataContent.append("data[attributes][password]", password);
      formDataContent.append(
        "data[attributes][password_confirmation]",
        confirmPassword
      );
      formDataContent.append("data[attributes][email]", email);

      if (profileImage !== null) {
        formDataContent.append("data[attributes][image]", profileImage);
      }

      if (selectedService === AuthorOperation.EDIT_AUTHOR) {
        formDataContent.append("_method", "PATCH");
        await updateAuthor(formDataContent, selectedAuthor.author_id);
      } else {
        await registerAuthor(formDataContent);
      }
      resetForm();
    };

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{AUTHOR_NAME}</label>
            <input
              type="text"
              name="authorName"
              id="authorName"
              placeholder={AUTHOR_NAME}
              value={this.state.authorName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {AUTHOR_DESCRIPTION}
            </label>
            <textarea
              name="authorDescription"
              id="authorDescription"
              placeholder={AUTHOR_DESCRIPTION}
              value={this.state.authorDescription}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            ></textarea>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{PROFILE_IMAGE}</label>
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              placeholder={PROFILE_IMAGE}
              value={this.state.profileImage}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <hr />
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{EMAIL}</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={EMAIL}
              value={this.state.email}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{PASSWORD}</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder={PASSWORD}
              value={this.state.password}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {CONFIRM_PASSWORD}
            </label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder={CONFIRM_PASSWORD}
              value={this.state.confirmPassword}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isAuthorLoading,
    isAuthorSubmissionSuccessful:
      state.supplements.isAuthorSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAuthor: (authorContent) => dispatch(registerAuthor(authorContent)),
    updateAuthor: (authorContent, authorId) =>
      dispatch(updateAuthor(authorContent, authorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuthor);
