import React, { Component } from "react";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";

// Component
import { validateEmptyString } from "../Utility/Validation";
import { updateChapter } from "../../actions/ComicAction";
import { CANCEL, CHAPTER_TITLE, EDIT, EDIT_CHAPTER, MISSING_FIELD } from "../../config/app_strings";

class EditChapter extends Component {
  state = {
    chapter:
      this.props.selectedChapter !== undefined
        ? this.props.selectedChapter.attributes.title
        : "",
  };
  render() {
    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onFormSubmit = (e) => {
      e.preventDefault();

      // Validation
      if (!validateEmptyString(this.state.chapter)) {
        return toast.warning(MISSING_FIELD);
      }

      let content = {
        data: {
          type: "chapters",
          attributes: {
            title: this.state.chapter,
          },
        },
      };

      this.props.updateChapter(content, this.props.selectedChapter.chapter_id);
    };

    return (
      <div className="pt-5">
        <div className="flex flex-row items-center justify-between px-10 mb-2">
          <p className="text-xl font-bold text-gray-400">{EDIT_CHAPTER}</p>
          <button
            id="toggleSelection"
            name="toggleSelection"
            className="px-3 py-3 rounded-lg text-passOverColor "
            onClick={() => this.props.closingModalElement()}
          >
            <MdClose />
          </button>
        </div>
        <hr className="px-10" />
        <form onSubmit={onFormSubmit} className="mt-5">
          <div className="flex flex-col px-10 mb-5 space-y-3">
            <label className="font-light text-textColor">
              {CHAPTER_TITLE}<span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="chapter"
              id="chapter"
              placeholder={CHAPTER_TITLE}
              value={this.state.chapter}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-10 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-passOverColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {EDIT}
            </button>

            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => this.props.closingModalElement()}
            >
              {CANCEL}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateChapter: (content, chapterId) =>
      dispatch(updateChapter(content, chapterId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChapter);
