export const startLoader = (type) => {
  return (dispatch) => dispatch({ type: type });
};

export const stopLoader = (type) => {
  return (dispatch) => dispatch({ type: type });
};

export const resetLoader = (type) => {
  return (dispatch) => dispatch({ type: type });
};
