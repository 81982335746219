export const customStyles = {
  content: {
    top: "52%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    minWidth: "50%",
    maxWidth: "60%",
    maxHeight: "85%",
    minHeight: "88%",
    border: "none",
    zIndex: "40",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
};

export const customStylesForMobile = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    minWidth: "95%",
    maxWidth: "100%",
    maxHeight: "85%",
    minHeight: "88%",
    border: "none",
    zIndex: "40",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
};

export const fullScreenModalStyle = {
  content: {
    top: "-2%",
    left: "0%",
    right: "0%",
    bottom: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "100%",
    border: "none",
    zIndex: "60",
    overflowY: "auto"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.93)",
  },
}
