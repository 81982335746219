// External imports
import { toast } from "react-toastify";

// Component imports
import {
  ADD_ADVERT_SUCCESS,
  ADD_ADVERT_FAILED,
  EDIT_ADVERT_SUCCESS,
  EDIT_ADVERT_FAILED,
  DELETE_ADVERT_SUCCESS,
  DELETE_ADVERT_FAILED,
} from "../actions/Type";
import {
  ADVERT_ADDED_FAILED,
  ADVERT_ADDED_SUCCESSFULLY,
  ADVERT_DELETED_FAILED,
  ADVERT_DELETED_SUCCESSFULLY,
  ADVERT_UPDATED_FAILED,
  ADVERT_UPDATED_SUCCESSFULLY,
} from "../config/app_strings";

const initialState = { isLoggedIn: false, isSubmissionSuccessful: null };

const AdvertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADVERT_SUCCESS:
      toast.success(ADVERT_ADDED_SUCCESSFULLY);
      return { ...state, isSubmissionSuccessful: true };
    case ADD_ADVERT_FAILED:
      toast.error(ADVERT_ADDED_FAILED + action.payload);
      return { ...state, isSubmissionSuccessful: false };
    case EDIT_ADVERT_SUCCESS:
      toast.success(ADVERT_UPDATED_SUCCESSFULLY);
      return { ...state };
    case EDIT_ADVERT_FAILED:
      toast.error(ADVERT_UPDATED_FAILED + action.payload);
      return { ...state };
    case DELETE_ADVERT_SUCCESS:
      toast.success(ADVERT_DELETED_SUCCESSFULLY);
      return { ...state };
    case DELETE_ADVERT_FAILED:
      toast.error(ADVERT_DELETED_FAILED + action.payload);
      return { ...state };
    default:
      return state;
  }
};

export default AdvertReducer;
