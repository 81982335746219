/**
 * Supported supplements operations.
 * @enum {string}
 */
 export const SupplementsOperation = Object.freeze({
    ADD_GENRE: 'add_genre',
    EDIT_GENRE: 'edit_genre',
    ADD_TAG: 'add_tag',
    EDIT_TAG: 'edit_tag',
});

/**
 * Supported supplements display options.
 * @enum {string}
 */
export const SupplementsDisplayOption = Object.freeze({
    GENRE: 'genre',
    TAG: 'tag',
});