import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import HeroBackground from "../../../assets/images/HeroImage.jpg";
import {
  CONTRACTED_COMPANY,
  POWERED_BY,
  SIGN_IN,
} from "../../../config/app_strings";
import SignInAccount from "../../../components/Form/SignInAccount";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundSize: "cover",
      }}
    >
      <Link
        to={"/"}
        className="absolute p-3 md:p-4 bg-white bg-opacity-25 rounded-full top-5 left-5 md:top-10 md:left-10 "
      >
        <BiArrowBack  color="#fff"  className="text-lg md:text-2xl"/>
      </Link>

      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl md:w-1/3 rounded-2xl">
        <p className="md:text-4xl  text-2xl font-bold text-lightbg">{SIGN_IN}</p>
        <SignInAccount
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>

      <p className="mt-10 mb-10 text-bottom text-white">
        {POWERED_BY}
        <a href="https://vptrading.et" className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </a>
      </p>
    </div>
  );
}
