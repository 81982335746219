import React from "react";

// Image
import HeroBg from "../../../../assets/images/HeroImage.jpg";
import PharaniaLogo from "../../../../assets/images/Pharania Logo White.png";
import PharaniaLogoText from "../../../../assets/images/Pharania Logo Text White.png";

export default function Hero() {
  return (
    <div className="w-screen">
      <img src={HeroBg} alt="Pharania Hero" className="heroImage" />
      <div className="absolute left-0 right-0 flex flex-col items-center justify-center ml-auto mr-auto space-y-3 top-32 lg:top-10">
        <img src={PharaniaLogo} alt="Pharania Logo" className="logo" />
        <img src={PharaniaLogoText} alt="Pharania Logo" className="logo-text" />
      </div>
    </div>
  );
}
