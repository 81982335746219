import React, { useState, useEffect } from "react";

import { Link, Navigate } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine, RiRefreshLine } from "react-icons/ri";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import ViewAuthor from "./ViewAuthor";
import { numberFormat } from "../../../components/Utility/Variables";
import { instance } from "../../../config/api";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { AuthorDisplayOption, AuthorOperation } from "../../../enums/author";
import {
  ADD_AUTHOR,
  ADD_NEW_AUTHOR_DESCRIPTION,
  AUTHOR,
  AUTHOR_MANAGEMENT,
  AUTHOR_MANAGEMENT_DESCRIPTION,
  CHOOSE_OPTION,
  PERFORM_UTILITY_FUNCTIONS_ON_AUTHOR,
  SUMMARY,
  TOTAL_AUTHOR_IN_STORE,
  UTILITY,
} from "../../../config/app_strings";
import { MoonLoader } from "react-spinners";

export default function Root() {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div>
      <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
        <SideNav />
        <AuthorDashContent />
      </div>
    </div>
  );
}

const AuthorDashContent = ({ deleteAuthor, isLoading }) => {
  const [getAuthor, setAuthor] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    fetchAuthor();
  }, []);

  async function fetchAuthor() {
    setStateLoading(true);
    await instance
      .get("authors", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      })
      .then((response) => {
        setAuthor(response.data.data);
      })
      .catch((error) => {});
    setStateLoading(false);
  }

  return !stateLoading ? (
    <div className="flex flex-row w-full text-left bg-lightbg">
      <AuthorLeftContent
        deleteAuthor={deleteAuthor}
        isLoading={isLoading}
        getAuthor={getAuthor}
        fetchAuthor={fetchAuthor}
      />
      <AuthorDashRightSide getAuthor={getAuthor.length} />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full min-h-screen">
     <MoonLoader size="50" color="#008fe7" />
    </div>
  );
};

const AuthorLeftContent = ({ getAuthor, fetchAuthor }) => {
  const [toggleSelection, setToggleSelection] = useState(
    AuthorDisplayOption.AUTHOR
  );
  const [searchTerm, setSearchTerm] = useState("");
  // onHandle toggle change selection
  const onHandleChangeSelection = (e) => {
    setToggleSelection(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter authors based on search term
  const filteredAuthors = getAuthor.filter((author) =>
    author.data.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* Author Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">
            {toggleSelection === AuthorDisplayOption.AUTHOR
              ? AUTHOR_MANAGEMENT
              : CHOOSE_OPTION}
          </p>
          <p className="text-sm font-thin">
            {toggleSelection === AuthorDisplayOption.AUTHOR
              ? AUTHOR_MANAGEMENT_DESCRIPTION
              : ""}
          </p>
        </div>
        <div className="flex flex-row items-center w-1/3 space-x-5">
          <RiRefreshLine
            title="Refresh"
            size="50px"
            className="text-default"
            type="button"
            onClick={() => fetchAuthor()}
          />
          <input
            className="w-3/4 px-3 py-3 bg-transparent border rounded-lg text-textColor placeholder-black"
            placeholder="Search Author"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <select
            id="toggleSelection"
            name="toggleSelection"
            className="w-3/4 px-3 py-3 bg-transparent border rounded-lg text-textColor"
            onChange={(e) => onHandleChangeSelection(e)}
          >
            <option value={AuthorDisplayOption.AUTHOR}>{AUTHOR}</option>
          </select>
        </div>
      </div>

      {/* View */}
      {toggleSelection === AuthorDisplayOption.AUTHOR ? (
        <ViewAuthor data={filteredAuthors} />
      ) : (
        ""
      )}
    </div>
  );
};

// User Right Side
const AuthorDashRightSide = ({ getAuthor }) => {
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto bg-white shadow-2xl text-lightDefault">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">
          {PERFORM_UTILITY_FUNCTIONS_ON_AUTHOR}
        </p>
        <p className="text-4xl font-bold text-default">{UTILITY}</p>
      </div>

      {/* Add Author */}
      <Link
        to={{
          pathname: "/author/create",
        }}
        state={{ selectedService: AuthorOperation.ADD_AUTHOR }}
        className="flex flex-row items-start space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_AUTHOR}</p>
          <p className="text-sm text-default">{ADD_NEW_AUTHOR_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        {/* Each Notice */}
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getAuthor)}
            </p>
            <p className="text-sm text-default">{TOTAL_AUTHOR_IN_STORE}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
