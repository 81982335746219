import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clip from "text-clipper";
import Modal from "react-modal";
import GridLoader from "react-spinners/GridLoader";

// Component
import Nav from "../../../components/Nav/Nav";
import { instance } from "../../../config/api";
import { customStyles } from "../../../components/Config/ModalConfig";
import ComicBook from "../../../components/Modal/ComicBook";
import { NO_DATA } from "../../../config/app_strings";

export default function Root() {
  let location = useLocation();
  let selectedAuthor = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [comicBooks, setComicBooks] = useState([]);
  const [totalGenres, setTotalGenres] = useState([]);
  const [totalAuthors, setTotalAuthors] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [comicBookModalIsOpen, setComicBookModalIsOpen] = useState(false);

  useEffect(() => {
    fetchComicBooksByAuthor();
  }, [location.key]);

  async function fetchComicBooksByAuthor() {
    setIsLoading(true);
    try {
      let [genres, authors, authorComicbooks] = await Promise.all([
        instance.get("genres"),
        instance.get("authors"),
        instance.get("authors/" + selectedAuthor.data.author_id + "/comicbooks"),
      ]);
      setTotalGenres(genres.data);
      setTotalAuthors(authors.data);
      setComicBooks(authorComicbooks.data);
    } catch (error) {
    }
    setIsLoading(false);
  }

  const onCloseComicBookModal = (e) => {
    e.stopPropagation();
    setComicBookModalIsOpen(false);
  };

  const onOpenComicBookModal = (selectedSuggestionComic) => {
    setComicBookModalIsOpen(true);
    setSelectedSuggestion(selectedSuggestionComic);
  };

  const mappingAuthorSpecificComicBook =
    comicBooks && comicBooks.data && comicBooks.data.length > 0 ? (
      comicBooks.data.map((comic) => (
        <div
          className="relative transition duration-500 transform group hover:scale-110 comic-book-thumbnail-card"
          key={comic.data.comicbook_id}
          onClick={() => onOpenComicBookModal(comic)}
        >
          <img
            src={comic.links.image}
            alt={comic.data.attributes.title}
            className="object-fill h-full w-full"
          />
          <div className="absolute left-0 right-0 bottom-0 opacity-0 fd-sh group-hover:opacity-100 bg-passOverColor transition duration-500 transform">
            <div>
              <p className="px-3 pt-3 text-xs text-white font-bold">
                {comic.data.attributes.title}
              </p>
              <p className="px-3 py-3 text-xs text-white">
                {clip(comic.data.attributes.description, 80)}
              </p>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="text-xl text-white h-screen text-center m-auto mt-20">
        {NO_DATA}
      </p>
    );

  return !isLoading ? (
    <div>
      <Nav from="search" genres={totalGenres} authors={totalAuthors} />
      <div className="flex flex-col py-28 px-10 space-y-5">
        <h2 className="text-white font-bold text-3xl">
          {selectedAuthor.data.attributes.name}
        </h2>
        <div className="flex flex-row flex-wrap gap-x-5 gap-y-10">
          {mappingAuthorSpecificComicBook}
        </div>
      </div>

      {/* Comic Book Modal */}
      <Modal
        isOpen={comicBookModalIsOpen}
        onRequestClose={(e) => onCloseComicBookModal(e)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel={`Comic Book Modal: `}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <ComicBook
          comicBook={selectedSuggestion}
          onCloseButtonPressed={onCloseComicBookModal}
          onOpenButtonPressed={onOpenComicBookModal}
        />
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full min-h-screen">
      <GridLoader color="white" size="10px" className="m-auto" />
    </div>
  );
}
