import React, { Component, useEffect, useState } from "react";

// External import
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from "react-redux";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import { editProfile } from "../../../actions/AuthAction";
import { instance } from "../../../config/api";
import {
  validateEmptyString,
  validateEmail,
  validatePasswordConfirmation,
} from "../../../components/Utility/Validation";
import { toast } from "react-toastify";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { UserOperation } from "../../../enums/user";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_DESCRIPTION,
  CONFIRM_PASSWORD,
  CURRENT_PASSWORD,
  EDIT_PROFILE,
  EDIT_PROFILE_DESCRIPTION,
  EMAIL,
  FULL_NAME,
  INVALID_EMAIL,
  MISSING_FIELD,
  NEW_PASSWORD,
  PASSWORD_DONOT_MATCH,
  SUBMIT,
} from "../../../config/app_strings";
import {  MoonLoader } from "react-spinners";

function EditProfile(props) {
  // state
  const [getSingleUser, setSingleUser] = useState([]);
  const [isFetching, setFetching] = useState(false);

  let location = useLocation();

  // Getting selected service props
  const selectedService = location.state.selectedService;

  useEffect(() => {
    async function fetchSingleUser() {
      setFetching(true);
      await instance
        .get("user/", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((response) => {
          setSingleUser(response.data);
        })
        .catch((error) => {});
      setFetching(false);
    }

    fetchSingleUser();
  }, []);

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />

      {!isFetching ? (
        <div className="w-full px-10">
          <div className="flex flex-row items-center py-10 space-x-5">
            <Link to={"/dashboard"}>
              <FaLongArrowAltLeft className="text-default " size="30" />
            </Link>

            {/* Profile Header */}
            <div className="flex flex-col">
              <p className="text-4xl font-bold text-textColor">
                {selectedService === UserOperation.EDIT_PROFILE &&
                typeof selectedService !== "undefined"
                  ? EDIT_PROFILE
                  : CHANGE_PASSWORD}
              </p>
              <p className="text-sm font-thin">
                {selectedService === UserOperation.EDIT_PROFILE &&
                typeof selectedService !== "undefined"
                  ? EDIT_PROFILE_DESCRIPTION
                  : CHANGE_PASSWORD_DESCRIPTION}
              </p>
            </div>
          </div>

          {/* Profile/Change password form */}
          <EditProfileForm
            selectedService={selectedService}
            getSingleUser={getSingleUser}
            editProfile={props.editProfile}
          />
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center m-auto mt-24">
           <MoonLoader size="50" color="#008fe7" />
        </div>
      )}
    </div>
  );
}

class EditProfileForm extends Component {
  // Component state
  state = {
    userFullName:
      this.props.selectedService === UserOperation.EDIT_PROFILE
        ? this.props.getSingleUser.name
        : "",
    userEmail:
      this.props.selectedService === UserOperation.EDIT_PROFILE
        ? this.props.getSingleUser.email
        : "",

    userCurrentPassword: "",
    userNewPassword: "",
    userConfirmPassword: "",
  };
  render() {
    // Accepting props
    const { selectedService, editProfile, isLoading } = this.props;

    //   onHandleChangeInField
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   onHandleFormSubmit
    const onFormSubmit = (e) => {
      e.preventDefault();
      // variables
      const {
        userFullName,
        userEmail,
        userCurrentPassword,
        userNewPassword,
        userConfirmPassword,
      } = this.state;

      if (selectedService === UserOperation.EDIT_PROFILE) {
        if (
          !validateEmptyString(userFullName) ||
          !validateEmptyString(userEmail)
        ) {
          return toast.warning(MISSING_FIELD);
        }
        if (!validateEmail(userEmail)) {
          return toast.warning(INVALID_EMAIL);
        }

        const content = {
          data: {
            attributes: {
              name: userFullName,
              email: userEmail,
            },
          },
        };

        editProfile(content);
      } else {
        if (
          !validateEmptyString(userCurrentPassword) ||
          !validateEmptyString(userCurrentPassword) ||
          !validateEmptyString(userNewPassword) ||
          !validateEmptyString(userConfirmPassword)
        ) {
          return toast.warning(MISSING_FIELD);
        }

        if (
          !validatePasswordConfirmation(userNewPassword, userConfirmPassword)
        ) {
          return toast.warning(PASSWORD_DONOT_MATCH);
        }

        const content = {
          data: {
            attributes: {
              password: userNewPassword,
              password_confirmation: userConfirmPassword,
            },
          },
        };

        editProfile(content);
      }
    };

    return selectedService === UserOperation.EDIT_PROFILE ? (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{FULL_NAME}</label>
            <input
              type="text"
              name="userFullName"
              id="userFullName"
              placeholder={FULL_NAME}
              value={this.state.userFullName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{EMAIL}</label>
            <input
              type="text"
              name="userEmail"
              id="userEmail"
              placeholder={EMAIL}
              value={this.state.userEmail}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    ) : (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{CURRENT_PASSWORD}</label>
            <input
              type="password"
              name="userCurrentPassword"
              id="userCurrentPassword"
              placeholder={CURRENT_PASSWORD}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{NEW_PASSWORD}</label>
            <input
              type="password"
              name="userNewPassword"
              id="userNewPassword"
              placeholder={NEW_PASSWORD}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{CONFIRM_PASSWORD}</label>
            <input
              type="password"
              name="userConfirmPassword"
              id="userConfirmPassword"
              placeholder={CONFIRM_PASSWORD}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <button
            className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
            type="submit"
          >
            {SUBMIT}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isUserLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editProfile: (userId, content, type) =>
      dispatch(editProfile(userId, content, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
