import { useRef } from "react";
import GridLoader from "react-spinners/GridLoader";
import Carousel from "nuka-carousel";

import { single_settings } from "../components/Config/CarouselConfig";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import { MapInteractionCSS } from "react-map-interaction";

const SlickSlider = ({
  selectedChapterPages,
  isPageLoading,
  selectedPage,
  setSelectedPage,
}) => {

  const zoomRef = useRef();

  /* The `mappingComicBooks` constant is mapping over the `selectedChapterPages` data and creating an
    array of `Slide` components with an `img` element inside. Each `Slide` component is wrapped in a
    `TransformWrapper` component from the `react-zoom-pan-pinch` library, which allows the user to
    zoom in and out of the image. The `TransformComponent` component is used to wrap the `img` element
    and apply the zooming functionality. The `mappingComicBooks` constant is conditionally rendered
    based on whether `selectedChapterPages` data exists and has a length greater than 0. If it does
    not meet this condition, `mappingComicBooks` is set to `null`. */
  const mappingComicBooks =
    selectedChapterPages &&
    selectedChapterPages.data &&
    selectedChapterPages.data.data.length > 0
      ? selectedChapterPages.data.data.map((page) => (
          <MapInteractionCSS
            ref={zoomRef}
            showControls={true}
            btnClass="bg-placeholderColor w-12 h-12 rounded-full text-white"
            controlsClass="absolute flex flex-row justify-center items-center left-0 right-0 bottom-2 space-x-2"
          >
            <div className="flex flex-col items-center space-y-2 transform-component-wrapper md:justify-center overflow-hidden">
              <div className="transform-component flex flex-row md:justify-center overflow-hidden">
                <img
                  src={page.links.image}
                  alt={""}
                  className={`zoomable-content overflow-hidden`}
                />
              </div>
              {/* <div className="tools text-white flex flex-row space-x-5">
                <button
                  className="bg-placeholderColor p-2 rounded-full"
                  onClick={(e) => {}}
                >
                  <CiMaximize1 size={18} />
                </button>
                <button
                  className="bg-placeholderColor p-2 rounded-full"
                  onClick={(e) => {
                  }}
                >
                  <CiMinimize1 size={18} />
                </button>
              </div> */}
            </div>
          </MapInteractionCSS>
        ))
      : null;

  return isPageLoading ? (
    <div className="m-auto flex flex-col justify-center items-center text-white h-2/3">
      <GridLoader size="10px" className="m-auto text-default" />
    </div>
  ) : (
    <div>
      <Carousel
        {...single_settings}
        adaptiveHeight={false}
        enableKeyboardControls={true}
        withoutControls={false}
        slideIndex={selectedPage}
        afterSlide={(index) => setSelectedPage(index)}
        currentSlide={selectedPage}
        className="single_slider"
        renderCenterRightControls={({ nextSlide }) => (
          <MdOutlineArrowForwardIos
            onClick={nextSlide}
            className={
              selectedPage === selectedChapterPages.data.data.length - 1
                ? "text-gray-600 cursor-not-allowed"
                : "text-gray-500 cursor-pointer"
            }
            size={30}
          />
        )}
        renderCenterLeftControls={({ previousSlide }) => (
          <MdOutlineArrowBackIosNew
            onClick={previousSlide}
            className={
              selectedPage === 0
                ? "text-gray-600 cursor-not-allowed"
                : "text-gray-500 cursor-pointer"
            }
            size={30}
          />
        )}
      >
        {mappingComicBooks}
      </Carousel>
    </div>
  );
};

export default SlickSlider;
