import React, { Component } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import { registerAdvert, updateAdvert } from "../../../actions/AdvertAction";
import { resetLoader } from "../../../actions/AppAction";
import { validateEmptyString } from "../../../components/Utility/Validation";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { ADVERT_RESET_LOADER } from "../../../actions/Type";
import { AdvertOperation, AdvertType, AdvertTypeOperation } from "../../../enums/advert";
import { ADD_ADVERT, ADD_ADVERT_TYPE, ADD_NEW_ADVERT_DESCRIPTION, ADD_NEW_ADVERT_TYPE_DESCRIPTION, ADVERT_IMAGE, ADVERT_TYPE, ADVERT_TYPE_LANDSCAPE, ADVERT_TYPE_PORTRAIT, ADVERT_TYPE_SQUARE, CHOOSE_OPTION, COMPANY_NAME, DESCRIPTION, DO_YOU_WANT_IT_TO_BE_LIVE, EDIT_ADVERT, EDIT_ADVERT_DESCRIPTION, EDIT_ADVERT_TYPE, EDIT_ADVERT_TYPE_DESCRIPTION, MISSING_FIELD, NO, SUBMIT, YES } from "../../../config/app_strings";

function ManageAdvert(props) {
  let location = useLocation();
  
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  // Getting selected service
  const selectedService = location.state.selectedService;
  const selectedAdvert = location.state.selectedAdvert;

  return (
    <div className="flex-col text-left md:flex md:flex-row md:min-h-screen bg-lightbg">
      <SideNav />
      <div className="w-full px-10">
        <div className="flex flex-row items-center space-x-5">
          <Link to={"/advert"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Advert Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-textColor">
              {selectedService === AdvertOperation.ADD_ADVERT &&
              typeof selectedService !== "undefined"
                ? ADD_ADVERT
                : selectedService === AdvertOperation.EDIT_ADVERT &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedAdvert !== "undefined"
                ? EDIT_ADVERT
                : selectedService === AdvertTypeOperation.ADD_ADVERT_TYPE &&
                  typeof selectedService !== "undefined"
                ? ADD_ADVERT_TYPE
                : selectedService === AdvertTypeOperation.EDIT_ADVERT_TYPE &&
                  typeof selectedService !== "undefined" &&
                  selectedAdvert !== "undefined"
                ? EDIT_ADVERT_TYPE
                : ""}
            </p>
            <p className="text-sm font-thin">
              {selectedService === AdvertOperation.ADD_ADVERT &&
              typeof selectedService !== "undefined"
                ? ADD_NEW_ADVERT_DESCRIPTION
                : selectedService === AdvertOperation.EDIT_ADVERT &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedAdvert !== "undefined"
                ? EDIT_ADVERT_DESCRIPTION
                : selectedService === AdvertTypeOperation.ADD_ADVERT_TYPE &&
                  typeof selectedService !== "undefined"
                ? ADD_NEW_ADVERT_TYPE_DESCRIPTION
                : selectedService === AdvertTypeOperation.EDIT_ADVERT_TYPE &&
                  typeof selectedService !== "undefined" &&
                  selectedAdvert !== "undefined"
                ? EDIT_ADVERT_TYPE_DESCRIPTION
                : ""}
            </p>
          </div>
        </div>

        {/* Advert form */}

        {selectedService === AdvertTypeOperation.ADD_ADVERT_TYPE ||
        selectedService === AdvertTypeOperation.EDIT_ADVERT_TYPE ? (
          ""
        ) : (
          <AdvertForm
            selectedService={selectedService}
            selectedAdvert={selectedAdvert}
            registerAdvert={props.registerAdvert}
            isLoading={props.isLoading}
            resetLoader={props.resetLoader}
            updateAdvert={props.updateAdvert}
            advertSubmissionStatus={props.advertSubmissionStatus}
          />
        )}
      </div>
    </div>
  );
}

class AdvertForm extends Component {
  state = {
    advertCompanyName:
      this.props.selectedService === AdvertOperation.EDIT_ADVERT
        ? this.props.selectedAdvert.data.attributes.company_name
        : "",
    advertType:
      this.props.selectedService === AdvertOperation.EDIT_ADVERT
        ? this.props.selectedAdvert.data.attributes.type
        : "",
    isFeatured:
      this.props.selectedService === AdvertOperation.EDIT_ADVERT
        ? this.props.selectedAdvert.data.attributes.is_featured === 1
          ? "true"
          : "false"
        : "",
    advertDescription:
      this.props.selectedService === AdvertOperation.EDIT_ADVERT
        ? this.props.selectedAdvert.data.attributes.description
        : "",
    leadingImage: "",

    stateLoading: false,
  };

  componentWillUnmount() {
    this.props.resetLoader(ADVERT_RESET_LOADER);
  }

  render() {
    // Props
    const {
      registerAdvert,
      selectedService,
      selectedAdvert,
      updateAdvert,
      isLoading,
      advertSubmissionStatus,
    } = this.props;

    // State
    let {
      advertCompanyName,
      advertType,
      advertDescription,
      isFeatured,
      leadingImage,
    } = this.state;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      // Get the data
      let formDataContent = new FormData();

      const image = document.querySelector("#leadingImage");

      // Validation
      if (
        !validateEmptyString(advertCompanyName) ||
        !validateEmptyString(advertType) ||
        !validateEmptyString(isFeatured) ||
        !validateEmptyString(advertDescription)
      )
        return toast.warning(MISSING_FIELD);

      // Append to form data
      formDataContent.append(
        "data[attributes][company_name]",
        advertCompanyName
      );
      formDataContent.append("data[attributes][type]", advertType);
      formDataContent.append(
        "data[attributes][description]",
        advertDescription
      );
      formDataContent.append(
        "data[attributes][is_featured]",
        isFeatured === "true" ? 1 : 0
      );

      leadingImage = image.files[0] !== undefined ? image.files[0] : null;

      if (leadingImage !== null) {
        formDataContent.append("data[attributes][image]", leadingImage);
      }
      if (selectedService === AdvertOperation.ADD_ADVERT) {
        registerAdvert(formDataContent);
      } else {
        formDataContent.append("_method", "PATCH");
        updateAdvert(formDataContent, selectedAdvert.data.advertisement_id);
      }
    };

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{COMPANY_NAME}</label>
            <input
              type="text"
              name="advertCompanyName"
              id="advertCompanyName"
              placeholder="Company Name"
              value={advertSubmissionStatus ? "" : advertCompanyName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{ADVERT_TYPE}</label>
            <select
              name="advertType"
              id="advertType"
              placeholder="Advert Type"
              value={advertSubmissionStatus ? "" : advertType}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            >
              <option value="">{CHOOSE_OPTION}</option>
              <option value={AdvertType.LANDSCAPE}>{ADVERT_TYPE_LANDSCAPE}</option>
              <option value={AdvertType.PORTRAIT}>{ADVERT_TYPE_PORTRAIT}</option>
              <option value={AdvertType.SQUARE}>{ADVERT_TYPE_SQUARE}</option>
            </select>
          </div>

          <div className="flex flex-col space-y-3">
            <label className="text-textColor">
              {DO_YOU_WANT_IT_TO_BE_LIVE}
            </label>
            <select
              name="isFeatured"
              id="isFeatured"
              placeholder="Select"
              value={advertSubmissionStatus ? "" : isFeatured}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            >
              <option value="">{CHOOSE_OPTION}</option>
              <option value="true">{YES}</option>
              <option value="false">{NO}</option>
            </select>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">{DESCRIPTION}</label>
            <textarea
              type="text"
              name="advertDescription"
              id="advertDescription"
              placeholder="Description"
              value={advertSubmissionStatus ? "" : this.state.advertDescription}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            ></textarea>
          </div>

          <div className="flex flex-col space-y-3">
            <label className="text-textColor">{ADVERT_IMAGE}</label>
            <input
              type="file"
              name="leadingImage"
              id="leadingImage"
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          {isLoading ? (
            <ScaleLoader color="#037d50" />
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isAdvertLoading,
    advertSubmissionStatus: state.advert.isSubmissionSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAdvert: (content) => dispatch(registerAdvert(content)),
    updateAdvert: (content, advertId) =>
      dispatch(updateAdvert(content, advertId)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdvert);
