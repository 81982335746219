import React, { useState } from "react";
import { BsBookmarksFill } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import Modal from "react-modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from "react-redux";

// Component imports
import { deleteGenre } from "../../../actions/SupplementsAction";

// Images
import NoData from "../../../assets/images/no-data.gif";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { SupplementsOperation } from "../../../enums/supplements";
import { CANCEL, DELETE, DELETE_FROM_GENERE, EDIT, FROM_GENERE, NO_GENRE } from "../../../config/app_strings";
import ActionModal from "../../../components/Modal/ActionModal";
import ShimmerIndicator from "../../../components/Shimmer/ShimmerIndicator";

function ViewGenres({ data, deleteGenre }) {
  // state
  const [isModalOpen, setModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDeletableFieldName, setSelectedDeletableFieldName] =
    useState("");
  const [selectedDeletableField, setSelectedDeletableField] = useState("");

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDeletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDeletableFieldName("");
  };

  // Mapping
  const mappingGenres =
    data && data.length > 0 ? (
      data.map((data) => (
        <div
          className="flex flex-row w-1/3 px-4 py-3 mb-4 space-x-6 space-y-2 rounded-lg shadow-lg bg-lightbg"
          key={data.data.genre_id}
        >
          <div className="flex flex-col items-center justify-center w-1/3 p-3 bg-passOverColor rounded-xl">
            <BsBookmarksFill size={40} className="text-white" />
          </div>
          <div className="flex flex-col flex-wrap justify-between w-3/4 text-textColor">
            <p className="w-5/6 text-lg font-bold truncate">
              {data.data.attributes.name}
            </p>
            <p className="mb-2 text-xs font-bold text-gray-300">
              {`@ ${data.data.attributes.created_by.data.attributes.name}`}
            </p>
            <div className="flex flex-row space-x-5">
              <Link
                to={{
                  pathname: "/supplement/create",
                  
                }}
                state={{
                  selectedService: SupplementsOperation.EDIT_GENRE,
                  selectedGenre: data.data,
                }}
                className="text-sm text-gray-300 hover:text-lightDefault"
              >
                {EDIT}
              </Link>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  openingModalElement(
                    data.data.genre_id,
                    data.data.attributes.name
                  );
                }}
              >
                {DELETE}
              </button>
            </div>
          </div>
        </div>
      ))
    ) : (
      <ShimmerIndicator width={200} height={120} count={10} />
    );

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex flex-row flex-wrap w-full px-5 text-black placeholder-black bg-transparent">
      {mappingGenres}
      <ActionModal
        fromName={FROM_GENERE}
        actionFromName={DELETE_FROM_GENERE}
        isModalOpen={isModalOpen}
        onClose={closingModalElement}
        onConfirm={async () => {
          deleteGenre(selectedDeletableField);
          closingModalElement();
        }}
        isProcessingDelete={isProcessingDelete}
        itemName={selectedDeletableFieldName}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteGenre: (genreId) => dispatch(deleteGenre(genreId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewGenres);
