import {
  ADD_ADVERT_SUCCESS,
  ADD_ADVERT_FAILED,
  EDIT_ADVERT_SUCCESS,
  EDIT_ADVERT_FAILED,
  DELETE_ADVERT_SUCCESS,
  DELETE_ADVERT_FAILED,
  ADVERT_START_LOADER,
  ADVERT_STOP_LOADER,
} from "./Type";
import { instance, CSRFInstance } from "../config/api";
import { startLoader, stopLoader } from "./AppAction";
import { encryptAndDecryptLocalStorage } from "./AuthAction";

// Ad
export const registerAdvert = (content) => {
  return (dispatch) => {
    dispatch(startLoader(ADVERT_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/advertisements", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: ADD_ADVERT_SUCCESS });
            dispatch(stopLoader(ADVERT_STOP_LOADER));
          } else {
            dispatch({ type: ADD_ADVERT_FAILED, payload: res.data.message });
            dispatch(stopLoader(ADVERT_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_ADVERT_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(ADVERT_STOP_LOADER));
        });
    });
  };
};

export const updateAdvert = (content, advertId) => {
  return (dispatch) => {
    dispatch(startLoader(ADVERT_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/advertisements/" + advertId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_ADVERT_SUCCESS });
            dispatch(stopLoader(ADVERT_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_ADVERT_FAILED, payload: res.data.message });
            dispatch(stopLoader(ADVERT_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_ADVERT_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(ADVERT_STOP_LOADER));
        });
    });
  };
};

export const deleteAdvert = (advertId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(ADVERT_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("advertisements/" + advertId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token", false)
              )}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(ADVERT_STOP_LOADER));
              dispatch({ type: DELETE_ADVERT_SUCCESS });
            } else {
              dispatch(stopLoader(ADVERT_STOP_LOADER));
              dispatch({
                type: DELETE_ADVERT_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(ADVERT_STOP_LOADER));
            dispatch({
              type: DELETE_ADVERT_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(ADVERT_STOP_LOADER));
        dispatch({
          type: DELETE_ADVERT_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};
