/**
 * Supported comic operations.
 * @enum {string}
 */
 export const ComicOperation = Object.freeze({
    ADD_COMIC: 'add_comic',
    EDIT_COMIC: 'edit_comic',
    ADD_PAGE: 'add_page',
    EDIT_PAGE: 'edit_page',
    ADD_SERIES: 'add_series',
    EDIT_SERIES: 'edit_series',
    ADD_CHAPTER: 'add_chapter',
    EDIT_CHAPTER: 'edit_chapter',
});

/**
 * Supported comic display option.
 * @enum {string}
 */
 export const ComicDisplayOption = Object.freeze({
    COMIC: 'comic',
    SERIES: 'series',
    PAGE: 'page',
    CHAPTER: 'chapter',
});
