import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

import Slider from "react-slick";
import Modal from "react-modal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import { settings } from "../Config/CarouselConfig";
import { fullScreenModalStyle } from "../Config/ModalConfig";
import { instance } from "../../config/api";
import ChapterReadingSection from "../../layouts/client/comic/Root";
import { CLOSE, LOADING } from "../../config/app_strings";
import ShimmerIndicator from "../Shimmer/ShimmerIndicator";

/// Comic Book Modal
export default function ComicBook({
  comicBook,
  onCloseButtonPressed,
  onOpenButtonPressed,
  fromSearch,
  selectedComicBookId,
}) {
  const [comicBookChapters, setComicBookChapters] = useState([]);
  const [isChapterLoading, setIsChapterLoading] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedComic, setSelectedComic] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /**
     * This function fetches comic book chapters and sets them in state.
     */
    async function fetchComicBookChapters() {
      setIsChapterLoading(true);
      try {
        var chapters = await instance.get(
          "comicBooks/" + comicBook.data.comicbook_id + "/chapters"
        );
        setComicBookChapters(chapters);
      } catch (error) {
      }
      setIsChapterLoading(false);
    }

    /**
     * This function fetches details of a selected comic book using an API call and sets the selected
     * comic book state.
     */
    async function fetchComicDetails() {
      setIsLoading(true);
      try {
        const comic = await instance.get("comicBooks/" + selectedComicBookId);
        setSelectedComic(comic.data);
      } catch (error) {
      }
      setIsLoading(false);
    }

    /**
     * This function fetches the chapters of a selected comic book and sets them in the state.
     */
    async function fetchChapterDetail() {
      setIsChapterLoading(true);
      try {
        var chapters = await instance.get(
          "comicBooks/" + selectedComicBookId + "/chapters"
        );
        setComicBookChapters(chapters);
      } catch (error) {
      }
      setIsChapterLoading(false);
    }

    if (fromSearch != null && fromSearch && selectedComicBookId != null) {
      fetchComicDetails();
      fetchChapterDetail();
    } else {
      fetchComicBookChapters();
    }
  }, []);

  /**
   * The function sets the modalIsOpen state to false and clears the selectedChapter object when the
   * modal is closed.
   * @param e - The parameter "e" is an event object that is passed as an argument to the function. It
   * is used to handle the event that triggered the function, in this case, the event of closing a
   * modal. The event object contains information about the event, such as the type of event, the
   * target
   */
  const onCloseModal = (e) => {
    e.stopPropagation();
    setModalIsOpen(false);
    setSelectedChapter({});
  };

  /**
   * This function sets the modal to open and selects a specific chapter when a modal is opened.
   * @param e - The "e" parameter is an event object that is passed to the function as an argument. It
   * is typically an instance of the MouseEvent or KeyboardEvent class, depending on the type of event
   * that triggered the function. In this case, it is likely a MouseEvent object, since the function is
   * being used
   * @param selectedChapter - `selectedChapter` is a variable that holds the data of the chapter that
   * was selected by the user. It is passed as a parameter to the `onOpenModal` function.
   */
  const onOpenModal = (e, selectedChapter) => {
    e.stopPropagation();
    setModalIsOpen(true);
    setSelectedChapter(selectedChapter);
  };

  /* `mappingComicBooks` is a variable that holds the result of mapping over the `comicBookChapters`
  array. It checks if `comicBookChapters` has a `data` property, if `data` has a `data` property,
  and if `data.data` has a length greater than 0. If all of these conditions are true, it maps over
  `comicBookChapters.data.data` and returns a div element for each chapter with an image and title.
  If any of the conditions are false, it sets `mappingComicBooks` to null. */
  const mappingComicBooks =
    comicBookChapters.data &&
    typeof comicBookChapters.data.data != "undefined" &&
    comicBookChapters.data.data.length > 0
      ? comicBookChapters.data.data.map((comics) => (
          <div
            className="pr-3"
            key={comics.data.chapter_id}
            onClick={(e) => onOpenModal(e, comics)}
          >
            <img
              src={
                fromSearch
                  ? typeof selectedComic.links !== "undefined"
                    ? selectedComic.links.image
                    : ""
                  : comicBook.links.image
              }
              alt={"Client Company Name"}
              className="w-full slide-image"
            />
            <p className="text-center text-white">
              {comics.data.attributes.title}
            </p>
          </div>
        ))
      : null;

  return !isLoading ? (
    <div className="w-full">
      <div className="relative flex flex-row justify-end z-40">
        <button
          className="relative flex flex-row items-center justify-end space-x-3 px-3 py-3 right-0 z-40 cursor-pointer"
          onClick={(e) => onCloseButtonPressed(e)}
        >
          <p className="text-white">{CLOSE}</p>
          <IoClose className="text-white" />
        </button>
      </div>
      <div className="flex flex-col justify-center px-5 space-y-3">
        <img
          src={
            fromSearch
              ? typeof selectedComic.links !== "undefined"
                ? selectedComic.links.image
                : ""
              : comicBook.links.image
          }
          alt={
            fromSearch
              ? typeof selectedComic.data !== "undefined"
                ? selectedComic.data.attributes.title
                : ""
              : comicBook.data.attributes.title
          }
          className="w-1/2 md:w-1/3 m-auto"
        />
        <p className="text-sm text-white">
          {fromSearch
            ? typeof selectedComic.data != "undefined"
              ? selectedComic.data.attributes.description
              : ""
            : comicBook.data.attributes.description}
        </p>
        {isChapterLoading ? (
          <ShimmerIndicator width={100} height={150} count={4} />
        ) : (
          <Slider {...settings} className="chapter_slider">
            {mappingComicBooks}
          </Slider>
        )}
      </div>

      {/* Reading View Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => onCloseModal(e)}
        style={fullScreenModalStyle}
        ariaHideApp={true}
        contentLabel={`Chapter Modal: `}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        className="z-40"
      >
        <ChapterReadingSection
          selectedChapter={selectedChapter}
          onCloseModal={onCloseModal}
          onParentClose={onCloseButtonPressed}
          comicBookChapters={comicBookChapters}
          comicBook={fromSearch ? selectedComic : comicBook}
        />
      </Modal>
    </div>
  ) : (
    <p className="text-white">{LOADING}</p>
  );
}
