import React from "react";

// Images
import MobileMock from "../../assets/images/mobile-mock.png";

// Depe
import Modal from "react-modal";

const DownloadAppModal = ({ isDownloadModalOpen, setIsDownloadModalOpen }) => {
  return (
    <Modal
      className="py-10 px-10 rounded-lg bg-default w-screen lg:w-1/2 m-auto my-32 flex flex-col lg:flex-row justify-between items-end"
      isOpen={isDownloadModalOpen}
      onRequestClose={(e) => setIsDownloadModalOpen(false)}
      ariaHideApp={false}
      contentLabel="Download Pharania Android App"
      role={"dialog"}
    >
      <div className="text-white text-4xl font-bold flex flex-col">
        <div>
          <span className="text-xl font-light">Télécharger Pharanie</span>{" "}
          <br /> Application mobile
        </div>
        <p className="text-lg font-light mt-5">
          Téléchargez notre application Android GRATUITE pour lire les dernières
          bandes dessinées de divers auteurs
        </p>

        <a
          href="market://details?id=com.app.pharania&hl=en&gl=US"
          download={true}
          className="bg-lightDefault mt-5 text-lg px-2 rounded-md py-2 lg:w-2/3 text-center cursor-pointer"
        >
          Télécharger l'application
        </a>

        <p
          onClick={() => setIsDownloadModalOpen(false)}
          className="text-sm mt-3 font-light lg:text-left text-center lg:w-1/2 cursor-pointer"
        >
          Fermer
        </p>
      </div>
      <div className="-mb-40 flex flex-row justify-center md:justify-end lg:w-2/3">
        <img
          src={MobileMock}
          className="w-2/3 flex flex-row justify-center"
          alt="Pharania Mobile Mock"
        />
      </div>
    </Modal>
  );
};

export default DownloadAppModal;
