import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine } from "react-icons/ri";
import { BiMessageSquareEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

import { connect } from "react-redux";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import {
  numberFormat,
  defaultColDef,
  rowSelectionType,
} from "../../../components/Utility/Variables";
import { deleteAdvert } from "../../../actions/AdvertAction";
import ViewAdvertType from "./ViewAdvertType";
import { instance } from "../../../config/api";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { AdvertDisplayOption, AdvertOperation } from "../../../enums/advert";
import {
  IMAGE,
  ADVERT_TYPE,
  COMPANY_NAME,
  DESCRIPTION,
  CURRENTLY_LIVE,
  ACTION,
  DELETE,
  CANCEL,
  ADVERT,
  ADVERT_TYPE_MANAGEMENT_DESCRIPTION,
  ADVERT_MANAGEMENT_DESCRIPTION,
  ADVERT_TYPE_MANAGEMENT,
  ADVERT_MANAGEMENT,
  ADD_ADVERT,
  ADD_ADVERT_DESCRIPTION,
  SUMMARY,
  TOTAL_ADVERT_IN_STORE,
  DELETE_FROM_COMIC,
  FROM_COMIC,
  FROM_ADVERT,
  DELETE_FROM_ADVERT,
} from "../../../config/app_strings";
import ActionModal from "../../../components/Modal/ActionModal";

function Root(props) {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div>
      <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
        <SideNav />
        <AdvertDash
          deleteAdvert={props.deleteAdvert}
          isLoading={props.isLoading}
        />
        {/* <BackgroundImage /> */}
      </div>
    </div>
  );
}

// Blog Dashboard Content
const AdvertDash = ({ deleteAdvert, isLoading }) => {
  const [getAdvert, setAdvert] = useState([]);
  const [stateLoading] = useState(false);

  return !stateLoading ? (
    <div className="flex flex-row w-full text-left bg-lightbg">
      <AdvertDashLeftSide
        deleteAdvert={deleteAdvert}
        isLoading={isLoading}
        setAdvert={setAdvert}
      />
      <AdvertDashRightSide getAdvert={getAdvert.length} />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <ClimbingBoxLoader size="10" color="#037d50" />
    </div>
  );
};

// Hotel Left Side
const AdvertDashLeftSide = ({ deleteAdvert, isLoading, setAdvert }) => {
  const [toggleSelection, setToggleSelection] = useState(
    AdvertDisplayOption.ADVERT
  );
  const [ isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeletableField, setSelectedDeletableField] = useState();
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
  
    useState();

  // Ag Grid
  const onGridReady = (params) => {
    instance
      .get("advertisements")
      .then((response) => {
        params.api.applyTransaction({ add: response.data.data });
        setAdvert(response.data.data);
      })
      .catch((error) => {});
  };

  const columnDefs = [
    {
      headerName: IMAGE,
      field: "image",
      cellRenderer: (params) => (
        <img
          src={params.data.links.image}
          alt={params.data.data.attributes.company_name}
          className="object-contain"
        />
      ),
    },
    { headerName: ADVERT_TYPE, field: "data.attributes.type" },
    { headerName: COMPANY_NAME, field: "data.attributes.company_name" },
    {
      headerName: DESCRIPTION,
      field: "data.attributes.description",
    },
    {
      headerName: CURRENTLY_LIVE,
      field: "data.attributes.is_featured",
      cellRenderer: (params) => {
        if (params.data.data.attributes.is_featured) return <p>Yes</p>;
        return <p>No</p>;
      },
    },
    {
      headerName: ACTION,
      field: "id",
      filter: false,
      cellRenderer: (params) => (
        <div className="flex flex-row items-center space-x-5">
          <Link
            to={{
              pathname: "/advert/create",
            }}
            state={{
              selectedService: AdvertOperation.EDIT_ADVERT,
              selectedAdvert: params.data,
            }}
            className="py-0 bg-transparent rounded-full text-default"
          >
            <BiMessageSquareEdit size="25" />
          </Link>
          <button
            className="text-red-400 bg-transparent rounded-full"
            onClick={() =>
              openingModalElement(
                params.data.data.advertisement_id,
                params.data.data.attributes.company_name
              )
            }
          >
            <AiFillDelete size="25" />
          </button>
        </div>
      ),
    },
  ];

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDelletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDelletableFieldName("");
  };

  // onHandle toggle change selection
  const onHandleChangeSelection = (e) => {
    setToggleSelection(e.target.value);
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto bg-lightbg">
      {/* Advert Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">
            {toggleSelection === AdvertDisplayOption.ADVERT
              ? ADVERT_MANAGEMENT
              : toggleSelection === AdvertDisplayOption.ADVERT_TYPE
              ? ADVERT_TYPE_MANAGEMENT
              : null}
          </p>
          <p className="text-sm font-thin">
            {toggleSelection === AdvertDisplayOption.ADVERT
              ? ADVERT_MANAGEMENT_DESCRIPTION
              : toggleSelection === AdvertDisplayOption.ADVERT_TYPE
              ? ADVERT_TYPE_MANAGEMENT_DESCRIPTION
              : null}
          </p>
        </div>
        <select
          id="toggleSelection"
          name="toggleSelection"
          className="w-1/4 px-3 py-3 bg-transparent border rounded-lg text-textColor"
          onChange={(e) => onHandleChangeSelection(e)}
        >
          <option value={AdvertDisplayOption.ADVERT}>{ADVERT}</option>
          {/* <option value="AdvertDisplayOption.ADVERT_TYPE">Advert Type</option> */}
        </select>
      </div>

      {/* View Advert */}
      {toggleSelection === AdvertDisplayOption.ADVERT ? (
        <div
          className="w-full text-black placeholder-black bg-transparent border ag-theme-material"
          style={{ height: "80vh", color: "black" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            rowSelectionType={rowSelectionType}
            rowMultiSelectWithClick={true}
            pagination={true}
            className="text-left text-black placeholder-black bg-transparent"
          ></AgGridReact>
        </div>
      ) : (
        <ViewAdvertType />
      )}

      <ActionModal
        fromName={FROM_ADVERT}
        actionFromName={DELETE_FROM_ADVERT}
        isModalOpen={isModalOpen}
        onClose={closingModalElement}
        onConfirm={async () => {
          deleteAdvert(selectedDeletableField);
          closingModalElement();
        }}
        isProcessingDelete={isProcessingDelete}
        itemName={selectedDelletableFieldName}
      />
    </div>
  );
};

// Advert Right Side
const AdvertDashRightSide = ({ getAdvert }) => {
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto shadow-2xl bg-lightbg text-lightDefault ">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">Perform utility functions on advert</p>
        <p className="text-4xl font-bold text-default">Utility</p>
      </div>

      {/* Add Advert */}
      <Link
        to={{
          pathname: "/advert/create",
        }}
        state={{ selectedService: AdvertOperation.ADD_ADVERT }}
        className="flex flex-row items-start space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_ADVERT}</p>
          <p className="text-sm text-default">{ADD_ADVERT_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        {/* Each Notice */}
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getAdvert)}
            </p>
            <p className="text-sm text-default">{TOTAL_ADVERT_IN_STORE}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAdvert: (advertId) => dispatch(deleteAdvert(advertId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
