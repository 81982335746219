import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { RiAdvertisementLine } from "react-icons/ri";
import { AdvertTypeOperation } from "../../../enums/advert";
import { MoonLoader } from "react-spinners";

// Component imports
// import { instance } from "../../../config/api";
// import { resetLoader } from "../../../actions/AppAction";
// import { deleteTourCategory } from "../../../action/TourAction";

// image imports
// import EthiopiaFlag from "../../../../assets/images/ethiopia.svg";
// import NoData from "../../../../assets/images/NoData.gif";

class ViewAdvertType extends Component {
  // state
  state = {
    isFetching: false,
    isProcessingDelete: false,
    getTourCategory: [],
    isModelOpen: false,
    selectedDelletableFieldName: "",
    selectedDeletableField: "",
  };

  //   async componentDidMount() {
  //     this.setState({ isFetching: true });

  //     await instance
  //       .get("/tour-categories")
  //       .then((response) => {
  //         this.setState({ getTourCategory: response.data.tour_categories });
  //       })
  //       .catch((error) => console.log("Error::" + error));

  //     this.setState({ isFetching: false });
  //   }

  //   componentWillUnmount() {
  //     this.props.resetLoader();
  //   }

  render() {
    // variables
    const { isFetching } = this.state;
    const { deleteTourCategory } = this.props;

    // functions
    // opening modal
    // const openingModalElement = (selectedField, selectedFieldName) => {
    //   this.setState({
    //     isModelOpen: true,
    //     selectedDeletableField: selectedField,
    //     selectedDelletableFieldName: selectedFieldName,
    //   });
    // };

    // closing Modal
    const closingModalElement = () => {
      this.setState({
        isModelOpen: false,
        selectedDeletableField: null,
        selectedDelletableFieldName: "",
      });
    };

    // mapping categories
    // const mappingCategory =
    //   getTourCategory && getTourCategory.length > 0 ? (
    //     getTourCategory.map((SingleCategory) => (
    //       <div
    //         key={SingleCategory.id}
    //         className="flex flex-row w-1/3 px-4 py-3 mb-4 space-x-6 space-y-2 rounded-lg shadow-lg bg-lightbg"
    //       >
    //         <div className="flex flex-col items-center justify-center p-3 bg-passOverColor rounded-xl">
    //           {/* <img
    //             width="40"
    //             className="text-lightDefault"
    //             src={EthiopiaFlag}
    //             alt="Ethiopia Flag Icon"
    //           /> */}
    //         </div>
    //         <div className="flex flex-col justify-between text-textColor">
    //           <p className="font-bold">{SingleCategory.name}</p>
    //           <div className="flex flex-row space-x-5">
    //             <Link
    //               to={{
    //                 pathname: "/tour/category/create",
    //                 state: {
    //                   selectedService: "EDIT_CATEGORY",
    //                   selectedCategory: SingleCategory,
    //                 },
    //               }}
    //               className="text-sm text-gray-300 hover:text-lightDefault"
    //             >
    //               Edit
    //             </Link>
    //             <button
    //               className="text-sm text-gray-300 hover:text-lightDefault"
    //               onClick={() => {
    //                 openingModalElement(SingleCategory.id, SingleCategory.name);
    //               }}
    //             >
    //               Delete😌
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     ))
    //   ) : (
    //     <div className="flex flex-col items-center justify-center m-auto space-y-5">
    //       {/* <img src={NoData} alt={"Not found"} className="w-72" /> */}
    //       <p className="font-light text-textColor">
    //         No Category Found. Try to add one !
    //       </p>
    //       <Link
    //         to={{
    //           pathname: "/advert/create",
    //           state: { selectedService: "ADD_ADVERT_TYPE" },
    //         }}
    //         className="px-3 py-2 text-base font-thin bg-white border rounded-lg hover:bg-default hover:text-white"
    //         type="submit"
    //       >
    //         Add Advert Type
    //       </Link>
    //     </div>
    //   );

    return isFetching ? (
      <ScaleLoader className="flex flex-row items-center justify-center" />
    ) : (
      <div className="flex flex-row flex-wrap px-5 py-10">
        {/* {mappingCategory} */}
        <div className="flex flex-row w-1/3 px-4 py-3 mb-4 space-x-6 space-y-2 rounded-lg shadow-lg bg-lightbg">
          <div className="flex flex-col items-center justify-center p-3 bg-passOverColor rounded-xl">
            <RiAdvertisementLine size={40} className="text-white" />
            {/* <img
                width="40"
                className="text-lightDefault"
                src={EthiopiaFlag}
                alt="Ethiopia Flag Icon"
              /> */}
          </div>
          <div className="flex flex-col justify-between text-textColor">
            <p className="font-bold">25 Min Adverts</p>
            <div className="flex flex-row space-x-5">
              <Link
                to={{
                  pathname: "/tour/category/create",
                  state: {
                    selectedService: AdvertTypeOperation.EDIT_ADVERT_TYPE,
                    // selectedAdvert: SingleCategory,
                  },
                }}
                className="text-sm text-gray-300 hover:text-lightDefault"
              >
                Edit
              </Link>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  //   openingModalElement(SingleCategory.id, SingleCategory.name);
                }}
              >
                Delete😌
              </button>
            </div>
          </div>
        </div>
        <Modal
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          ariaLabelledBy="modal-headline"
          ariaHideApp={false}
          isOpen={this.state.isModelOpen}
          onRequestClose={() => closingModalElement()}
          contentLabel="Delete Modal"
          style={{
            content: {
              display: "inline-block",
              alignItems: "bottom",
              background: "white",
              borderRadius: "20px",
              textAlign: "left",
              overflow: "hidden",
              position: "fixed",
              left: "30%",
              right: "auto",
              bottom: "auto",
              top: "30vh",
            },
          }}
        >
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="w-6 h-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-headline"
                >
                  Deleting{" "}
                  <span className="font-bold">
                    {this.state.selectedDelletableFieldName}
                  </span>{" "}
                  from Advert Type
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete{" "}
                    <span className="font-bold">
                      {this.state.selectedDelletableFieldName}
                    </span>{" "}
                    from advert type? All advert data with this specific advert
                    type will be permanently removed. This action cannot be
                    undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            {this.state.isProcessingDelete ? (
              <MoonLoader size="50" color="#008fe7" />
            ) : (
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => {
                  this.setState({ isProcessingDelete: true });
                  await deleteTourCategory(this.state.selectedDeletableField);
                  this.setState({ isProcessingDelete: false });
                  closingModalElement();
                }}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => closingModalElement()}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ViewAdvertType;
