import React from "react";
import Logo from "../../../assets/images/Pharania Logo White.png";

const PrivacyPolicy = () => {
  return (
    <div>
      <nav className="flex flex-row justify-center py-5">
        <a href="/">
          <img src={Logo} className={"flex"} alt="Pharania Logo" width={50} />
        </a>
      </nav>
      <main className="flex flex-col justify-center items-center m-auto py-10 px-5 bg-default bg-opacity-25 text-white mt-10 w-1/2 rounded-xl">
        <h1 className="text-2xl font-bold">Politique de confidentialité</h1>

        <p className="mt-10 font-bold">Introduction</p>
        <p className="text-sm text-center">
          Cette politique de confidentialité s'applique à l'application/au site
          Web de lecture de bandes dessinées (l'« Application/le site Web »)
          développé et exploité par VP Trading PLC.
        </p>

        <p className="mt-5 font-bold text-lg">
          Informations que nous collectons
        </p>
        <p className="text-sm text-center mb-10">
          Nous collectons les informations suivantes lorsque vous créez un
          compte et utilisez l'application :
        </p>
        <ul className="flex flex-col space-y-3 text-center">
          <li>
            <strong>Informations sur le compte :</strong> lorsque vous créez un
            compte, vous devrez fournir un nom complet, une adresse e-mail, un
            mot de passe et une date de naissance.
          </li>
          <li>
            <strong>Informations sur l'appareil :</strong> nous collectons
            certaines informations sur l'appareil que vous utilisez pour accéder
            à l'application, telles que le type d'appareil, la version du
            système d'exploitation et l'identifiant unique de l'appareil.
          </li>
          <li>
            <strong>Informations d'utilisation :</strong> nous collectons des
            informations sur votre activité au sein de l'application, telles que
            les bandes dessinées que vous lisez, votre progression en lecture et
            les fonctionnalités que vous utilisez.
          </li>
        </ul>

        <p className="mt-5 font-bold text-lg">
          Comment nous utilisons vos informations
        </p>
        <p className="text-sm text-center mb-10">
          Nous utilisons les informations que nous collectons aux fins
          suivantes:
        </p>
        <ul className="flex flex-col space-y-3 text-center">
          <li>
            Pour fournir et exploiter l'Application, y compris pour créer et
            gérer votre compte, personnaliser votre expérience et vous permettre
            d'accéder et de lire des bandes dessinées.
          </li>
          <li>
            Pour vous envoyer des informations importantes sur l'application,
            telles que les communications du service client, les mises à jour et
            les notifications de sécurité.
          </li>
          <li>
            Analyser la façon dont vous utilisez l’Application pour améliorer
            nos services et développer de nouvelles fonctionnalités.
          </li>
          <li>Pour se conformer aux exigences légales et réglementaires.</li>
        </ul>

        <p className="mt-5 font-bold text-lg">Partage d'information</p>
        <p className="text-sm text-center mb-10">
          Nous ne partagerons pas vos informations personnelles avec des tiers
          sauf dans les cas suivants :
        </p>
        <ul className="flex flex-col space-y-3 text-center">
          <li>
            <strong>Fournisseurs de services :</strong> nous pouvons partager
            vos informations avec des fournisseurs de services tiers qui nous
            aident à exploiter l'application, tels que des fournisseurs de
            stockage cloud et des fournisseurs d'analyse de données. Ces
            prestataires de services sont tenus de protéger vos informations et
            de les utiliser uniquement aux fins que nous spécifions.
          </li>
          <li>
            <strong>Divulgations légalement requises :</strong> nous pouvons
            divulguer vos informations si nous y sommes tenus par la loi, par
            exemple en réponse à une ordonnance d'un tribunal ou à une
            assignation à comparaître.
          </li>
          <li>
            <strong>Transferts d'entreprise :</strong> en cas de transfert
            d'entreprise, tel qu'une fusion, une acquisition ou une faillite,
            nous pouvons transférer vos informations au nouveau propriétaire.
          </li>
        </ul>

        <p className="mt-5 font-bold text-lg">Sécurité des données</p>
        <p className="text-sm text-center">
          Nous prenons des mesures raisonnables pour protéger vos informations
          contre tout accès, divulgation, modification ou destruction non
          autorisés. Cependant, aucune transmission Internet ni stockage
          électronique n’est totalement sécurisé, nous ne pouvons donc pas
          garantir la sécurité de vos informations.
        </p>

        <p className="mt-5 font-bold text-lg">Confidentialité des enfants</p>
        <p className="text-sm text-center">
          L'application n'est pas destinée aux enfants de moins de 13 ans. Nous
          ne collectons pas sciemment d'informations personnelles auprès
          d'enfants de moins de 13 ans. Si vous êtes un parent ou un tuteur et
          que vous pensez que votre enfant nous a fourni des informations
          personnelles, veuillez nous contacter à support@ vptrading.et.
        </p>

        <p className="mt-5 font-bold text-lg">
          Modifications de cette politique de confidentialité
        </p>
        <p className="text-sm text-center">
          Nous pouvons mettre à jour cette politique de confidentialité de temps
          à autre. Nous publierons la politique de confidentialité mise à jour
          sur l'application et vous informerons de toute modification par
          e-mail. Nous vous encourageons à consulter régulièrement cette
          politique de confidentialité pour rester informé de tout changement.
        </p>

        <p className="mt-5 font-bold text-lg">Contactez-nous</p>
        <p className="text-sm text-center">
          Si vous avez des questions sur cette politique de confidentialité,
          veuillez nous contacter à support@vptrading.et
        </p>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
