// For formatting number
export const numberFormat = new Intl.NumberFormat();

// Removing HTML tags from descriptions
export const removeHTMLTag = (stringWithTag) => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = stringWithTag;
  return tmp.textContent || tmp.innerText || "";
};

// Ag grid setting options - Global
// Default col option for grid layout
export const defaultColDef = {
  filter: true,
  sortable: true,
  floatingFilter: true,
  resizable: true,
  scrollable: true,
};

// Row selection type
export const rowSelectionType = "single";
