// External imports
import { toast } from "react-toastify";

// Component imports
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  USER_SUCCESS,
  USER_FAILED,
  GUEST_USER_SUCCESS,
  GUEST_USER_FAILED,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
} from "../actions/Type";
import {
  LOGIN_SUCCESSFUL,
  LOGIN_FAILED as login_failed,
  LOGOUT_SUCCESSFUL,
  LOGOUT_FAILED as logout_failed,
} from "../config/app_strings";

const initialState = {
  isResetPasswordSuccessful: false,
 
  loginFailedError: null,
  isLoggedIn: null,
  isAdminSubmissionSuccessful: null,
  readerSubmissionError: null,
  isReaderSubmissionSuccessful: null,
  isForgotPasswordSuccessful: null,
  forgotPasswordErrorMessage: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      toast.success(LOGIN_SUCCESSFUL);
      return { ...state, isLoggedIn: true };
    case LOGIN_FAILED:
      toast.error(login_failed.LOGIN_FAILED + action.payload);
      return { ...state, isLoggedIn: false };
    case LOGOUT_SUCCESS:
      toast.success(LOGOUT_SUCCESSFUL);
      return { ...state, isLoggedIn: false };
    case LOGOUT_FAILED:
      toast.error(logout_failed.LOGOUT_FAILED, action.payload);
      return { ...state, isLoggedIn: true };
    case GUEST_LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true };
    case GUEST_LOGIN_FAILED:
      return { ...state, isLoggedIn: false, loginFailedError: action.payload };
    // User
    case GUEST_USER_SUCCESS:
      return { ...state, isReaderSubmissionSuccessful: true };
    case GUEST_USER_FAILED:
      return {
        ...state,
        isReaderSubmissionSuccessful: false,
        readerSubmissionError: action.payload,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, isForgotPasswordSuccessful: true };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPasswordSuccessful: false,
        forgotPasswordErrorMessage: action.payload,
      };

    case RESET_PASSWORD_SUCCESS:
      
      return { ...state, isResetPasswordSuccessful: true };
    case GUEST_LOGIN_FAILED:
      
      return { ...state, isResetPasswordSuccessful: false };

    case USER_SUCCESS:
      return { ...state, isAdminSubmissionSuccessful: true };
    case USER_FAILED:
      return { ...state, isAdminSubmissionSuccessful: false };
    default:
      return state;
  }
};

export default AuthReducer;
