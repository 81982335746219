import React, { Component } from "react";
import Slider from "react-slick";
import Rating from "react-rating";
import { BsStar, BsStarFill } from "react-icons/bs";
import Modal from "react-modal";
import clip from "text-clipper";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import { settings } from "../Config/CarouselConfig";
import { customStyles, customStylesForMobile } from "../Config/ModalConfig";
import ComicBook from "../Modal/ComicBook";
import { Navigate } from "react-router-dom";
import { instance } from "../../config/api";

export default class RootCarousel extends Component {
  // State
  state = {
    modalIsOpen: false,
    selectedComic: {},
    isLoading: false,
    isRedirected: false,
    hasNoDataFound: false,
    width: window.innerWidth,
    comicBooksList: [],
  };

  /* The `handleWindowSizeChange` function is an event handler that is called when the window is
  resized. It updates the `width` state variable with the current inner width of the window. This is
  useful for handling responsive design, as it allows the component to react to changes in the
  window size and adjust its rendering accordingly. */
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * This function retrieves data for a selected comic book based on its ID and sets the state
   * accordingly.
   */
  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);

    this.comicBooksToDisplay();
    this.openComicBookFromSharedLinkOrFromState();
  }

  /**
   * The componentWillUnmount function removes the event listener for the "resize" event.
   */
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  /* The `pickTopThreeComicAndShuffleTheReset` function is responsible for picking the top three comic
  books based on the number of views and shuffling the remaining comic books in the data array.
  Here's a breakdown of what the function does: */
  comicBooksToDisplay = () => {
    const comicData = this.props.data.data;
    const isNewlyReleased = this.props.isNewlyReleased;

    if (comicData == null) return;

    let comicBooksList;

    if (isNewlyReleased) {
      comicBooksList = comicData.sort(
        (a, b) =>
          new Date(b.data.attributes.created_at) -
          new Date(a.data.attributes.created_at)
      );
    } else {
      comicBooksList =
        comicData && comicData.length > 0
          ? this.shuffleArray([...comicData])
          : [];
    }

    this.setState({ comicBooksList });
  };

  /* The `openComicBookFromSharedLinkOrFromState` function is responsible for opening a modal and
  setting the selected comic book in the state. */
  openComicBookFromSharedLinkOrFromState = () => {
    const id = window.location.pathname.replace("/comic/", ""); /// getting the id of a shared comic book
    const selectedComic = this.props.selectedComicId;
    if (id !== "/") {
      this.setState({ isLoading: true, modalIsOpen: false }, async () => {
        try {
          const comicbook = await instance.get("comicBooks/" + id);
          this.setState({ modalIsOpen: true, selectedComic: comicbook.data });
        } catch (error) {
          this.setState({ hasNoDataFound: true });
        }
      });
      this.setState({ isLoading: false });
    } else if (selectedComic != null) {
      this.setState({ isLoading: true, modalIsOpen: false }, async () => {
        try {
          const comicbook = await instance.get("comicBooks/" + selectedComic);
          this.setState({ modalIsOpen: true, selectedComic: comicbook.data });
        } catch (error) {
          this.setState({ hasNoDataFound: true });
        }
      });
      this.setState({ isLoading: false });
    }
  };

  /* The `shuffleArray` function is a helper function that shuffles the elements in an array randomly.
  It takes an array `comics` as a parameter and uses the Fisher-Yates algorithm to shuffle the
  elements. The function iterates over the array from the last element to the first element and for
  each iteration, it generates a random index `j` between 0 and the current index `i`. It then swaps
  the elements at index `i` and `j` in the array. Finally, it returns the shuffled array. */
  shuffleArray = (comics) => {
    if (comics == null) return;

    for (let i = comics.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [comics[i], comics[j]] = [comics[j], comics[i]];
    }
    return comics;
  };

  render() {
    // Props
    const { header } = this.props;

    /**
     * This function closes a modal and updates the state of the component, including redirecting if
     * necessary.
     * @param e - The parameter "e" is an event object that is passed as an argument to the function.
     * It is used to handle the event that triggered the function, in this case, it is used to handle
     * the click event on the close button of a modal. The "stopPropagation" method is called on
     */
    const closeModal = (e) => {
      e.stopPropagation();
      this.setState({ modalIsOpen: false });
      const id = window.location.pathname.replace("/comic/", "");
      if (id !== "/") this.setState({ isRedirected: true });
    };

    /**
     * The function opens a modal and sets the selected comic in the state.
     * @param selectedComic - The selectedComic parameter is a variable that holds the data of the
     * comic that was selected by the user. It is passed as an argument to the openModal function. The
     * function then sets the state of the component to indicate that the modal is open and also sets
     * the selectedComic state variable to the value
     */
    const openModal = (selectedComic) => {
      this.setState({ modalIsOpen: true, selectedComic: selectedComic });
    };

    /// Mapping comic books from {data} source
    /* The `mappingComicBooks` constant is a variable that holds the result of mapping over the `data`
    array and creating a new array of JSX elements. It checks if `data` exists and has a `data`
    property with a length greater than 0 before mapping over it. If the condition is true, it maps
    over the `data` array and creates a new array of JSX elements. Each element is a `div` that
    contains an image, title, description, and rating of a comic book. The `onClick` event on each
    `div` element calls the `openModal` function with the corresponding comic book data as an
    argument. If the condition is false, it sets `mappingComicBooks` to `null`. */
    const mappingComicBooks =
      this.state.comicBooksList && this.state.comicBooksList.length > 0
        ? this.state.comicBooksList.map((comics) => (
            <div
              className="slide-card relative pr-3 transition duration-500 transform group hover:scale-110 rounded-sm"
              key={comics.data.comicbook_id}
              onClick={() => openModal(comics)}
            >
              <img
                src={comics.links.image}
                alt={comics.data.attributes.title}
                className="slide-image"
              />
              <div className="absolute left-0 right-3 bottom-3 opacity-0 fd-sh group-hover:opacity-100 bg-passOverColor transition duration-500 transform rounded-b-sm">
                <div>
                  <p className="px-3 pt-3 text-xs text-white font-bold">
                    {comics.data.attributes.title}
                  </p>
                  <p className="px-3 pt-1 text-xs text-white">
                    {clip(comics.data.attributes.description, 80)}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between px-3 pb-3">
                  {comics.data.attributes.no_of_views != null &&
                  comics.data.attributes.no_of_views !== 0 ? (
                    <p className="text-xs text-white">
                      {comics.data.attributes.no_of_views} Vues
                    </p>
                  ) : null}
                  <Rating
                    emptySymbol={<BsStar size={10} />}
                    fullSymbol={<BsStarFill size={10} />}
                    fractions={2}
                    initialRating={comics.data.attributes.average_rating}
                    readonly
                    className="text-white"
                  />
                </div>
              </div>
            </div>
          ))
        : null;
    if (this.state.isRedirected) return <Navigate to="/" />;

    if (this.state.hasNoDataFound) return <Navigate to="/404" />;

    return (
      <div className="my-5 px-10 w-screen" id="comic-book">
        <h3 className="my-4 mx-5 text-xl font-bold text-lightbg">{header}</h3>
        <Slider {...settings} className="general_slider">
          {mappingComicBooks}
        </Slider>

        {/* Modal */}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={(e) => closeModal(e)}
          style={this.state.width <= 768 ? customStylesForMobile : customStyles}
          ariaHideApp={false}
          contentLabel={`Comic Book Modal: `}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <ComicBook
            comicBook={this.state.selectedComic}
            onCloseButtonPressed={closeModal}
            onOpenButtonPressed={openModal}
          />
        </Modal>
      </div>
    );
  }
}
