import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import Slider from "react-slick";
import Modal from "react-modal";

// Component
import Nav from "../../../components/Nav/Nav";
import { instance } from "../../../config/api";
import {
  SEARCH_RESULT,
  SEARCH_RESULT_CHAPTER,
  SEARCH_RESULT_SUGGESTIONS,
  NO_RECOMMENDATION,
  GENRE,
  AUTHOR,
} from "../../../config/app_strings";
import { settings } from "../../../components/Config/CarouselConfig";
import { fullScreenModalStyle } from "../../../components/Config/ModalConfig";
import { customStyles } from "../../../components/Config/ModalConfig";
import ChapterReadingSection from "../../client/comic/Root";
import ComicBook from "../../../components/Modal/ComicBook";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../../components/Footer/footer";

export default function Root() {
  let location = useLocation();

  const [totalGenres, setTotalGenres] = useState([]);
  const [totalAuthors, setTotalAuthors] = useState([]);
  const [resultComicBookChapters, setResultComicBookChapters] = useState([]);
  const [selectedComicBook, setSelectedComicBook] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState({});
  const [selectedChapter, setSelectedChapter] = useState({});
  const [stateLoading, setStateLoading] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const [readingModalIsOpen, setReadingModalIsOpen] = useState(false);
  const [comicBookModalIsOpen, setComicBookModalIsOpen] = useState(false);

  useEffect(() => {
    if (location.state != null || location.state !== "") {
      setSelectedComicBook(location.state);
    }
    fetchComicBooks();
  }, [location.key]);

  /**
   * This function fetches data for a specific comic book and sets the state for genres, authors, and
   * comic book chapters.
   */
  async function fetchComicBooks() {
    setStateLoading(true);
    if (location.state != null || location.state !== "") {
      let comicBookId = location.state.data.comicbook_id;
      try {
        var [genres, authors, comicBookChapters] = await Promise.all([
          instance.get("genres"),
          instance.get("authors"),
          instance.get("comicBooks/" + comicBookId + "/chapters"),
        ]);
        setTotalGenres(genres.data);
        setTotalAuthors(authors.data);
        setResultComicBookChapters(comicBookChapters.data);
      } catch (error) {}
    } else {
      setIsRedirected(true);
    }
    setStateLoading(false);
  }

  /**
   * This function closes a reading modal and resets the selected chapter.
   * @param e - The parameter "e" is an event object that is passed as an argument to the function. It
   * is used to handle the event that triggered the function, in this case, the "onCloseReadingModal"
   * function. The event object contains information about the event, such as the type of event,
   */
  const onCloseReadingModal = (e) => {
    e.stopPropagation();
    setReadingModalIsOpen(false);
    setSelectedChapter({});
  };

  /**
   * This function sets the reading modal to open and selects a specific chapter.
   * @param e - The "e" parameter is an event object that is passed to the function as an argument. It
   * is typically used to access information about the event that triggered the function, such as the
   * target element or the type of event. In this case, it is used to prevent the event from
   * propagating further
   * @param selectedChapter - selectedChapter is a variable that holds the data of the chapter that was
   * selected by the user. It is passed as a parameter to the onOpenReadingModal function.
   */
  const onOpenReadingModal = (e, selectedChapter) => {
    e.stopPropagation();
    setReadingModalIsOpen(true);
    setSelectedChapter(selectedChapter);
  };

  /**
   * This function closes a comic book modal and stops event propagation.
   * @param e - The parameter "e" is an event object that is passed as an argument to the function. It
   * is used to handle the event that triggered the function, in this case, the "onCloseComicBookModal"
   * function. The "e.stopPropagation()" method is used to stop the event from propagating
   */
  const onCloseComicBookModal = (e) => {
    e.stopPropagation();
    setComicBookModalIsOpen(false);
  };

  /**
   * This function sets the comic book modal to open and selects a suggestion.
   * @param selectedSuggestionComic - The parameter `selectedSuggestionComic` is a variable that
   * represents the selected comic book suggestion. It is passed as an argument to the
   * `onOpenComicBookModal` function.
   */
  const onOpenComicBookModal = (selectedSuggestionComic) => {
    setComicBookModalIsOpen(true);
    setSelectedSuggestion(selectedSuggestionComic);
  };

  if (location.state == null || location.state === "" || isRedirected)
    return <Navigate to={"/404"} />;

  // Mapping
  /* `mappingComicBooks` is a variable that holds the JSX elements for the comic book chapters. It
  checks if `resultComicBookChapters.data` exists, is not undefined, and has a length greater than
  0. If it does, it maps through the `resultComicBookChapters.data` array and creates a div element
  for each chapter. The div element contains an image and the title of the chapter. It also sets an
  onClick event that triggers the `onOpenReadingModal` function with the selected chapter as a
  parameter. If `resultComicBookChapters.data` is not valid, it sets `mappingComicBooks` to null. */
  const mappingComicBooks =
    resultComicBookChapters.data &&
    typeof resultComicBookChapters.data != "undefined" &&
    resultComicBookChapters.data.length > 0
      ? resultComicBookChapters.data.map((comicsChapters) => (
          <div
            className="pr-3 cursor-pointer"
            key={comicsChapters.data.chapter_id}
            onClick={(e) => onOpenReadingModal(e, comicsChapters)}
          >
            <img
              src={selectedComicBook.links.image}
              alt={"Client Company Name"}
              className="w-full slide-image rounded-sm"
            />
            <p className="text-center text-white">
              {comicsChapters.data.attributes.title}
            </p>
          </div>
        ))
      : null;

  /* The above code is checking if there is a selected comic book and if it has recommendations. If
  there are recommendations, it maps through them and creates a div for each recommendation with an
  image and a click event that opens a modal for that recommendation. If the recommendation is the
  same as the selected comic book, it is not displayed. If there are no recommendations, it displays
  a message saying there are no recommendations. */
  const mappingRecommendedComicBooks =
    selectedComicBook &&
    selectedComicBook.data &&
    typeof selectedComicBook.data !== "undefined" &&
    selectedComicBook.data.recommendations.length > 0 ? (
      selectedComicBook.data.recommendations.map((comics) =>
        comics.id !== selectedComicBook.data.comicbook_id ? (
          <div
            className="cursor-pointer relative transition duration-500 transform group hover:scale-110 comic-book-thumbnail-card rounded-md"
            key={comics.id}
            onClick={(e) => {
              onOpenComicBookModal(comics);
            }}
          >
            <img
              src={comics.image.path}
              alt={"Client Company Name"}
              className="w-full h-full object-fill rounded-sm"
            />
          </div>
        ) : null
      )
    ) : (
      <div className="text-white px-20 text-center m-auto">
        {NO_RECOMMENDATION}
      </div>
    );

  /* The above code is checking if there is a selected comic book and if it has data with defined
  genres. If there are genres, it maps through them and creates a paragraph element with the genre
  name as text content. The resulting elements are stored in the `mappingGenre` variable. If there
  are no genres, `mappingGenre` is set to `null`. */
  const mappingGenre =
    selectedComicBook &&
    selectedComicBook.data &&
    typeof selectedComicBook.data !== "undefined" &&
    selectedComicBook.data.meta.genres.data.length > 0
      ? selectedComicBook.data.meta.genres.data.map((genre) => (
          <p
            className="bg-lightDefault text-white text-sm px-4 py-1 rounded-2xl"
            key={genre.data.id}
          >
            {genre.data.attributes.name}
          </p>
        ))
      : null;

  /* The above code is checking if there is a selectedComicBook object with a defined data property and
  if the length of the authors array in the data property is greater than 0. If these conditions are
  met, it maps through the authors array and returns a paragraph element for each author's name,
  with some styling. If the conditions are not met, it returns null. */
  const mappingAuthor =
    selectedComicBook &&
    selectedComicBook.data &&
    typeof selectedComicBook.data !== "undefined" &&
    selectedComicBook.data.meta.authors.data.length > 0
      ? selectedComicBook.data.meta.authors.data.map((author) => (
          <p
            className="bg-lightDefault text-white text-sm px-4 py-1 rounded-2xl"
            key={author.data.id}
          >
            {author.data.attributes.name}
          </p>
        ))
      : null;

  return !stateLoading ? (
    <div>
      <Nav from="search" genres={totalGenres} authors={totalAuthors} />
      <div>
        <div className="flex flex-col py-28 px-10 space-y-10">
          <div className="flex flex-row justify-between space-x-28">
            <div className="flex flex-col space-y-5 w-1/4">
              <h3 className="text-lg text-white">{SEARCH_RESULT}</h3>
              <img
                src={
                  typeof selectedComicBook.links !== "undefined"
                    ? selectedComicBook.links.image
                    : ""
                }
                alt={
                  typeof selectedComicBook.data !== "undefined"
                    ? selectedComicBook.data.attributes.title
                    : ""
                }
                className="slide-image"
              />
            </div>
            <div className="container flex flex-col space-y-5 w-3/4">
              <p className="text-white mt-14">
                {typeof selectedComicBook.data !== "undefined"
                  ? selectedComicBook.data.attributes.description
                  : ""}
              </p>
              <div className="flex flex-row space-x-32">
                <div className="flex flex-col space-y-2">
                  <h3 className="text-lg font-bold text-white">{GENRE}</h3>
                  <div className="flex flex-row space-x-2">{mappingGenre}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <h3 className="text-lg font-bold text-white">{AUTHOR}</h3>
                  <div className="flex flex-row space-x-2">{mappingAuthor}</div>
                </div>
              </div>
              <h3 className="text-lg text-white">{SEARCH_RESULT_CHAPTER}</h3>
              <Slider {...settings} className="chapter_slider">
                {mappingComicBooks}
              </Slider>
            </div>
          </div>
          <div className="flex flex-col space-y-5">
            <h3 className="text-lg text-white">{SEARCH_RESULT_SUGGESTIONS}</h3>
            <div className=" flex flex-row flex-wrap gap-x-5 gap-y-5">
              {mappingRecommendedComicBooks}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* Reading View Model */}
      <Modal
        isOpen={readingModalIsOpen}
        onRequestClose={(e) => onCloseReadingModal(e)}
        style={fullScreenModalStyle}
        ariaHideApp={false}
        contentLabel={`Chapter Modal: `}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <ChapterReadingSection
          selectedChapter={selectedChapter}
          onCloseModal={onCloseReadingModal}
          onParentClose={null}
          comicBookChapters={resultComicBookChapters}
          comicBook={selectedComicBook}
        />
      </Modal>

      {/* Comic Book Modal */}
      <Modal
        isOpen={comicBookModalIsOpen}
        onRequestClose={(e) => onCloseComicBookModal(e)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel={`Comic Book Modal: `}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <ComicBook
          onCloseButtonPressed={onCloseComicBookModal}
          onOpenButtonPressed={onOpenComicBookModal}
          fromSearch={true}
          selectedComicBookId={selectedSuggestion.id}
        />
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full min-h-screen">
      <GridLoader color="white" size="10px" className="m-auto" />
    </div>
  );
}
