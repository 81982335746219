import React, { Component } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import Select from "react-select";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";

//
import {
  validateEmptyString,
  validateYearLength,
} from "../Utility/Validation";
import { instance } from "../../config/api";
import { ComicOperation } from "../../enums/comic";
import {
  ADD_CHAPTER_NOTICE,
  AUTHOR_NAME,
  CHAPTER_TITLE,
  CHOOSE_OPTION,
  DESCRIPTION,
  DOES_BELONGS_TO_SERIES,
  GENRE,
  IMAGE,
  MISSING_FIELD,
  NOTE,
  NO_SERIES,
  PUBLICATION_YEAR,
  PUBLICATION_YEAR_WRONG_FORMAT,
  SERIES,
  SUBMIT,
  TAG,
  TITLE,
} from "../../config/app_strings";

export default class ManipulateComicForm extends Component {
  state = {
    comicName:
      this.props.selectedComic !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_COMIC
        ? this.props.selectedComic.attributes.title
        : "",
    description:
      this.props.selectedComic !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_COMIC
        ? this.props.selectedComic.attributes.description
        : "",
    authorName:
      this.props.selectedComic !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_COMIC
        ? this.props.selectedComic.attributes.author
        : [],
    genreId:
      this.props.selectedComic !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_COMIC
        ? this.props.selectedComic.attributes.genre
        : [],
    tagId:
      this.props.selectedComic !== undefined &&
      this.props.selectedService === ComicOperation.EDIT_COMIC
        ? this.props.selectedComic.attributes.tag
        : [],
    seriesId: "",
    profileImage: "",
    chapters: [{ title: "" }],

    isFieldLoading: false,
    doesItHaveASeries: false,
    getAuthors: [],
    getGenre: [],
    getTags: [],
    getSeries: [],
  };

  async componentDidMount() {
    this.setState({ isFieldLoading: true });
    try {
      var [series, authors, genres, tags] = await Promise.all([
        instance.get("series"),
        instance.get("authors", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        }),
        instance.get("genres", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        }),
        instance.get("tags", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        }),
      ]);

      var [processedAuthors, processedGenres, processedTags] =
        await Promise.all([
          this.processAuthors(authors),
          this.processGenres(genres),
          this.processTags(tags),
        ]);

      this.setState({
        getAuthors: processedAuthors,
        getGenre: processedGenres,
        getTags: processedTags,
      });

      if (
        series &&
        typeof series.data != "undefined" &&
        typeof series.data.data != "undefined" &&
        series.data.data.length > 0
      ) {
        this.setState({ getSeries: series.data.data });
      }
    } catch (error) {
    }

    this.setState({ isFieldLoading: false });
  }

  processGenres(response) {
    var fetchedGenre = [];
    response.data.data && response.data.data.length > 0
      ? response.data.data.map((SingleGenre) =>
          fetchedGenre.push({
            value: SingleGenre.data.genre_id,
            label: SingleGenre.data.attributes.name,
          })
        )
      : (fetchedGenre = []);
    return fetchedGenre;
  }

  processTags(response) {
    var fetchedTags = [];

    response.data.data && response.data.data.length > 0
      ? response.data.data.map((SingleAuthor) =>
          fetchedTags.push({
            value: SingleAuthor.data.tag_id,
            label: SingleAuthor.data.attributes.name,
          })
        )
      : (fetchedTags = []);
    return fetchedTags;
  }

  processAuthors(response) {
    var fetchedAuthors = [];

    response.data.data && response.data.data.length > 0
      ? response.data.data.map((SingleAuthor) =>
          fetchedAuthors.push({
            value: SingleAuthor.data.author_id,
            label: SingleAuthor.data.attributes.name,
          })
        )
      : (fetchedAuthors = []);
    return fetchedAuthors;
  }

  render() {
    // Get the data
    let {
      comicName,
      description,
      authorName,
      genreId,
      started_at,
      getAuthors,
      getGenre,
      isFieldLoading,
      profileImage,
      getTags,
      tagId,
      chapters,
      getSeries,
      doesItHaveASeries,
      seriesId,
    } = this.state;
    const {
      registerComic,
      selectedService,
      comicBookSubmissionStatus,
      isLoading,
    } = this.props;


    const resetForm=()=>{

      this.state.comicName="";
      this.state.description="";
      this.state.started_at="";
      this.state.profileImage="";
      this.state.SingleChapter="";
      
    }



    
    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onHandleFileInput = (index, e) => {
      const values = [...chapters];

      values[index][e.target.name] = e.target.value;
      this.setState({ chapters: values });
    };

    const onHandleAddDynamicField = (e) => {
      e.preventDefault();

      this.setState({ chapters: [...chapters, { title: "" }] });
    };

    const onHandleRemoveDynamicField = (index, e) => {
      const values = [...chapters];
      values.splice(index, 1);
      this.setState({ chapters: values });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      //   Get the data
      let formDataContent = new FormData();
      const image = document.querySelector("#profileImage");

      // Validation
      if (
        !validateEmptyString(comicName) ||
        !validateEmptyString(description) ||
        !validateEmptyString(profileImage) ||
        !validateEmptyString(authorName) ||
        !validateEmptyString(genreId) ||
        !validateEmptyString(tagId) ||
        !validateEmptyString(started_at)
      ) {
        return toast.warning(MISSING_FIELD);
      }
      if (!validateYearLength(started_at))
        return toast.warning(PUBLICATION_YEAR_WRONG_FORMAT);

      if (doesItHaveASeries && !validateEmptyString(seriesId))
        return toast.warning(MISSING_FIELD);

      // Appending content to form data.
      formDataContent.append("data[attributes][title]", comicName);
      formDataContent.append("data[attributes][description]", description);
      formDataContent.append("data[attributes][publication_year]", started_at);

      profileImage = image.files[0] !== undefined ? image.files[0] : null;

      if (profileImage !== null) {
        formDataContent.append("data[attributes][image]", profileImage);
      }
      if (doesItHaveASeries)
        formDataContent.append("data[attributes][series_id]", seriesId);

      for (var i = 0; i < genreId[0].length; i++)
        formDataContent.append("data[attributes][genres][]", genreId[0][i]);
      for (var j = 0; j < authorName[0].length; j++)
        formDataContent.append("data[attributes][authors][]", authorName[0][j]);
      for (var k = 0; k < tagId[0].length; k++)
        formDataContent.append("data[attributes][tags][]", tagId[0][k]);

      registerComic(formDataContent, chapters);
      
     resetForm();
    };

    // Methods
    const onHandleMultipleSelectChanges = (value, stateVariable) => {
      var selected = [];
      for (var option of value) {
        selected.push(option.value);
      }
      this.setState({ [stateVariable]: [selected] });
    };

    const mappingSeriesToSelect = isFieldLoading ? (
      <option value="">Loading . . .</option>
    ) : getSeries && getSeries.length > 0 ? (
      getSeries.map((SingleSeries) => (
        <option
          key={SingleSeries.data.series_id}
          value={SingleSeries.data.series_id}
          className="flex flex-row justify-between"
        >
          {SingleSeries.data.attributes.title}
        </option>
      ))
    ) : (
      NO_SERIES
    );

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {TITLE}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="comicName"
              id="comicName"
              placeholder={TITLE}
              value={comicName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {DESCRIPTION}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              placeholder={DESCRIPTION}
              value={description}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          {selectedService === ComicOperation.ADD_COMIC ? (
            <>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {AUTHOR_NAME}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="authorName"
                  id="authorName"
                  placeholder={AUTHOR_NAME}
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getAuthors}
                  noOptionsMessage={() => "No Author Found"}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "authorName")
                  }
                  classNamePrefix="select py-3"
                  className="placeholder-gray-300 rounded-lg basic-multi-select text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {GENRE}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="genreId"
                  id="genreId"
                  placeholder={GENRE}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "genreId")
                  }
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getGenre}
                  noOptionsMessage={() => "No Genre Found"}
                  classNamePrefix="select py-3"
                  className="placeholder-gray-300 rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {TAG}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <Select
                  name="tagId"
                  id="tagId"
                  placeholder={TAG}
                  onChange={(value) =>
                    onHandleMultipleSelectChanges(value, "tagId")
                  }
                  isClearable={true}
                  isLoading={isFieldLoading}
                  isMulti
                  options={getTags}
                  classNamePrefix="select py-3"
                  noOptionsMessage={() => "No Tag Found"}
                  className="placeholder-gray-300 rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {PUBLICATION_YEAR}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="started_at"
                  id="started_at"
                  placeholder={PUBLICATION_YEAR}
                  value={started_at}
                  onChange={onHandleChangeInField}
                  className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-light text-textColor">
                  {IMAGE}
                  <span className="mx-1 text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name="profileImage"
                  id="profileImage"
                  placeholder={IMAGE}
                  value={profileImage}
                  onChange={onHandleChangeInField}
                  className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                />
              </div>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-radio"
                  name="doesItHaveASeries"
                  value={comicBookSubmissionStatus ? false : doesItHaveASeries}
                  onChange={(e) =>
                    this.setState({ doesItHaveASeries: e.target.checked })
                  }
                />
                <span className="ml-2">{DOES_BELONGS_TO_SERIES}</span>
              </label>
              {doesItHaveASeries ? (
                <div className="flex flex-col space-y-3">
                  <label className="font-light text-textColor">
                    {SERIES}
                    <span className="mx-1 text-red-500">*</span>
                  </label>
                  <select
                    type="file"
                    name="seriesId"
                    id="seriesId"
                    placeholder="Series"
                    value={seriesId}
                    onChange={onHandleChangeInField}
                    className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                  >
                    <option value="">{CHOOSE_OPTION}</option>
                    {mappingSeriesToSelect}
                  </select>
                </div>
              ) : null}
              <hr />
              <p className="text-sm italic text-gray-400">
                <span className="font-extrabold">{NOTE}:</span>{" "}
                {ADD_CHAPTER_NOTICE}
              </p>
              {chapters.map((SingleChapter, index) => (
                <div
                  className="flex flex-row items-end justify-between space-x-5"
                  key={index}
                >
                  <div className="flex flex-col w-full space-y-3">
                    <label className="font-light text-textColor">
                      {CHAPTER_TITLE}
                      <span className="mx-1 text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder={CHAPTER_TITLE}
                      value={SingleChapter.title}
                      onChange={(e) => onHandleFileInput(index, e)}
                      className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                      required
                    />
                  </div>
                  <div className="flex flex-row space-x-8">
                    <button
                      className="p-2 rounded-lg bg-default"
                      onClick={(e) => onHandleAddDynamicField(e)}
                    >
                      <HiPlusSm size="30px" color="#FFF" />
                    </button>
                    <button
                      className="p-2 rounded-lg bg-default"
                      onClick={(e) => {
                        e.preventDefault();
                        if (chapters.length > 1) onHandleRemoveDynamicField(e);
                      }}
                    >
                      <HiMinusSm size="30px" color="#FFF" />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          )}

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}
