/**
 * Supported user operation.
 * @enum {string}
 */
 export const UserOperation = Object.freeze({
    EDIT_PROFILE: 'edit_profile',
    CHANGE_PASSWORD: 'change_password',
    ADD_ADMIN: 'add_admin',
});

/**
 * Supported user display option.
 * @enum {string}
 */
export const UserDisplayOperation = Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
});