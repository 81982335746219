import React from "react";

// External import
import { NavLink } from "react-router-dom";
import { AiOutlineDashboard, AiOutlineUser } from "react-icons/ai";
import { BiBookOpen } from "react-icons/bi";
import { RiAdvertisementLine } from "react-icons/ri";
import { FaSignature, FaStar } from "react-icons/fa";
import { HiOutlineSupport } from "react-icons/hi";

// Image imports
import Logo from "../../assets/images/Pharania Logo Color.png";
import LogoText from "../../assets/images/Pharania Logo Text.png";
import {
  ADVERTISEMENT,
  AUTHOR,
  AUTHOR_RANKING,
  COMIC_BOOK,
  DASHBOARD,
  SUPPLEMENTS,
  USERS,
} from "../../config/app_strings";

export default function SideNav() {
  return (
    <div className="z-10 flex flex-col flex-shrink-0 w-full text-gray-700 bg-white shadow-2xl md:w-64 md:pt-10 rounded-r-3xl">
      <div className="flex flex-row items-center justify-between flex-shrink-0 px-8 py-4">
        <a
          href="/"
          className="flex flex-col items-center justify-center space-y-5 text-lg font-semibold tracking-widest text-center uppercase text-textColor rouned-lg focus:outline-none focus:shadow-outline"
        >
          <img src={Logo} alt="" className="w-1/2" />
          <img src={LogoText} alt="" />
        </a>
        <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline">
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
            <path
              x-show="!open"
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
            <path
              x-show="open"
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <nav className="flex-grow pb-4 pl-4 space-y-5 md:block md:my-10 md:pb-0 md:overflow-y-auto">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/dashboard"
        >
          <AiOutlineDashboard className="opacity-50 text-textColor" size="25" />
          <span>{DASHBOARD}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/comics"
        >
          <BiBookOpen className="opacity-50 text-textColor" size="25" />
          <span>{COMIC_BOOK}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/author"
        >
          <FaSignature className="opacity-50 text-textColor" size="25" />
          <span>{AUTHOR}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/supplements"
        >
          <HiOutlineSupport className="opacity-50 text-textColor" size="25" />
          <span>{SUPPLEMENTS}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/users"
        >
          <AiOutlineUser className="opacity-50 text-textColor" size="25" />
          <span>{USERS}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/advert"
        >
          <RiAdvertisementLine
            className="opacity-50 text-textColor"
            size="25"
          />

          <span>{ADVERTISEMENT}</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg font-bold text-default border-r-4 border-default"
              : "flex flex-row items-center px-4 py-2 mt-2 space-x-5 text-lg text-textColor focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          }
          to="/author-ranking"
        >
          <FaStar className="opacity-50 text-textColor" size="25" />

          <span>{AUTHOR_RANKING}</span>
        </NavLink>
      </nav>
    </div>
  );
}
