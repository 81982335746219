import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import Modal from "react-modal";
import { connect } from "react-redux";

// Component
import { deleteSeries } from "../../../actions/ComicAction";

// Images
import NoData from "../../../assets/images/no-data.gif";
import { isUserStillLoggedIn } from "../../../actions/AuthAction";
import { ComicOperation } from "../../../enums/comic";
import { CANCEL, DELETE, EDIT, NO_SERIES } from "../../../config/app_strings";
import { MoonLoader } from "react-spinners";

function ViewSeries({ data, deleteSeries }) {
  // state
  const [isModelOpen, setModalOpen] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [selectedDeletableFieldName, setSelectedDeletableFieldName] =
    useState("");
  const [selectedDeletableField, setSelectedDeletableField] = useState("");

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDeletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDeletableFieldName("");
  };

  // Mapping
  const mappingSeriesTag = (seriesTags) => {
    return seriesTags.data && seriesTags.data.length > 0
      ? seriesTags.data.map((SingleTag, i) =>
          i + 1 === seriesTags.data.length ? (
            <span className="text-xs font-bold text-gray-300 ">{`#${SingleTag.data.attributes.name}`}</span>
          ) : (
            <span className="text-xs font-bold text-gray-300 ">{`#${SingleTag.data.attributes.name}, `}</span>
          )
        )
      : null;
  };

  const mappingSeriesGenre = (seriesGenre) => {
    return seriesGenre.data && seriesGenre.data.length > 0
      ? seriesGenre.data.map((SingleGenre, i) =>
          i + 1 === seriesGenre.data.length ? (
            <span className="text-xs font-bold text-gray-300 ">{`${SingleGenre.data.attributes.name}`}</span>
          ) : (
            <span className="text-xs font-bold text-gray-300 ">{`${SingleGenre.data.attributes.name}, `}</span>
          )
        )
      : null;
  };

  const mappingSeries =
    data && data.length > 0 ? (
      data.map((data) => (
        <div
          className="flex flex-row items-center w-1/3 px-4 py-3 mb-4 space-x-4 space-y-2 rounded-lg shadow-lg bg-lightbg"
          key={data.data.series_id}
        >
          <img
            src={`${data.links.image}`}
            alt={data.data.attributes.name}
            className="w-1/3 rounded-lg"
          />
          <div className="flex flex-col flex-wrap justify-between w-3/4 text-textColor">
            <p className="w-5/6 text-lg font-bold truncate">
              {data.data.attributes.title}
            </p>
            <div className="w-5/6 truncate">
              {mappingSeriesGenre(data.data.meta.genres)}
            </div>
            <div className="w-5/6 mb-2 truncate">
              {mappingSeriesTag(data.data.meta.tags)}
            </div>
            <div className="flex flex-row space-x-5">
              <Link
                to={{
                  pathname: "/series/create",
                  
                }}
                state={{
                  selectedService: ComicOperation.EDIT_SERIES,
                  selectedSeries: data.data,
                }}
                className="text-sm text-gray-300 hover:text-lightDefault"
              >
                {EDIT}
              </Link>
              <button
                className="text-sm text-gray-300 hover:text-lightDefault"
                onClick={() => {
                  openingModalElement(
                    data.data.series_id,
                    data.data.attributes.title
                  );
                }}
              >
                {DELETE}
              </button>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="flex flex-col m-auto mt-10 text-center text-muted">
        <img
          src={NoData}
          alt="Pharania - No data animation"
          className="w-2/3 m-auto"
        />
        <p>{NO_SERIES}</p>
      </p>
    );

  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div className="flex flex-row flex-wrap w-full px-5 text-black placeholder-black bg-transparent">
      {mappingSeries}
      <Modal
        className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        ariaLabelledBy="modal-headline"
        ariaHideApp={false}
        isOpen={isModelOpen}
        onRequestClose={() => closingModalElement()}
        contentLabel="Delete Modal"
        style={{
          content: {
            display: "inline-block",
            alignItems: "bottom",
            background: "white",
            borderRadius: "20px",
            textAlign: "left",
            overflow: "hidden",
            position: "fixed",
            left: "30%",
            right: "auto",
            bottom: "auto",
            top: "30vh",
          },
        }}
      >
        <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="w-6 h-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                id="modal-headline"
              >
                Deleting{" "}
                <span className="font-bold">{selectedDeletableFieldName}</span>{" "}
                from Series
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete{" "}
                  <span className="font-bold">
                    {selectedDeletableFieldName}
                  </span>{" "}
                  from series? All comic book data with this specific series
                  will be permanently removed. This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          {isProcessingDelete ? (
            <MoonLoader size="50" color="#008fe7" />
          ) : (
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={async () => {
                setIsProcessingDelete(true);
                await deleteSeries(selectedDeletableField);
                setIsProcessingDelete(false);
                closingModalElement();
              }}
            >
              {DELETE}
            </button>
          )}
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => closingModalElement()}
          >
            {CANCEL}
          </button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSeries: (seriesId) => dispatch(deleteSeries(seriesId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSeries);
