import React from "react";
import { Helmet } from "react-helmet-async";
import { PHARANIA_COMIC_BOOK_ENGLISH } from "../../config/app_strings";

const PageHelmet = () => {
  return (
    <Helmet>
      <title>{PHARANIA_COMIC_BOOK_ENGLISH}</title>
      <meta
        name="description"
        content="Pharania is a platform whose purpose is to broadcast comic book and cartoons online. The platform is open to any comic book artists who wish to make it as a work and give them the opportunity to earn a living from their art"
      />
      <meta
        name="keywords"
        content="comic books, pharania, pharania.com, story, comic story,"
      />
      <meta name="author" content="VP Trading" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
};

export default PageHelmet;
