import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  OPERATION_SUCCESS,
  OPERATION_FAILED,
  USER_SUCCESS,
  USER_START_LOADER,
  USER_STOP_LOADER,
  START_LOADER,
  STOP_LOADER,
  GUEST_READER_STOP_LOADER,
  GUEST_READER_START_LOADER,
  GUEST_USER_SUCCESS,
  USER_FAILED,
  GUEST_USER_FAILED,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_FAILED,
  RESET_PASSWORD_START_LOADER,
  RESET_PASSWORD_STOP_LOADER,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  FORGOT_PASSWORD_START_LOADER,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_STOP_LOADER,
} from "./Type";
import { instance, CSRFInstance } from "../config/api";
import { startLoader, stopLoader } from "./AppAction";
import CryptoJS from "crypto-js";

export const login = (email, password) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          email: email,
          password: password,
        },
      },
    };

    dispatch(startLoader(START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/login", content)
        .then((res) => {
          if (res.status === 200) {
            instance
              .get("/user", {
                headers: {
                  Authorization: `Bearer ${res.data.data.attributes.access_token}`,
                },
              })
              .then((user) => {
                if (user.status === 200) {
                  if (user.data.role_id != 1) {
                    dispatch({
                      type: LOGIN_FAILED,
                      payload:
                        "The accound provided doesnot belong to an admin",
                    });
                    dispatch(stopLoader(STOP_LOADER));
                  } else {
                    setValueToLocalStorage(
                      res.data.data.attributes.access_token,
                      user.data
                    );

                    dispatch({ type: LOGIN_SUCCESS });
                    dispatch(stopLoader(STOP_LOADER));
                  }
                } else {
                  dispatch({ type: LOGIN_FAILED, payload: res.data.message });
                  dispatch(stopLoader(STOP_LOADER));
                }
              });
          } else {
            dispatch({ type: LOGIN_FAILED, payload: res.data.message });
            dispatch(stopLoader(STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: LOGIN_FAILED, payload: err });
          dispatch(stopLoader(STOP_LOADER));
        });
    });
  };
};
export const guestLogin = (email, password) => {
  return (dispatch) => {
    // Input
    const content = {
      data: {
        attributes: {
          email: email,
          password: password,
        },
      },
    };

    dispatch(startLoader(START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/login", content)
        .then((res) => {
          if (res.status === 200) {
            instance
              .get("/user", {
                headers: {
                  Authorization: `Bearer ${res.data.data.attributes.access_token}`,
                },
              })
              .then((user) => {
                if (user.status === 200) {
                  setValueToLocalStorage(
                    res.data.data.attributes.access_token,
                    user.data
                  );

                  dispatch({ type: GUEST_LOGIN_SUCCESS });
                  dispatch(stopLoader(STOP_LOADER));
                } else {
                  dispatch({
                    type: GUEST_LOGIN_FAILED,
                    payload: res.data.message,
                  });
                  dispatch(stopLoader(STOP_LOADER));
                }
              });
          } else {
            dispatch({ type: GUEST_LOGIN_FAILED, payload: res.data.message });
            dispatch(stopLoader(STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({ type: GUEST_LOGIN_FAILED, payload: err });
          dispatch(stopLoader(STOP_LOADER));
        });
    });
  };
};

export const createUser = (content) => {
  return (dispatch) => {
    dispatch(startLoader(USER_START_LOADER));

    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/register", content, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token")
              )}`,
            },
          })
          .then((response) => {
            dispatch({ type: USER_SUCCESS });
          })
          .catch((error) =>
            dispatch({
              type: USER_FAILED,
              payload: error.response.data.message,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: USER_FAILED,
          payload: error.response.data.message,
        })
      );

    dispatch(stopLoader(USER_STOP_LOADER));
  };
};

export const forgotPassword = (content) => {
  return (dispatch) => {
    dispatch(startLoader(FORGOT_PASSWORD_START_LOADER));
    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/forgot-password", content, {
            headers: {},
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch({ type: FORGOT_PASSWORD_SUCCESS });
              dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
            } else if (response.status === 422) {
              dispatch({
                type: FORGOT_PASSWORD_FAILED,
                payload: response.data.message,
              });
            } else {
              dispatch({
                type: FORGOT_PASSWORD_FAILED,
                payload: response.data.message,
              });
            }
          })
          .catch((error) => {
            const { message } = error;

            dispatch({
              type: FORGOT_PASSWORD_FAILED,
              payload: message,
            });
          })
          .finally(() => {
            dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
          });
      })
      .catch((error) => {
        const { message } = error;
        dispatch({
          type: FORGOT_PASSWORD_FAILED,
          payload: message,
        });
        dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
      });
  };
};

export const createReader = (content) => {
  return (dispatch) => {
    dispatch(startLoader(GUEST_READER_START_LOADER));

    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/register", content, {})
          .then((response) => {
            dispatch(stopLoader(GUEST_READER_STOP_LOADER));

            if (response.status === 422) {
              dispatch({
                type: GUEST_USER_FAILED,
                payload: "failed man",
              });
            } else {
              dispatch({ type: GUEST_USER_SUCCESS });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(GUEST_READER_STOP_LOADER));
            dispatch({
              type: GUEST_USER_FAILED,
              payload: error.response.data.message,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: GUEST_USER_FAILED,
          payload: error.response.data.message,
        });

        dispatch({
          type: GUEST_USER_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

export const resetPassword = (content) => {
  return (dispatch) => {
    dispatch(startLoader(START_LOADER));

    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/renew-password", content, {
            headers: {},
          })
          .then((response) => {
            dispatch({ type: RESET_PASSWORD_SUCCESS });
          })
          .catch((error) => {
            dispatch({
              type: RESET_PASSWORD_FAILED,
              payload: error.response.data.message,
            });
          })
          .finally(() => {
            dispatch(stopLoader(STOP_LOADER));
          });
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: error.response.data.message,
        });
        dispatch(stopLoader(STOP_LOADER));
      });
  };
};

export const editProfile = (content) => {
  return (dispatch) => {
    dispatch(startLoader(START_LOADER));

    CSRFInstance.get("")
      .then(() => {
        instance
          .patch("/update-profile", content, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token")
              )}`,
            },
          })
          .then((response) => {
            dispatch({ type: OPERATION_SUCCESS });
          })
          .catch((error) =>
            dispatch({
              type: OPERATION_FAILED,
              payload: error.response.data.message,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: OPERATION_FAILED,
          payload: error.response.data.message,
        })
      );

    dispatch(stopLoader(STOP_LOADER));
  };
};

/// Helper function
export const encryptAndDecryptLocalStorage = (value, isEncryption) => {
  let secretKey = "Ym2bp6xPKN";

  if (value === "" || value === null) return;

  if (isEncryption) {
    let encryptedValue = CryptoJS.AES.encrypt(value, secretKey);

    return encryptedValue;
  }

  let decryptedByte = CryptoJS.AES.decrypt(value, secretKey);
  let decryptedData = decryptedByte.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

// Setting to local storage
const setValueToLocalStorage = (accessToken, userData) => {
  // Setting user access token
  localStorage.setItem(
    "login_user_token",
    encryptAndDecryptLocalStorage(accessToken, true)
  );
  localStorage.setItem("login_user_name", userData.name);
  localStorage.setItem("login_role_id", userData.role_id);
};

// Check for login information
export const isUserStillLoggedIn = () => {
  let access_token = localStorage.getItem("login_user_token");

  if (
    access_token !== null &&
    access_token.length > 0 &&
    localStorage.getItem("login_role_id") === "1"
  )
    return true;

  return false;
};

export const isGuestUserStillLoggedIn = () => {
  let access_token = localStorage.getItem("login_user_token");

  if (
    (access_token !== null &&
      access_token.length > 0 &&
      localStorage.getItem("login_role_id") === "2") ||
    localStorage.getItem("login_role_id") === "3"
  )
    return true;

  return false;
};
