import { useEffect } from "react";

const PreventContextMenu = ({ children }) => {
  /// Used for disabling the context menu/right-click functionality on the pages
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  /// Used for preventing the user to take screenshot using
  window.addEventListener('keydown', (event) => {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault(); // Attempt to prevent default behavior
    }
  });

  return children;
};

export default PreventContextMenu;
