/**
 * Supported author operations.
 * @enum {string}
 */
 export const AuthorOperation = Object.freeze({
    ADD_AUTHOR: 'add_author',
    EDIT_AUTHOR: 'edit_author',
    ADD_AUTHOR_CREDENTIAL: "add_author_credential"
});

/**
 * Supported author display option.
 * @enum {string}
 */
 export const AuthorDisplayOption = Object.freeze({
    AUTHOR: 'author',
});