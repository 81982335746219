import {
  OPERATION_SUCCESS,
  OPERATION_FAILED,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAILED,
  EDIT_SERIES_SUCCESS,
  EDIT_SERIES_FAILED,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_FAILED,
  DELETE_COMIC_BOOK_SUCCESS,
  DELETE_COMIC_BOOK_FAILED,
  ADD_COMIC_BOOK_SUCCESS,
  ADD_COMIC_BOOK_FAILED,
  SERIES_START_LOADER,
  SERIES_STOP_LOADER,
  COMIC_BOOK_START_LOADER,
  COMIC_BOOK_STOP_LOADER,
  CHAPTER_START_LOADER,
  CHAPTER_STOP_LOADER,
  PAGE_START_LOADER,
  PAGE_STOP_LOADER,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_FAILED,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_FAILED,
  ADD_CHAPTER_SUCCESS,
  ADD_CHAPTER_FAILED,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAILED,
} from "./Type";
import { instance, CSRFInstance } from "../config/api";
import { startLoader, stopLoader } from "./AppAction";
import { encryptAndDecryptLocalStorage } from "./AuthAction";

// Series
export const registerSeries = (content) => {
  return (dispatch) => {
    dispatch(startLoader(SERIES_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/series", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: ADD_SERIES_SUCCESS });
            dispatch(stopLoader(SERIES_STOP_LOADER));
          } else {
            dispatch({ type: ADD_SERIES_FAILED, payload: res.data.message });
            dispatch(stopLoader(SERIES_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_SERIES_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(SERIES_STOP_LOADER));
        });
    });
  };
};

export const updateSeries = (content, seriesId) => {
  return (dispatch) => {
    dispatch(startLoader(SERIES_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/series/" + seriesId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_SERIES_SUCCESS });
            dispatch(stopLoader(SERIES_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_SERIES_FAILED, payload: res.data.message });
            dispatch(stopLoader(SERIES_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_SERIES_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(SERIES_STOP_LOADER));
        });
    });
  };
};

export const deleteSeries = (seriesId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(SERIES_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("series/" + seriesId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(SERIES_STOP_LOADER));
              dispatch({ type: DELETE_SERIES_SUCCESS });
            } else {
              dispatch(stopLoader(SERIES_STOP_LOADER));
              dispatch({
                type: DELETE_SERIES_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(SERIES_STOP_LOADER));
            dispatch({
              type: DELETE_SERIES_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(SERIES_STOP_LOADER));
        dispatch({
          type: DELETE_SERIES_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

// Comic Book
export const registerComic = (content, chapters) => {
  return (dispatch) => {
    dispatch(startLoader(COMIC_BOOK_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/comicBooks", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            dispatch(
              registerChapter(chapters, res.data.data.comicbook_id, true)
            );
          } else {
            dispatch({
              type: ADD_COMIC_BOOK_FAILED,
              payload: res.data.message,
            });
            dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_COMIC_BOOK_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
        });
    });
  };
};

export const deleteComic = (comicId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(COMIC_BOOK_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("comicBooks/" + comicId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
              dispatch({ type: DELETE_COMIC_BOOK_SUCCESS });
            } else {
              dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
              dispatch({
                type: DELETE_COMIC_BOOK_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
            dispatch({
              type: DELETE_COMIC_BOOK_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
        dispatch({
          type: DELETE_COMIC_BOOK_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

// Chapter
export const registerChapter = (chapters, comicId, fromComic) => {
  return (dispatch) => {
    dispatch(startLoader(CHAPTER_START_LOADER));

    // Input
    const content = {
      data: {
        type: chapters,
        attributes: {
          chapters: chapters,
        },
      },
    };

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/comicBooks/" + comicId + "/chapters", content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            fromComic
              ? dispatch({ type: ADD_COMIC_BOOK_SUCCESS })
              : dispatch({ type: ADD_CHAPTER_SUCCESS });
            dispatch(stopLoader(CHAPTER_STOP_LOADER));
            dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
          } else {
            fromComic
              ? dispatch({
                  type: ADD_COMIC_BOOK_FAILED,
                  payload: res.data.message,
                })
              : dispatch({
                  type: ADD_CHAPTER_FAILED,
                  payload: res.data.message,
                });
            dispatch(stopLoader(CHAPTER_STOP_LOADER));
            dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
          }
        })
        .catch((err) => {
          fromComic
            ? dispatch({
                type: ADD_COMIC_BOOK_FAILED,
                payload: err.response.data.message,
              })
            : dispatch({
                type: ADD_CHAPTER_FAILED,
                payload: err.response.data.message,
              });
          dispatch(stopLoader(CHAPTER_STOP_LOADER));
          dispatch(stopLoader(COMIC_BOOK_STOP_LOADER));
        });
    });
  };
};

export const updateChapter = (content, chapterId) => {
  return (dispatch) => {
    dispatch(startLoader(CHAPTER_START_LOADER));

    

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .patch("/chapters/" + chapterId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: OPERATION_SUCCESS });
            dispatch(stopLoader(CHAPTER_STOP_LOADER));
          } else {
            dispatch({ type: OPERATION_FAILED, payload: res.data.message });
            dispatch(stopLoader(CHAPTER_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: OPERATION_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(CHAPTER_STOP_LOADER));
        });
    });
  };
};
export const deleteChapter = (chapterId) => {
  return (dispatch) => {
    dispatch(startLoader(CHAPTER_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("/chapters/" + chapterId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(CHAPTER_STOP_LOADER));
              dispatch({ type: DELETE_CHAPTER_SUCCESS });
            } else {
              dispatch(stopLoader(CHAPTER_STOP_LOADER));
              dispatch({
                type: DELETE_CHAPTER_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(CHAPTER_STOP_LOADER));
            dispatch({
              type: DELETE_CHAPTER_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(PAGE_STOP_LOADER));
        dispatch({
          type: DELETE_CHAPTER_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

// Pages
export const registerPages = (chapterId, pages) => {
  return (dispatch) => {
    dispatch(startLoader(PAGE_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/chapters/" + chapterId + "/pages", pages, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: ADD_PAGE_SUCCESS });
            dispatch(stopLoader(PAGE_STOP_LOADER));
          } else {
            dispatch({ type: ADD_PAGE_FAILED, payload: res.data.message });
            dispatch(stopLoader(PAGE_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_PAGE_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(PAGE_STOP_LOADER));
        });
    });
  };
};

export const updatePages = (content, pageId) => {
  return (dispatch) => {
    dispatch(startLoader(PAGE_START_LOADER));
    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/pages/" + pageId, content, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: OPERATION_SUCCESS });
            dispatch(stopLoader(PAGE_STOP_LOADER));
          } else {
            dispatch({ type: OPERATION_FAILED, payload: res.data.message });
            dispatch(stopLoader(PAGE_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: OPERATION_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(PAGE_STOP_LOADER));
        });
    });
  };
};

export const deletePage = (pageId) => {
  return (dispatch) => {
    // loader function
    dispatch(startLoader(PAGE_START_LOADER));

    // operation
    CSRFInstance.get("")
      .then(() =>
        instance
          .delete("pages/" + pageId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch(stopLoader(PAGE_STOP_LOADER));
              dispatch({ type: DELETE_PAGE_SUCCESS });
            } else {
              dispatch(stopLoader(PAGE_STOP_LOADER));
              dispatch({
                type: DELETE_PAGE_FAILED,
                payload: response.data,
              });
            }
          })
          .catch((error) => {
            dispatch(stopLoader(PAGE_STOP_LOADER));
            dispatch({
              type: DELETE_PAGE_FAILED,
              payload: error.response.data.message,
            });
          })
      )
      .catch((error) => {
        dispatch(stopLoader(PAGE_STOP_LOADER));
        dispatch({
          type: DELETE_PAGE_FAILED,
          payload: error.response.data.message,
        });
      });
  };
};

export const rateComicBook = (comicbook_id, rating) => {
  return (dispatch) => {
    let data = {
      data: {
        attributes: {
          rating: parseInt(rating),
        },
      },
    };

    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/comicbooks/" + comicbook_id + "/ratings", data, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {
            // dispatch({ type: OPERATION_SUCCESS });
          })
          .catch((error) =>
            dispatch({
              type: OPERATION_FAILED,
              payload: error.response.data.message,
            })
          );
      })
      .catch((error) =>
        dispatch({
          type: OPERATION_FAILED,
          payload: error.response.data.message,
        })
      );
  };
};

export const calculatePageNumberViews = (pageId) => {
  return (dispatch) => {
    CSRFInstance.get("")
      .then(() => {
        instance
          .get("/calculate-views/" + pageId, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          })
          .then((response) => {})
          .catch((error) => {});
      })
      .catch((error) => {});
  };
};
