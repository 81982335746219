import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { instance } from "../../config/api";
import { LOADING, SEARCH_FIELD_PLACEHOLDER } from '../../config/app_strings';

export default function SearchForm() {
  let history = useNavigate();
  let typingTimer = null;

  const [isDropdownVisible, setIsDropDownVisible] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);

  const OnFormSubmit = (e, comic) => {
    e.preventDefault();
    history('/search', {state: comic});
  };

  const handleChange = (e) => {
    const value = e.target.value;
    clearTimeout(typingTimer);
    setIsDropDownVisible(true);
    typingTimer = setTimeout(async () => {
      if (value) {
        await makeApiCall(value);
      } else {
        setIsDropDownVisible(false);
        setDropDownList([]);
      }
    }, 700);
  };

  const makeApiCall = async (value) => {
    var searchResponse = await instance.get("search?q=" + value);
    setDropDownList(searchResponse.data.data);
  };

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    };
  }, []);

  const mappingDropdownList =
    dropDownList.length > 0 ? (
      dropDownList.slice(0, 10).map((comic) => {
        return (
          <div
            className="flex flex-row bg-white justify-between items-center cursor-pointer hover:text-white hover:bg-lightDefault rounded-md"
            key={comic.data.comicbook_id}
            onClick={(e) => OnFormSubmit(e, comic)}
          >
            <p className="px-3">{comic.data.attributes.title}</p>
            <img src={comic.links.image} alt={""} className="w-12 rounded-r-md" />
          </div>
        );
      })
    ) : (
      <div className="flex flex-row bg-white py-3 px-2 rounded-md">
        <p className="text-center text-sm">{LOADING}</p>
      </div>
    );

  return (
    <div className="pb-3 w-full rounded-lg">
      <form className="relative flex flex-wrap items-stretch w-full">
        <input
          type="text"
          placeholder={SEARCH_FIELD_PLACEHOLDER}
          name="searchTerm"
          className="relative w-full px-3 py-3 pl-3 text-sm text-white placeholder-gray-800 bg-white bg-opacity-50 rounded-lg outline-none focus:outline-none focus:ring"
          onChange={(e) => handleChange(e)}
        />
        <span className="absolute z-10 items-center justify-center w-8 h-full py-4 pl-3 text-base font-normal leading-snug text-center text-default bg-transparent rounded right-3 ">
          <FaSearch />
        </span>
      </form>
      <div className="absolute w-1/5 mt-2 rounded-md">
        {isDropdownVisible ? mappingDropdownList : null}
      </div>
    </div>
  );
}
