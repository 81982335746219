import React, { Component } from "react";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";

import { instance } from "../../config/api";
import { validateEmptyString } from "../Utility/Validation";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import {
  CHOOSE_OPTION,
  CONFIRM_PASSWORD,
  EMAIL,
  FULL_NAME,
  LOADING,
  MISSING_FIELD,
  NO_ROLE,
  PASSWORD,
  PASSWORD_DONOT_MATCH,
  ROLE,
  SUBMIT,
} from "../../config/app_strings";

export default class ManipulateAdminUserForm extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    role: "",
    confirmPassword: "",

    roles: [],
    isFieldLoading: false,
  };

  async componentDidMount() {
    this.setState({ isFieldLoading: true });

    try {
      let roles = await instance.get("roles", {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      });

      this.setState({ roles: roles.data.data });
    } catch (error) {
      toast.error(error);
    }

    this.setState({ isFieldLoading: false });
  }

  render() {
    let {
      name,
      email,
      password,
      role,
      confirmPassword,
      roles,
      isFieldLoading,
    } = this.state;
    let { isLoading, createUser, adminSubmissionStatus } = this.props;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onFormSubmit = async (e) => {
      e.preventDefault();

      if (
        !validateEmptyString(name) ||
        !validateEmptyString(email) ||
        !validateEmptyString(password) ||
        !validateEmptyString(role) ||
        !validateEmptyString(confirmPassword)
      ) {
        return toast.warning(MISSING_FIELD);
      }

      if (password !== confirmPassword) {
        return toast.warning(PASSWORD_DONOT_MATCH);
      }

      let content = {
        data: {
          type: "users",
          attributes: {
            name: name,
            email: email,
            password: password,
            role_id: role,
          },
        },
      };

      createUser(content);
    };

    const mappingRoleToSelect = isFieldLoading ? (
      <option value="">Loading ...</option>
    ) : roles && roles.length > 0 ? (
      roles.map((SingleRole) => (
        <option
          key={SingleRole.data.role_id}
          value={SingleRole.data.role_id}
          className="flex flex-row justify-between"
        >
          {SingleRole.data.attributes.title.charAt(0).toUpperCase() +
            SingleRole.data.attributes.title.slice(1)}
        </option>
      ))
    ) : (
      NO_ROLE
    );

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {FULL_NAME}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder={FULL_NAME}
              value={adminSubmissionStatus ? "" : name}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {EMAIL}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={EMAIL}
              value={adminSubmissionStatus ? "" : email}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              Role<span className="mx-1 text-red-500">*</span>
            </label>
            <select
              type="select"
              name="role"
              id="role"
              placeholder={isFieldLoading ? LOADING : ROLE}
              value={adminSubmissionStatus ? "" : role}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            >
              <option value="">{CHOOSE_OPTION}</option>
              {mappingRoleToSelect}
            </select>
          </div>
          <hr />
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {PASSWORD}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder={PASSWORD}
              value={adminSubmissionStatus ? "" : password}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {CONFIRM_PASSWORD}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder={CONFIRM_PASSWORD}
              value={adminSubmissionStatus ? "" : confirmPassword}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            />
          </div>

          {isLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}
