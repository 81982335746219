import React, { Component } from "react";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import { toast } from "react-toastify";
import { instance } from "../../config/api";
import {
  CHAPTER_TITLE,
  CHOOSE_OPTION,
  COMIC_BOOK,
  MISSING_FIELD,
  NO_COMIC,
  SUBMIT,
} from "../../config/app_strings";
import { validateEmptyString } from "../Utility/Validation";
import { ScaleLoader } from "react-spinners";

export default class ManipulateChapter extends Component {
  state = {
    comicName: "",
    chapters: [{ title: "" }],

    //
    isFieldLoading: false,
    getComicBook: [],
  };

  //
  async componentDidMount() {
    this.setState({ isFieldLoading: true });
    await instance
      .get("comicBooks")
      .then((response) => {
        this.setState({ getComicBook: response.data.data });
      })
      .catch((error) => {});
    this.setState({ isFieldLoading: false });
  }

  render() {
    let { comicName, isFieldLoading, getComicBook, chapters } = this.state;
    const { registerChapter, isChapterLoading } = this.props;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onHandleFileInput = (index, e) => {
      const values = [...chapters];

      values[index][e.target.name] = e.target.value;
      this.setState({ chapters: values });
    };

    const onHandleAddDynamicField = (e) => {
      e.preventDefault();

      this.setState({ chapters: [...chapters, { title: "" }] });
    };

    const onHandleRemoveDynamicField = (index, e) => {
      const values = [...chapters];
      values.splice(index, 1);
      this.setState({ chapters: values });
    };

    const onFormSubmit = async (e) => {
      e.preventDefault();

      // Validation
      if (!validateEmptyString(comicName)) {
        return toast.warning(MISSING_FIELD);
      }

      registerChapter(chapters, comicName, false);
    };

    const mappingComics = isFieldLoading ? (
      <option value="">Loading ...</option>
    ) : getComicBook && getComicBook.length > 0 ? (
      getComicBook.map((SingleComic) => (
        <option
          value={SingleComic.data.comicbook_id}
          key={SingleComic.data.comicbook_id}
        >
          {SingleComic.data.attributes.title}
        </option>
      ))
    ) : (
      NO_COMIC
    );

    return (
      <div className="w-1/2 m-auto">
        <form onSubmit={onFormSubmit} className="flex flex-col py-10 space-y-5">
          <div className="flex flex-col space-y-3">
            <label className="font-light text-textColor">
              {COMIC_BOOK}
              <span className="mx-1 text-red-500">*</span>
            </label>
            <select
              name="comicName"
              id="comicName"
              placeholder={COMIC_BOOK}
              value={comicName}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
            >
              <option value="">{CHOOSE_OPTION}</option>
              {mappingComics}
            </select>
          </div>
          {comicName !== "" ? (
            <>
              {chapters.map((SingleChapter, index) => (
                <div
                  className="flex flex-row items-end justify-between space-x-5"
                  key={index}
                >
                  <div className="flex flex-col w-full space-y-3">
                    <label className="font-light text-textColor">
                      {CHAPTER_TITLE}
                      <span className="mx-1 text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder={CHAPTER_TITLE}
                      value={SingleChapter.title}
                      onChange={(e) => onHandleFileInput(index, e)}
                      className="px-3 py-2 placeholder-gray-300 border rounded-lg text-textColor focus:outline-none focus:border-default"
                      required
                    />
                  </div>
                  <div className="flex flex-row space-x-8">
                    <button
                      className="p-2 rounded-lg bg-default"
                      onClick={(e) => onHandleAddDynamicField(e)}
                    >
                      <HiPlusSm size="30px" color="#FFF" />
                    </button>
                    <button
                      className="p-2 rounded-lg bg-default"
                      onClick={(e) => {
                        e.preventDefault();
                        if (chapters.length > 1) onHandleRemoveDynamicField(e);
                      }}
                    >
                      <HiMinusSm size="30px" color="#FFF" />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : null}
          {isChapterLoading ? (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              disabled
            >
              <ScaleLoader className="m-auto text-white" />
            </button>
          ) : (
            <button
              className="py-2 bg-white border rounded-lg hover:bg-default hover:text-white"
              type="submit"
            >
              {SUBMIT}
            </button>
          )}
        </form>
      </div>
    );
  }
}
