import React from "react";
import { IoClose } from "react-icons/io5";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Components
import { CLOSE, NO_RECOMMENDATION } from "../../../config/app_strings";
import { setNavBarVisibility } from "../../../actions/UtilityAction";
import { BsBookHalf } from "react-icons/bs";
import { isMobile } from "react-device-detect";

function RightSection({
  onCloseModal,
  adverts,
  recommendations,
  comicbookId,
  onParentCloseModel,
  setNavBarVisibility,
}) {
  const history = useNavigate();

  /* The `mappingRecommendedComicBooks` constant is creating a list of recommended comic books based on
  the `recommendations` array passed as a prop. If the `recommendations` array is not empty, it maps
  through each comic book object and checks if its `id` is not equal to the `comicbookId` prop
  passed to the component. If the `id` is not equal, it creates a `div` element with a `className`
  of `w-1/2 pb-3 pr-3` and an `onClick` event that calls the `onCloseModal` and `onParentCloseModel`
  functions passed as props, and navigates to the comic book's page using `history.push`. Inside the
  `div`, it also includes an `img` element with the `src` attribute set to the comic book's image
  path and a `className` of `w-full slide-image`. If the `id` is equal to the `comicbookId`, it
  returns `null`. If the `recommendations` array is empty, it returns a `div` element with a
  `className` of `text-white px-20 text-center m-auto` and displays the */
  const mappingRecommendedComicBooks =
    recommendations.length > 0 ? (
      recommendations.slice(0, 6).map((comics) =>
        comics.id !== comicbookId ? (
          <div
            className="w-1/2 pb-3 pr-3"
            key={comics.id}
            onClick={(e) => {
              onCloseModal(e);
              onParentCloseModel(e);
              history("/comic/" + comics.id);
            }}
          >
            <img
              src={comics.image.path}
              alt={"Client Company Name"}
              className="w-full h-52 object-cover"
            />
          </div>
        ) : null
      )
    ) : (
      <div className="text-white px-20 text-center m-auto">
        {NO_RECOMMENDATION}
      </div>
    );

  return (
    <div className="w-full md:w-1/5 flex flex-col space-y-3">
      {!isMobile ? (
        <button
          className="flex flex-row space-x-3 justify-end items-center font-bold py-5 px-3"
          onClick={(e) => {
            onCloseModal(e);
            setNavBarVisibility(true);
          }}
        >
          <p className="text-white">{CLOSE}</p>
          <IoClose className="text-white" />
        </button>
      ) : null}

      {adverts.length === 0 ? (
        <div
          className=" bg-default bg-opacity-70 flex rounded-md"
          style={{ width: "97%", height: "30%" }}
        >
          <p className="m-auto text-center text-2xl font-bold text-white">
            Déposez votre <br /> annonce ici
          </p>
        </div>
      ) : (
        <SimpleImageSlider
          width={"19.5%"}
          height={"30%"}
          images={adverts}
          showBullets={false}
          showNavs={false}
          autoPlay={true}
          autoPlayDelay={1}
          duration={0.3}
          loop={true}
        />
      )}
      <h4 className="text-white font-bold flex flex-row space-x-3 items-center">
        <span>
          <BsBookHalf className="text-passOverColor" />
        </span>
        <span>Bandes dessinées similaires</span>
      </h4>
      <div
        className="flex flex-row flex-wrap overflow-y-auto hover:overflow-y-scroll no-scrollbar"
        style={{ height: "60%" }}
      >
        {mappingRecommendedComicBooks}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isNavVisible: state.utility.isNavBarVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavBarVisibility: (isVisible) =>
      dispatch(setNavBarVisibility(isVisible)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSection);
